// eslint-disable-next-line fsd-import/layer-imports
import { store } from 'app/providers/StoreProvider/config/store';

import { authSliceActions } from 'processes/auth';

import { logger } from 'shared/lib/utils/logger';
import { getBrowserStorage } from 'shared/lib/utils/storage';
import { needUpdateTokens } from 'shared/lib/utils/tokens';

import { AUTH_REFRESH } from '../../authApi/authRefresh.gql';
import { apolloClientWithoutAuth } from '../clients/apolloClientWithoutAuth';
import { getAccessTokenInfo, getAuthSlice } from './storeAuthSlice';

/** Ссылка на promise ожидания обновления токена */
let pendingAccessTokenPromise: Promise<string> | null = null;

/** Идентификатор интервала с флагом продолжения запроса */
let intervalId: NodeJS.Timeout | null = null;

const log = logger('AUTH');

/**
 * Получение нового access токена
 */
async function refreshAccessToken() {
  const refreshToken = getBrowserStorage('REFRESH_TOKEN_KEY');
  if (!refreshToken) throw new Error('Refresh token is empty');

  const isRefreshExpired = needUpdateTokens({ token: refreshToken });
  if (isRefreshExpired) throw new Error('Refresh token is expired');

  const { data } = await apolloClientWithoutAuth.mutate({
    mutation: AUTH_REFRESH,
    variables: {
      input: { refreshToken },
    },
  });
  if (!data) throw new Error('Cannot refresh token');

  const { access, refresh } = data.auth.refresh;
  store.dispatch(
    authSliceActions.setTokens({
      access: access.value,
      refresh: refresh.value,
    }),
  );

  return access.value;
}

/**
 * Получение актуального access токена (с обработкой его обновления)
 */
export async function getAccessTokenPromise() {
  const { value: accessToken, expiresAt: accessExpiresAt } =
    getAccessTokenInfo();

  // Передаётся время истечения токена, чтобы не делать лишние манипуляции
  // с токеном на каждый вызов
  const isAccessExpired = needUpdateTokens({ exp: accessExpiresAt });

  if (accessToken && !isAccessExpired) {
    return accessToken;
  }

  // Если запрос обновления токена выполняется, то возвращаем его
  if (pendingAccessTokenPromise) return pendingAccessTokenPromise;

  try {
    const newAccessToken = await refreshAccessToken();
    // При успехе возвращаем новый токен
    return newAccessToken;
  } catch (err) {
    log('Не удалось обновить access токен: ', err);

    store.dispatch(authSliceActions.dropTokens());
    store.dispatch(authSliceActions.showLogin());
  }

  // делаем promise для ожидания токена
  pendingAccessTokenPromise = new Promise<string>((res) => {
    intervalId = setInterval(async () => {
      const token = getAuthSlice().accessToken;
      // проверяем наличие токена для продолжения запросов
      if (token) {
        res(getAccessTokenPromise());
      }
    }, 1000);
  }).finally(() => {
    if (intervalId) clearInterval(intervalId);
    pendingAccessTokenPromise = null;
  });

  return pendingAccessTokenPromise;
}
