import { Button as MUIButton, SxProps, Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { sizeMap } from '../const/sizeMap';
import { IButtonProps } from '../types/Button';

export const Button = (props: IButtonProps) => {
  const {
    children,
    size,
    loading,
    disabled,
    variant,
    customSize,
    justifyContent,
    startIcon,
    ...otherProps
  } = props;

  const defaultSize: IButtonProps['size'] = size || 'large';

  const sx: SxProps<Theme> = {
    height: customSize?.height || sizeMap[defaultSize].height,
    fontSize: sizeMap[defaultSize].fontSize,
    ...(customSize && {
      width: customSize.width,
      minWidth: customSize.width,
      padding: '2px',
    }),
    ...(justifyContent && { justifyContent }),
    alignItems: 'center',
  };

  return (
    <MUIButton
      size={sizeMap[defaultSize].size}
      disabled={disabled || loading}
      variant={variant || 'contained'}
      sx={sx}
      startIcon={
        startIcon && loading ? (
          <CircularProgress
            size={sizeMap[defaultSize].loaderSize}
            color='inherit'
          />
        ) : (
          startIcon
        )
      }
      {...otherProps}
    >
      {!startIcon && loading ? (
        <CircularProgress
          size={sizeMap[defaultSize].loaderSize}
          color='inherit'
        />
      ) : (
        children
      )}
    </MUIButton>
  );
};
