import { Box } from '@mui/system';

import LogoBlueIcon from 'shared/assets/icons/mc-logo.svg?react';
import { classNames } from 'shared/lib/utils/classNames';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import cls from './NavbarLogo.module.scss';

export const NavbarLogo = () => {
  return (
    <Box className={classNames(cls.Container)}>
      <IconWrapper Svg={LogoBlueIcon} className={classNames(cls.Image)} />
    </Box>
  );
};
