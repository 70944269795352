import { createSelector } from '@reduxjs/toolkit';

import { getAuth } from '../getAuth/getAuth';

export const getToken = createSelector(
  getAuth,
  ({ accessToken, accessExpiresAt, refreshExpiresAt }) => ({
    accessToken,
    accessExpiresAt,
    refreshExpiresAt,
  }),
);
