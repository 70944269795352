import { gql } from '@apollo/client';

export const USER_ADD = gql`
  mutation USER_ADD($input: AddPersonInput!) {
    accounts {
      people {
        add(input: $input) {
          id
        }
      }
    }
  }
`;
