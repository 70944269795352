import { gql } from '../__generated__';

export const SIGNING_FILES_FOR_SIGNING = gql(/* GraphQL */ `
  subscription SIGNING_FILES_FOR_SIGNING($input: SubscribeToFilesInput) {
    filesForSign(input: $input) {
      ...TokenUpdateSubscriptionEvent_Fragment
      ...Disconnect_Fragment

      ... on FileForSign {
        id
        url
        deadline
      }
    }
  }
`);
