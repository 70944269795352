import { Stack } from '@mui/material';

import { cssVar } from 'shared/lib/utils/cssVar';

import { NumberToColorConversion } from '../../../helpers/NumberToColorConversion';
import cls from './PhotoPreviewStub.module.scss';

export enum ColorIcon {
  BLUE = '--color-blue-100',
  GREEN = '--color-green-100',
  MAGENTA = '--color-magenta-100',
}

interface PhotoPreviewStubProps {
  uuid: string;
  iconType: 'organization' | 'user';
  borderRadius?: '0.5rem' | '1rem';
}

interface PhotoPreviewStubPlaceholderProps {
  iconType: 'organization' | 'user';
  color: ColorIcon.BLUE | ColorIcon.GREEN | ColorIcon.MAGENTA;
}

const PhotoPreviewStubPlaceholder = (
  props: PhotoPreviewStubPlaceholderProps,
) => {
  const { iconType, color } = props;

  return iconType === 'organization' ? (
    <svg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' fill={cssVar(color)} fillOpacity='0.3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4619 8.91646C14.9372 7.69451 17.0628 7.69451 18.5381 8.91646L22.8305 12.4716C23.5709 13.0848 24 14.0004 24 14.9668V20.7717C24 22.5546 22.5673 24 20.8 24H11.2C9.43269 24 8 22.5546 8 20.7717V14.9668C8 14.0004 8.42913 13.0848 9.16949 12.4716L13.4619 8.91646Z'
        fill='#435DE5'
        stroke={cssVar(color)}
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4619 7.91646C13.9372 6.69451 16.0628 6.69451 17.5381 7.91646L21.8305 11.4716C22.5709 12.0848 23 13.0004 23 13.9668V19.7717C23 21.5546 21.5673 23 19.8 23H10.2C8.43269 23 7 21.5546 7 19.7717V13.9668C7 13.0004 7.42913 12.0848 8.16949 11.4716L12.4619 7.91646Z'
        fill='white'
        stroke='white'
        strokeWidth='2'
      />
    </svg>
  ) : (
    <svg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='30' height='30' fill={cssVar(color)} fillOpacity='0.3' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3506 11C11.3506 8.23858 13.5892 6 16.3506 6C19.1121 6 21.3506 8.23858 21.3506 11C21.3506 13.7614 19.1121 16 16.3506 16C13.5892 16 11.3506 13.7614 11.3506 11Z'
        fill={cssVar(color)}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.48903 20.655C9.83833 18.4462 12.3875 17 16.3506 17C20.3138 17 22.8629 18.4462 24.2123 20.655C25.0497 22.0259 24.7292 23.4526 23.873 24.4474C23.0517 25.4018 21.7284 26 20.3506 26H12.3506C10.9729 26 9.6496 25.4018 8.82828 24.4474C7.97211 23.4526 7.65158 22.0259 8.48903 20.655Z'
        fill={cssVar(color)}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99999 10C9.99999 7.23858 12.2386 5 15 5C17.7614 5 20 7.23858 20 10C20 12.7614 17.7614 15 15 15C12.2386 15 9.99999 12.7614 9.99999 10Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.13838 19.655C8.48769 17.4462 11.0368 16 15 16C18.9632 16 21.5123 17.4462 22.8616 19.655C23.6991 21.0259 23.3785 22.4526 22.5224 23.4474C21.701 24.4018 20.3777 25 19 25H11C9.62227 25 8.29895 24.4018 7.47764 23.4474C6.62147 22.4526 6.30094 21.0259 7.13838 19.655Z'
        fill='white'
      />
    </svg>
  );
};

export const PhotoPreviewStub = (props: PhotoPreviewStubProps) => {
  const { iconType, uuid, borderRadius } = props;

  return (
    <Stack className={cls.IconWrapper} borderRadius={borderRadius}>
      <PhotoPreviewStubPlaceholder
        iconType={iconType}
        color={NumberToColorConversion(uuid, [
          ColorIcon.BLUE,
          ColorIcon.GREEN,
          ColorIcon.MAGENTA,
        ])}
      />
    </Stack>
  );
};
