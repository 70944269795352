import { gql } from '@apollo/client';

export const USER_SET_ATTRIBUTES = gql`
  mutation USER_SET_ATTRIBUTES($input: SetAttributesInput!) {
    accounts {
      people {
        setAttributes(input: $input) {
          id
        }
      }
    }
  }
`;
