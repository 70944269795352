import { LoggerColor, LoggerColorDefault } from './const';
import { LoggerTag } from './types';

/**
 * Отображение красивых логов в консоли
 */
export const logger = (tag: LoggerTag, ...prefixes: any[]) => {
  const colors = LoggerColor[tag] || LoggerColorDefault;

  const message = `%c${tag}`;
  const style = [
    `color: ${colors.text}`,
    `background-color: ${colors.background}`,
    'font-weight: bold',
    'border-radius: 3px',
    'padding: 2px 10px',
  ].join(';');

  return function showTextInConsole(...args: any[]) {
    // eslint-disable-next-line no-console
    console.log(message, style, ...prefixes, ...args);
  };
};
