import { gql } from '@apollo/client';

export const USER_SET_ROLE = gql`
  mutation USER_SET_ROLE($input: SetRoleInput!) {
    accounts {
      setRole(input: $input) {
        ... on Person {
          id
        }
      }
    }
  }
`;
