import { LoggerTag, LoggerTagColor } from '../types';

const textColor = {
  white: '#FFFFFF',
  black: '#000000',
};

export const LoggerColorDefault: LoggerTagColor = {
  background: '#DDD',
  text: textColor.black,
};

export const LoggerColor: Partial<Record<LoggerTag, LoggerTagColor>> = {
  APP: { background: '#08C', text: textColor.white },
  AUTH: { background: '#EF6C00', text: textColor.white },
  MEDCAB: { background: '#44CCDF', text: textColor.white },
  WS: { background: '#FF99A4', text: textColor.white },
};
