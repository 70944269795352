import { SubscriptionDetailsPage } from 'pages/Subscription';

import {
  SubscriptionsRouteNames,
  SubscriptionsRoutePaths,
} from 'shared/const/router/subscriptions';

import { TRouteProps } from './types';

export const SubscriptionsRoutes: Record<SubscriptionsRouteNames, TRouteProps> =
  {
    [SubscriptionsRouteNames.SUBSCRIPTION_DETAILS_INFO]: {
      path: SubscriptionsRoutePaths.getRouteSubscriptionDetailsInfo(':id'),
      element: <SubscriptionDetailsPage />,
      isAuth: true,
    },
  };
