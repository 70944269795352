import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'shared/lib/hooks/useDebounce';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { UniversalAutocomplete } from 'shared/ui/form/inputs/UniversalAutocomplete';

import { ORG_UNIT_FAKER_SEARCH } from '../../../../../api/OrgUnitFakerSearch.gql';
import { IItem } from '../../../../../types';

interface FakerExamOrgUnitSelectProps {
  inputValue: Nullable<IItem>;
  onChangeInputValue: (value: Nullable<IItem>) => void;
}

const STEP = 10;

export const FakerExamOrgUnitSelect = (props: FakerExamOrgUnitSelectProps) => {
  const { inputValue, onChangeInputValue } = props;

  const errorHandlerContext = useErrorHandlerContext();

  const [isOpen, setIsOpen] = useState(false);

  const [params, setParams] = useState({
    search: '',
    offset: 0,
    total: 0,
    isLoading: false,
    items: [] as IItem[],
  });
  const [value, setValue] = useState<Nullable<IItem>>(inputValue);
  const [memoVariables, setMemoVariables] = useState<string>('');

  const changeValue = useCallback(
    (el: Nullable<IItem>) => {
      onChangeInputValue(el);
      setValue(el);
    },
    [onChangeInputValue, setValue],
  );

  const changeParams = useCallback(
    (
      el:
        | ((prev: typeof params) => Partial<typeof params>)
        | Partial<typeof params>,
    ) => {
      setParams((prev) => {
        const newValue = typeof el === 'function' ? el(prev) : el;
        return { ...prev, ...newValue };
      });
    },
    [setParams],
  );

  /** Действия при изменении текста поиска OU */
  const changeSearchText = useCallback(
    (el: string) => {
      changeParams({
        offset: 0,
        search: el,
      });
    },
    [changeParams],
  );

  const [getList] = useLazyQuery(ORG_UNIT_FAKER_SEARCH, {
    context: errorHandlerContext({
      message: 'Не удалось загрузить организации',
    }),

    onCompleted: (res) => {
      const page = res.orgUnits.getFilteredList;
      const { total, items } = page;

      const selectValues = items.map((item) => ({
        id: item.id,
        label: item.displayShortname,
      }));

      const newParams = {
        total,
        isLoading: false,
      };

      if (params.offset > 0) {
        changeParams((prev) => ({
          ...newParams,
          items: [...prev.items, ...selectValues],
        }));
      } else {
        changeParams({
          ...newParams,
          items: selectValues,
        });
      }
    },
  });

  // Отложенный запрос списка OU
  const memoParams = useMemo(
    () => ({
      search: params.search,
      offset: params.offset,
    }),
    [params.search, params.offset],
  );
  const debouncedParams = useDebounce(memoParams);

  // Запрос списка OU
  useEffect(() => {
    changeParams({ isLoading: true });
    const variables = {
      filters: {
        ...(!!debouncedParams.search && { search: debouncedParams.search }),
      },
      pagination: { limit: STEP, offset: debouncedParams.offset },
    };

    if (JSON.stringify(variables) !== memoVariables && isOpen) {
      setMemoVariables(JSON.stringify(variables));
      getList({ variables });
    } else {
      changeParams({ isLoading: false });
    }
  }, [debouncedParams, memoVariables, getList, changeParams, isOpen]);

  return (
    // Фильтр OU
    <UniversalAutocomplete
      options={params.items}
      isLoading={params.isLoading}
      isFullyLoaded={params.items.length === params.total}
      loadMore={() => {
        if (!params.isLoading)
          changeParams((prev) => ({
            ...prev,
            offset: prev.offset + STEP,
          }));
      }}
      value={value}
      onChangeValue={(el) => changeValue(el)}
      search={params.search}
      onChangeSearch={(search) => changeSearchText(search)}
      onOpenHandler={() => setIsOpen(true)}
      onCloseHandler={() => setIsOpen(false)}
      clearSearchOnClose
    />
  );
};
