import { gql } from '../__generated__';
import { UserGetIndocsForUnbanQuery } from '../__generated__/graphql';

export const USER_GET_INDOCS_FOR_UNBAN = gql(/* GraphQL */ `
  query USER_GET_INDOCS_FOR_UNBAN(
    $filters: IndocsFilter!
    $pagination: Pagination!
  ) {
    indocs {
      getFilteredList(filters: $filters, pagination: $pagination) {
        items {
          name
          id
          type
        }
      }
    }
  }
`);

export type UserIndoc = NonNullable<
  UserGetIndocsForUnbanQuery['indocs']['getFilteredList']
>['items'][number];
