import { TypographyOptions } from '@mui/material/styles/createTypography';

export const createTypography = (): TypographyOptions => ({
  fontSize: 16,
  htmlFontSize: 16,
  fontFamily: 'Rawline, sans-serif',

  h1: {
    fontSize: '1.875rem',
    lineHeight: '1.27',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.5rem',
    lineHeight: '1.25',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1rem',
    lineHeight: '1.375',
    fontWeight: 500,
  },
  h4: {
    fontSize: '0.875rem',
    lineHeight: '1.428',
    fontWeight: 500,
  },
  t1: {
    fontSize: '1rem',
    lineHeight: '1.375',
    fontWeight: 400,
  },
  t2: {
    fontSize: '0.875rem',
    lineHeight: '1.428',
    fontWeight: 400,
  },
  t3: {
    fontSize: '0.75rem',
    lineHeight: '1.5',
    fontWeight: 500,
  },
  t4: {
    fontSize: '0.625rem',
    lineHeight: '1.6',
    fontWeight: 500,
  },
  b1: {
    fontSize: '1rem',
    lineHeight: '1.625',
    fontWeight: 600,
  },
  b2: {
    fontSize: '0.75rem',
    lineHeight: '2.167',
    fontWeight: 600,
  },
});
