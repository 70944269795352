import { ActionErrorChip } from '../../../helpers/ActionErrorChip';
import { TActionModal } from '../types';
import { ActionModal, ActionModalProps } from './ActionModal';

interface ActionModalSwitchContainerProps {
  actionType: TActionModal;
  ConfirmModalProps?: Partial<ActionModalProps>;
  SuccessModalProps?: Partial<ActionModalProps>;
  ErrorModalProps?: Partial<ActionModalProps>;
  onClose: () => void;
}

export const ActionModalSwitchContainer = (
  props: ActionModalSwitchContainerProps,
) => {
  const {
    actionType,
    onClose,
    ConfirmModalProps,
    SuccessModalProps,
    ErrorModalProps,
  } = props;
  switch (actionType) {
    case 'confirm': {
      return (
        <ActionModal
          {...ConfirmModalProps}
          actionType='confirm'
          open={actionType === 'confirm'}
          title={ConfirmModalProps?.title ?? 'Отменить изменения?'}
          subtitle={
            ConfirmModalProps?.subtitle ?? 'Введенные данные будут потеряны'
          }
          onClose={ConfirmModalProps?.onClose ?? onClose}
          containedBtn={{
            label: ConfirmModalProps?.containedBtn?.label ?? 'Закрыть',
            onClick:
              ConfirmModalProps?.containedBtn?.onClick ?? (() => onClose()),
          }}
        />
      );
    }

    case 'success': {
      return (
        <ActionModal
          {...SuccessModalProps}
          actionType='success'
          open={actionType === 'success'}
          title={SuccessModalProps?.title ?? 'Данные успешно изменены'}
          onClose={SuccessModalProps?.onClose ?? onClose}
          outlinedBtn={
            SuccessModalProps?.outlinedBtn ?? {
              label: 'Закрыть',
              onClick: () => onClose(),
            }
          }
        />
      );
    }

    case 'error': {
      return (
        <ActionModal
          containedBtn={{
            label: 'Закрыть',
            onClick: () => onClose(),
          }}
          {...ErrorModalProps}
          actionType='confirm'
          open={actionType === 'error'}
          title={ErrorModalProps?.title || 'Что-то пошло не так'}
          content={ErrorModalProps?.content || <ActionErrorChip />}
          onClose={onClose}
        />
      );
    }
    default: {
      return null;
    }
  }
};
