import { AutocompleteRenderOptionState, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../contents/Typography';
import { Checkbox } from '../../checkboxes/Checkbox';
import {
  AutocompleteValue,
  SelectOption,
  UniversalAutocomplete,
  UniversalAutocompleteProps,
} from '../../inputs/UniversalAutocomplete';
import cls from './SearchSelectBase.module.scss';
import { SelectedItemChips } from './SelectedItemChips';

export type SearchSelectOption = SelectOption;

export interface SearchSelectBaseMultipleProps<TOption, TValue> {
  /** Выбор нескольких значений */
  multiple: true;

  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, true>,
    options: TOption[],
  ) => void;
}
export interface SearchSelectBaseSingleProps<TOption, TValue> {
  /** Выбор нескольких значений */
  multiple?: false;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, false>,
    options: TOption[],
  ) => void;
}

export type SearchSelectBaseProps<
  TOption,
  TValue,
  Multi extends boolean | undefined = undefined,
> = Omit<
  UniversalAutocompleteProps<TOption, TValue, Multi>,
  'multiple' | 'onChangeValue' | 'value'
> & {
  /** Выбранные элементы выпадающеего списка (значение поля) */
  selectedOptions: TOption[];
  /** Текст ошибки */
  isSearchFieldError?: boolean;
  /** Отображение выбранных элементов в выпадающем списке */
  showSelectedInListbox?: boolean;
  /** Отображение выбранных элементов под инпутом */
  showSelectedAfterInput?: boolean;
  /** Функция получения текста элемента списка для отображения в chips под полем ввода (для multiple) */
  getChipLabel?: (option: TOption) => string;
  /** Функция получения аватарки элемента списка для отображения в chips под полем ввода (для multiple) */
  getAvatar?: (option: TOption) => string | undefined;
  /** Ширина выпадающего списка */
  menuWidth?: number;
} & TernaryUnion<
    Multi,
    SearchSelectBaseMultipleProps<TOption, TValue>,
    SearchSelectBaseSingleProps<TOption, TValue>
  >;

export function SearchSelectBase<TOption, TValue>(
  props: SearchSelectBaseProps<TOption, TValue, undefined>,
) {
  const {
    selectedOptions,
    multiple,
    onChange,
    isSearchFieldError,
    label,
    menuWidth,
    placeholder,
    renderOption: renderOptionExternal,
    getChipLabel,
    getAvatar,

    disabled,
    getOptionLabel = (option: TOption) => (option as SearchSelectOption).label,
    getOptionValue = (option: TOption) =>
      (option as SearchSelectOption).id as TValue,

    showSelectedInListbox = !!multiple,
    showSelectedAfterInput = !!multiple,

    ...restProps
  } = props;

  const { t } = useTranslation();

  const _placeholder = useMemo(() => {
    return !selectedOptions.length
      ? placeholder
      : `Выбрано: ${selectedOptions.length}`;
  }, [selectedOptions, placeholder]);

  const handleChangeValueSingle = useCallback(
    (newSelectedOption: AutocompleteValue<TOption, false>) => {
      if (!multiple) {
        onChange(
          newSelectedOption ? getOptionValue(newSelectedOption) : null,
          newSelectedOption ? [newSelectedOption] : [],
        );
      }
    },
    [onChange, getOptionValue, multiple],
  );

  const handleChangeValueMultiple = useCallback(
    (newSelectedOptions: AutocompleteValue<TOption, true>) => {
      if (multiple) {
        const newValueOptions = newSelectedOptions.filter((option) => option);
        onChange(newValueOptions.map(getOptionValue), newValueOptions);
      }
    },
    [onChange, getOptionValue, multiple],
  );

  const handleDelete = useCallback(
    (toDelete: TOption) => {
      if (multiple) {
        const newOptions = selectedOptions.filter(
          (option) => getOptionValue(option) !== getOptionValue(toDelete),
        );
        onChange(newOptions.map(getOptionValue), newOptions);
      } else {
        onChange(null, []);
      }
    },
    [onChange, multiple, selectedOptions, getOptionValue],
  );

  const handleClear = useCallback(() => {
    if (multiple) {
      onChange([], []);
    } else {
      onChange(null, []);
    }
  }, [onChange, multiple]);

  const selectedItemsChips = useMemo(() => {
    if (!multiple) return null;
    if (!selectedOptions.length && !isSearchFieldError) return null;

    return (
      <SelectedItemChips
        isSearchFieldError={isSearchFieldError}
        selectedOptions={selectedOptions}
        getLabel={getChipLabel || getOptionLabel}
        getOptionValue={getOptionValue}
        getAvatar={getAvatar}
        handleDelete={handleDelete}
        handleClear={handleClear}
      />
    );
  }, [
    isSearchFieldError,
    selectedOptions,
    multiple,
    handleDelete,
    handleClear,
    getOptionLabel,
    getChipLabel,
    getAvatar,
    getOptionValue,
  ]);

  const errorMessage = useMemo(() => {
    return isSearchFieldError
      ? t('To search, enter more than 3 characters')
      : undefined;
  }, [isSearchFieldError, t]);

  const renderOption = useCallback(
    (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: TOption,
      state: AutocompleteRenderOptionState,
    ) => {
      const { selected, index } = state;
      const key = `listItem-${index}-${getOptionValue(option)}`;

      return (
        <li {...optionProps} key={key}>
          {multiple && (
            <Checkbox
              className={cls.Checkbox}
              size='medium'
              checked={selected}
              id={key}
            />
          )}
          <Typography variant='t2'>{getOptionLabel(option)}</Typography>
        </li>
      );
    },
    [getOptionLabel, getOptionValue, multiple],
  );

  const universalAutocompleteProps = {
    ...restProps,
    placeholder: _placeholder,
    renderOption: renderOptionExternal || renderOption,
    getOptionValue,
    getOptionLabel,
    disabled,
    menuWidth,
  };

  return (
    <Stack gap='.5rem'>
      {label && (
        <Typography
          variant='h4'
          color={disabled ? 'var(--color-monochrome-grey-300)' : undefined}
        >
          {label}
        </Typography>
      )}

      {multiple ? (
        <UniversalAutocomplete
          {...universalAutocompleteProps}
          multiple
          value={selectedOptions}
          onChangeValue={handleChangeValueMultiple}
          slots={{
            top: showSelectedInListbox && selectedItemsChips,
          }}
          searchErrorText={errorMessage}
        />
      ) : (
        <UniversalAutocomplete
          {...universalAutocompleteProps}
          value={selectedOptions[0] || null}
          onChangeValue={handleChangeValueSingle}
          searchErrorText={errorMessage}
        />
      )}

      {showSelectedAfterInput && selectedItemsChips}
    </Stack>
  );
}
