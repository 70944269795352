import { TypographyProps } from '@mui/material';
import { FC } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import { Typography } from '../../../contents/Typography';
import SpinnerImg from '../assets/spinner.svg?react';
import cls from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  children?: React.ReactNode;
  textColor?: string;
  textVariant?: TypographyProps['variant'];
}

export const Loader: FC<LoaderProps> = (props: LoaderProps) => {
  const {
    className,
    children,
    textColor,
    textVariant = 't1',
    ...otherProps
  } = props;
  return (
    <div
      className={classNames(cls.LoaderWrapper, {}, [className])}
      {...otherProps}
    >
      <div className={cls.LoaderBox}>
        <SpinnerImg />
      </div>
      {children && (
        <Typography
          className={cls.LoaderAnnotation}
          color={textColor}
          variant={textVariant}
        >
          {children}
        </Typography>
      )}
    </div>
  );
};
