import { jwtDecode } from 'jwt-decode';

import { IAccessToken, IRefreshToken } from './tokens.types';

export function parseToken<T>(x: string): T | null {
  try {
    const obj = jwtDecode<T>(x);
    if (!obj) return null;

    return obj;
  } catch (err) {
    console.error('Не удалось распарсить токен: ', err);
  }
  return null;
}

/** Получить типизированный access токен */
export const parseAccessToken = (token: string) => {
  const obj = parseToken<IAccessToken>(token);
  return obj;
};

/** Получить типизированный refresh токен */
export const parseRefreshToken = (token: string) => {
  const obj = parseToken<IRefreshToken>(token);
  return obj;
};
