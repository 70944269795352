import { Stack } from '@mui/material';

import { filterItemsByPeriod } from '../../lib/filterItemsByPeriod';
import { INotificationItem } from '../../types/INotificationItem.types';
import { NotificationListPeriod } from './NotificationListPeriod';

interface NotificationListProps {
  items: INotificationItem[];
  /** Флаг наличия новых уведомлений */
  hasNew: boolean;
  /** Метод для получения новых уведомлений */
  onNew: () => void;
  onClose: () => void;
}

export const NotificationList = (props: NotificationListProps) => {
  const { items, hasNew, onClose, onNew } = props;

  const { todayData, yesterdayData, lastSevenDaysData, pastMonthsData } =
    filterItemsByPeriod(items);

  const periods = [
    {
      label: 'Сегодня',
      notifications: todayData.notificationItemsList,
      period: { dateFrom: todayData.dateFrom, dateTo: todayData.dateTo },
      isToday: true,
    },
    {
      label: 'Вчера',
      notifications: yesterdayData.notificationItemsList,
      period: {
        dateFrom: yesterdayData.dateFrom,
        dateTo: yesterdayData.dateTo,
      },
    },
    {
      label: 'За прошедшие 7 дней',
      notifications: lastSevenDaysData.notificationItemsList,
      period: {
        dateFrom: lastSevenDaysData.dateFrom,
        dateTo: lastSevenDaysData.dateTo,
      },
    },
    {
      label: 'За прошедшие месяцы',
      notifications: pastMonthsData.notificationItemsList,
      period: {
        dateFrom: pastMonthsData.dateFrom,
        dateTo: pastMonthsData.dateTo,
      },
    },
  ];

  return (
    <Stack gap='.75rem' paddingBottom='.75rem'>
      {periods.map((el) => (
        <NotificationListPeriod
          {...el}
          key={el.label}
          hasNew={hasNew}
          onClose={onClose}
          onNew={onNew}
        />
      ))}
    </Stack>
  );
};
