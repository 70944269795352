import type { Dayjs } from 'dayjs';

import { dayjs } from '../../../external/dayjs';
import { getTimezone } from '../getTimezone/getTimezone';

export const formatDate = (
  date?: Date | Dayjs | number | string | null | undefined,
  format: string = 'DD.MM.YYYY HH:mm',
  params?: {
    withoutTz?: boolean;
  },
  nullDisplayValue?: string,
) => {
  if (!date) {
    return nullDisplayValue ?? '-';
  }

  const { withoutTz } = params || {};
  const showTimeZone = !withoutTz && String(date).at(-1) === 'Z';

  const tz = showTimeZone ? ` (${getTimezone()})` : '';

  return `${dayjs(date, undefined, true).tz().format(format)}${tz}`;
};
