import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';
import { styled } from '@mui/system';
import { forwardRef } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

const McCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: '4px',
  overflow: 'hidden',
  border: `1px solid`,
  borderColor: '#656A83',
  backgroundColor: '#FFFFFF',
  '.Mui-focusVisible &': {
    outline: '1px auto #435DE555',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    borderColor: '#435DE5',
  },
  'input:disabled ~ &': {
    borderColor: '#E3E5F1',
  },
}));

const McCheckboxCheckedIcon = styled(McCheckboxIcon)({
  backgroundColor: '#435DE5',
  borderColor: 'transparent',
  '&::before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3821 0.206198C13.7284 0.509266 13.7635 1.03573 13.4605 1.3821L6.16881 9.71543C5.86686 10.0605 5.34285 10.0968 4.9962 9.79669L0.621196 6.00881C0.27325 5.70756 0.235397 5.18128 0.536649 4.83334C0.8379 4.48539 1.36418 4.44754 1.71212 4.74879L5.4604 7.99405L12.2062 0.284591C12.5092 -0.061773 13.0357 -0.0968709 13.3821 0.206198Z' fill='white' /%3E%3C/svg%3E\")",
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  'input:hover ~ &': {
    backgroundColor: '#435DE5',
  },
  'input:disabled ~ &': {
    borderColor: '#B5BAD0',
    backgroundColor: '#B5BAD0',
  },
});

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => {
    const { size, className, ...otherProps } = props;

    const _size = size === undefined ? 'medium' : size;

    return (
      <MUICheckbox
        ref={ref}
        disableRipple
        color='default'
        checkedIcon={<McCheckboxCheckedIcon />}
        icon={<McCheckboxIcon />}
        className={classNames('', {}, [className, _size])}
        {...otherProps}
      />
    );
  },
);
Checkbox.displayName = 'Checkbox';
