import { createSelector } from '@reduxjs/toolkit';

import { TPermissionKey } from 'shared/api/authApi/types';

import { getRolePermissions } from '../getRolePermissions/getRolePermissions';

export const getCanMakeActionRule = (value: TPermissionKey) =>
  createSelector(getRolePermissions, (permissions) => {
    return permissions.has(value);
  });
