import { ApolloLink } from '@apollo/client';
import { ASTNode, DocumentNode, Kind, visit } from 'graphql';

const getDefinedVariables = (document: DocumentNode) => {
  const variables: string[] = [];

  visit(document, {
    [Kind.VARIABLE_DEFINITION]: {
      leave(node) {
        variables.push(node.variable.name.value);

        return node;
      },
    },
  });

  return variables;
};

const isSingleNode = (
  parent: ASTNode | readonly ASTNode[] | undefined,
): parent is ASTNode => {
  return parent !== undefined && !Array.isArray(parent);
};

const getUnusedVariables = (document: DocumentNode) => {
  const unused: string[] = getDefinedVariables(document);

  visit(document, {
    [Kind.VARIABLE]: {
      leave(variable, _, parent) {
        // если родительский элемент не является объявлением переменной,
        // то переменную вычеркиваем из списка не используемых
        if (isSingleNode(parent) && parent.kind !== Kind.VARIABLE_DEFINITION) {
          const variableName = variable.name?.value;

          if (variableName && unused.includes(variableName)) {
            unused.splice(unused.indexOf(variableName), 1);
          }
        }

        return variable;
      },
    },
  });

  return unused;
};

const removeVariables = (vars: string[], document: DocumentNode) => {
  return visit(document, {
    [Kind.VARIABLE_DEFINITION]: (node) => {
      const variableName = node.variable.name.value;

      if (vars.includes(variableName)) return null;

      return node;
    },
  });
};

const removeUnusedVariableDefinitions = (document: DocumentNode) => {
  return removeVariables(getUnusedVariables(document), document);
};

/**
 * Middleware, убирающий из запроса не используемые переменные
 */
export const removeUnusedVariables = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-param-reassign
  operation.query = removeUnusedVariableDefinitions(operation?.query);

  return forward(operation);
});
