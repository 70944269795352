import { useMutation } from '@apollo/client';
import { IlBell } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { Dayjs } from 'dayjs';
import { memo, useEffect, useState } from 'react';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { formatDate } from 'shared/lib/utils/dates';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { NotificationSetIsRead } from '../../api/NotificationSetIsRead.gql';
import { INotificationItemStringVariables } from '../../types/INotificationItem.types';
import { NotificationIndicator } from '../NotificationIndicator';
import cls from './NotificationCard.module.scss';
import { parserBlock } from './notificationParserBlock';

interface NotificationCardProps {
  id: string;
  header?: INotificationItemStringVariables;
  descriptions?: { text: string }[];
  timestamp: Dayjs;
  payload: INotificationItemStringVariables[];
  isNew: boolean;
  onClose: () => void;
}

const NotificationCardComponent = (props: NotificationCardProps) => {
  const { id, isNew, header, descriptions, payload, timestamp, onClose } =
    props;
  const errorHandlerContext = useErrorHandlerContext();

  const [isUnread, setIsUnread] = useState(isNew);

  const notificationsActions = useNotifications();

  const [setIsRead] = useMutation(NotificationSetIsRead, {
    variables: {
      input: {
        ids: [id],
        isRead: isUnread,
      },
    },
    context: errorHandlerContext({
      message: 'Не удалось отметить как прочитанное',
    }),
  });

  useEffect(() => {
    setIsUnread(isNew);
  }, [isNew]);

  const handleSetIsRead = async () => {
    try {
      const res = await setIsRead();

      if (res.errors) {
        console.error(res.errors);
        throw new Error('Не удалось отметить как прочитанное');
      }

      setIsUnread(!isUnread);
    } catch (err) {
      console.error(err);

      notificationsActions.showErrorNotification({
        message: 'Не удалось отметить как прочитанное',
      });
    }
  };

  return (
    <Stack className={cls.Card}>
      {/* === Заголовок === */}
      <Stack direction='row' alignItems='center' gap='.25rem'>
        <IconWrapper Svg={IlBell} className={cls.Icon} />
        <Typography
          variant='h4'
          display='inline'
          flexDirection='row'
          gap='.125rem'
        >
          {header ? parserBlock('header', header.text, header) : null}
        </Typography>
      </Stack>

      {/* === Описание === */}
      <Stack direction='row' gap='.125rem'>
        <Typography variant='t3' fontWeight={500}>
          {descriptions?.map((el) => el.text).join('. ')}
        </Typography>
      </Stack>

      {/* === Блок payload === */}
      {payload?.map((el, idx) => {
        const key = el.text + idx;

        return (
          <Stack key={key} direction='row' gap='.125rem' alignItems='center'>
            <Typography variant='t3'>
              {parserBlock('payload', el.text, el)}
            </Typography>
          </Stack>
        );
      })}

      {/* === Дата/Индикатор === */}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        gap='.5rem'
      >
        <Typography variant='t3' className={cls.GreyText}>
          {formatDate(timestamp, 'DD.MM.YYYY')}
        </Typography>
        <Typography variant='t3' className={cls.GreyText}>
          {formatDate(timestamp, 'HH:mm')}
        </Typography>
        <NotificationIndicator isActive={isUnread} onClick={handleSetIsRead} />
      </Stack>
    </Stack>
  );
};

export const NotificationCard = memo(NotificationCardComponent);
