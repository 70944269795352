import { Navigate } from 'react-router';

import { NotFoundPage } from 'pages/common';

import {
  ExamsRoutePaths,
  GlobalRouteNames,
  GlobalRoutePaths,
} from 'shared/const/router';

import { TRouteProps } from './types';

export const GlobalRouteConfig: Record<GlobalRouteNames, TRouteProps> = {
  [GlobalRouteNames.MAIN]: {
    path: GlobalRoutePaths.getRouteMain(),
    element: <Navigate to={ExamsRoutePaths.getRouteExams()} />,
    isAuth: true,
  },
  [GlobalRouteNames.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
  },
};
