import { AutocompleteRenderOptionState, Box, List, Stack } from '@mui/material';
import React, { forwardRef, useContext } from 'react';
import { CustomContainerComponentProps, Virtualizer } from 'virtua';

import { useObserverResizeBox } from 'shared/lib/hooks/useObserverResizeBox';

import { Loader } from '../../../helpers/Loader';
import { UniversalAutocompleteContext } from './UniversalAutocompleteContext';

const Container = forwardRef<HTMLUListElement, CustomContainerComponentProps>(
  function Container({ children, style }, ref) {
    return (
      <List dense ref={ref} style={style}>
        {children}
      </List>
    );
  },
);

export const VirtualizedListbox = forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement>
>(function VirtualizedListbox({ children, ...listboxProps }, ref) {
  const ctx = useContext(UniversalAutocompleteContext);

  const { countItems, loadMore, isFetching, render, slots, pageSize } = ctx;

  // NOTE: Типизация входящих параметров.
  // Метод `renderOption` в `Autocomplete` затипизирован на работу с ReactNode,
  // но можно вместо него передать аргументы в виде массива.
  // А здесь приводим к типу, который ожидает VirtualizedListbox.
  const _children = children as [
    React.HTMLAttributes<HTMLElement> & { key: string },
    any,
    AutocompleteRenderOptionState,
  ][];

  const { ref: topRef, height: topHeight } = useObserverResizeBox({
    trigger: slots?.top,
  });

  return (
    <Box
      ref={ref}
      sx={{
        '&[role="listbox"]': {
          maxHeight: 300,
        },
      }}
      {...listboxProps}
    >
      {slots?.top && (
        <div
          ref={topRef}
          style={{
            marginBottom: '4px',
            paddingBottom: '4px',
            borderBottom: '1px solid var(--color-monochrome-grey-200)',
          }}
        >
          {slots?.top}
        </div>
      )}

      <Virtualizer
        as={Container}
        startMargin={topHeight}
        onRangeChange={async (_, end) => {
          if (
            end > countItems() - (pageSize ?? 10) &&
            loadMore &&
            !isFetching()
          ) {
            loadMore();
          }
        }}
      >
        {_children.map((arg) => (
          <Stack marginBottom='4px'>{render(...arg)}</Stack>
        ))}

        {isFetching() && <Loader />}
      </Virtualizer>

      {slots?.bottom && <div>{slots?.bottom}</div>}
    </Box>
  );
});
