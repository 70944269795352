import { InjuryLocation, InjuryType } from 'shared/api/__generated__';
import {
  injuryLocationTranslate,
  injuryTypeTranslate,
} from 'shared/const/exam/injuryMaps';

// TODO: добавить типизацию на перебор всех элементов InjuryType
const injuryTypeOrder: InjuryType[] = [
  InjuryType.HAIRY_AREA,
  InjuryType.FRONTAL,
  InjuryType.TEMPORAL_REGION,
  InjuryType.BROW,
  InjuryType.PARAORBITAL_HEMATOMA,
  InjuryType.EYELID,
  InjuryType.INFLAMMATION_EYELID,
  InjuryType.INFRAORBITAL_REGION,
  InjuryType.ZYGOMATIC_AREA,
  InjuryType.NOSAL_DORSUM,
  InjuryType.NOSE,
  InjuryType.PAROTID_MASTICATORY_REGION,
  InjuryType.EAR,
  InjuryType.SOFT_TISSUE,
  InjuryType.UPPER_LIP,
  InjuryType.LOWER_LIP,
  InjuryType.CHIN_AREA,
  InjuryType.MULTIPLE_HEAD_INJURIES,
  InjuryType.NECK,
  InjuryType.CHEST,
  InjuryType.UPPER_LIMB,
  InjuryType.LOWER_LIMB,
  InjuryType.OTHER,
];

const injuryLocationOrder: InjuryLocation[] = [
  InjuryLocation.LEFT,
  InjuryLocation.RIGHT,
  InjuryLocation.LEFT_AND_RIGHT,
];

export const injuryTypeOptions = injuryTypeOrder.map((key) => ({
  id: key,
  value: key,
  content: injuryTypeTranslate[key],
}));

export const injuryLocationOptions = injuryLocationOrder.map((key) => ({
  id: key,
  content: injuryLocationTranslate[key],
}));
