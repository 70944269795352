import { IlBurger } from '@mc/react-icons/il';
import { SvgIcon } from '@mui/material';

import { Button } from 'shared/ui/buttons/Button';

import cls from './FakerStickyButton.module.scss';

interface FakerStickyButtonProps {
  onOpen?: () => void;
}

export const FakerStickyButton = (props: FakerStickyButtonProps) => {
  const { onOpen } = props;

  return (
    <div className={cls.ButtonWrapper}>
      <Button
        startIcon={<SvgIcon component={IlBurger} />}
        onClick={onOpen}
        variant='contained'
        color='inherit'
      >
        Faker
      </Button>
    </div>
  );
};
