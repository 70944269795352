import { Stack } from '@mui/material';

import { Dialog } from 'shared/ui/modals/Dialog';

import cls from './ReLogin.module.scss';
import { ReLoginForm } from './ui/ReLoginForm/ReLoginForm';

export const ReLogin = () => {
  return (
    <Dialog open disableEnforceFocus>
      <Stack className={cls.Wrapper}>
        <Stack className={cls.Content}>
          <ReLoginForm />
        </Stack>
      </Stack>
    </Dialog>
  );
};
