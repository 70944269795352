import { dayjs } from '../../../external/dayjs';

const TimezoneNames: Record<number, string> = {
  [-1]: 'калининградское',
  0: 'московское',
  1: 'самарское',
  2: 'екатеринбургское',
  3: 'омское',
  4: 'красноярское',
  5: 'иркутское',
  6: 'якутское',
  7: 'владивостокское',
  8: 'магаданское',
  9: 'камчатское',
};

export const getTimezone = ({
  withName = false,
}: { withName?: boolean } = {}) => {
  const offsetInHour =
    dayjs().tz().utcOffset() / 60 -
    dayjs().tz('Europe/Moscow').utcOffset() / 60;

  // Для минуса не указываем знак
  const sign = offsetInHour > 0 ? `+` : '';
  const timeZoneOffset = offsetInHour !== 0 ? sign + offsetInHour : '';

  if (withName) {
    return `${TimezoneNames[offsetInHour] || ''} время (МСК${timeZoneOffset})`;
  }

  return `МСК${timeZoneOffset}`;
};
