import { gql } from '../../../__generated__';

export const WS_UPDATE_SUBSCRIPTION_TOKEN = gql(/* GraphQL */ `
  mutation WS_UPDATE_SUBSCRIPTION_TOKEN($input: UpdateSubscriptionTokenInput!) {
    webSocket {
      updateSubscriptionToken(input: $input) {
        success
        __typename
      }
      __typename
    }
  }
`);
