import { gql } from 'shared/api/__generated__';

export const ACCOUNTS_FAKER_SEARCH = gql(/* GraphQL */ `
  query ACCOUNTS_FAKER_SEARCH(
    $filters: AccountsFilters!
    $pagination: Pagination!
  ) {
    accounts {
      getFilteredList(filters: $filters, pagination: $pagination) {
        items {
          id
          ... on Person {
            personalData {
              name
              surname
            }
          }
        }
        total
      }
    }
  }
`);
