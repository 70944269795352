import { PhotoDefect } from '../../__generated__';

export const UserMappedPhotoDefects: Partial<Record<PhotoDefect, string>> = {
  [PhotoDefect.NON_ACTUAL_PHOTO]: 'Неактуальное фото',
  [PhotoDefect.MANY_FACES]: 'Посторонние люди в кадре',
  [PhotoDefect.FOREIGN_OBJECTS]: 'Посторонние предметы в кадре',
  [PhotoDefect.HEADDRESS_IN_PHOTO]: 'Наличие головного убора',
  [PhotoDefect.DARK_GLASSES]: 'Наличие темных очков',
  [PhotoDefect.CLOSED_EYES]: 'Закрыты глаза',
  [PhotoDefect.CROPPED_FACE]: 'Обрезанное лицо',
  [PhotoDefect.UNEVEN_LIGHT]: 'Неравномерное освещение',
  [PhotoDefect.LOW_RESOLUTION]: 'Низкое разрешение',
};
