import { UserDetailsPage, UsersPage } from 'pages/User';

import { UsersRouteNames, UsersRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const UsersRoutes: Record<UsersRouteNames, TRouteProps> = {
  [UsersRouteNames.USERS]: {
    path: UsersRoutePaths.getRouteUsers(),
    element: <UsersPage />,
    isAuth: true,
    needRules: ['account.method.get_filtered_list'],
  },
  [UsersRouteNames.USER_DETAILS_INFO]: {
    path: UsersRoutePaths.getRouteUserDetailsInfo(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: ['account.method.get_one'],
  },
  [UsersRouteNames.USER_DETAILS_MED]: {
    path: UsersRoutePaths.getRouteUserDetailsMedData(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: ['account.method.get_one'],
  },
  [UsersRouteNames.USER_DETAILS_AUDIT_HISTORY]: {
    path: UsersRoutePaths.getRouteUserDetailsAuditHistory(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: ['account.method.get_one'],
  },
  [UsersRouteNames.USER_DETAILS_SETTINGS]: {
    path: UsersRoutePaths.getRouteUserDetailsSettings(':id'),
    element: <UserDetailsPage />,
    isAuth: true,
    needRules: ['account.method.get_one'],
  },
};
