import { IlClose } from '@mc/react-icons/il';
import { Drawer } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';

import {
  getBrowserStorage,
  removeBrowserStorage,
  setBrowserStorage,
} from 'shared/lib/utils/storage';
import { IconButton } from 'shared/ui/buttons/IconButton';
import { InfiniteList } from 'shared/ui/contents/InfiniteList';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { useNotificationsList } from '../lib/useNotificationsList';
import { NotificationDrawerMenu } from './NotificationDrawerMenu/NotificationDrawerMenu';
import { EmptyNotificationList } from './NotificationList/EmptyNotificationList';
import { NotificationList } from './NotificationList/NotificationList';
import cls from './NotificationsDrawer.module.scss';

interface NotificationsDrawerProps {
  open: boolean;
  /** Флаг наличия новых уведомлений */
  hasNew: boolean;
  onUpdateHasNew: (val: boolean) => void;
  onClose: () => void;
}

// TODO: Переделать NotificationList на рендер по одному айтему

export const NotificationsDrawer = (props: NotificationsDrawerProps) => {
  const { open, onClose, hasNew, onUpdateHasNew } = props;

  const [isUnreadOnly, setIsUnreadOnly] = useState(
    getBrowserStorage('NOTIFICATIONS_IS_UNREAD_ONLY') === 'true',
  );

  const saveIsUnreadOnly = (v: typeof isUnreadOnly) => {
    if (v) setBrowserStorage('NOTIFICATIONS_IS_UNREAD_ONLY', v.toString());
    else removeBrowserStorage('NOTIFICATIONS_IS_UNREAD_ONLY');
    setIsUnreadOnly(v);
  };

  const {
    data: { items },
    loading,
    isFullLoaded,
    loadMore,
    refetch,
    setPagination,
    error,
    variables,
  } = useNotificationsList({
    isRead: isUnreadOnly ? false : undefined,
  });

  const paginationUpdate = (limit: number) => {
    setPagination({
      limit,
      offset: 0,
    });
  };

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const handleOnClose = () => {
    paginationUpdate(10);
    onClose();
  };

  const handleLoadingNewNotifications = () => {
    // условие необходимо нам для того, чтобы при refetch() мы получали итемы не с сдвигом в offset, а от начала
    // до имета которого мы долистали
    if (variables.pagination.offset !== 0) {
      paginationUpdate(10 + variables.pagination.offset);
    }
    refetch();
    onUpdateHasNew(false);
  };

  return (
    <Drawer open={open} anchor='right' onClose={handleOnClose}>
      <Stack className={cls.DrawerRoot}>
        <Stack className={cls.DrawerContainer}>
          <Typography variant='h1'>Уведомления</Typography>
          <Stack direction='row'>
            <NotificationDrawerMenu
              isUnreadOnly={isUnreadOnly}
              onClose={handleOnClose}
              hasNotificationItemsList={!!items.length}
              onSwitcherChange={() => {
                paginationUpdate(10);
                saveIsUnreadOnly(!isUnreadOnly);
              }}
              onRefetch={refetch}
            />
            <IconButton onClick={handleOnClose}>
              <IconWrapper Svg={IlClose} />
            </IconButton>
          </Stack>
        </Stack>
        <InfiniteList
          isLoading={loading}
          error={error}
          scrollEndCallback={loadMore}
          count={items.length}
          isFullLoaded={isFullLoaded}
          items={[
            {
              key: 'list',
              items,
              hasNew,
              onClose: handleOnClose,
              onNew: handleLoadingNewNotifications,
            },
          ]}
          renderItem={(el) => <NotificationList {...el} />}
          emptyList={<EmptyNotificationList isUnreadOnly={isUnreadOnly} />}
        />
      </Stack>
    </Drawer>
  );
};
