import { createNewDate } from 'shared/lib/utils/dates';

import {
  INotificationItem,
  NotificationItemsPeriodData,
} from '../types/INotificationItem.types';

export const filterItemsByPeriod = (items: INotificationItem[]) => {
  const currentDate = createNewDate();

  /* время начала и конца спика уведомлений за сегодня */
  const beginningToday = currentDate.startOf('day');
  const endToday = currentDate.endOf('day');

  /* время начала и конца спика уведомлений за вчерашний день */
  const beginningYesterday = currentDate.subtract(1, 'day').startOf('day');
  const endYesterday = currentDate.subtract(1, 'day').endOf('day');

  /* время начала и конца спика уведомлений за прошедшие 7 дней */
  const beginningLastWeek = currentDate.subtract(7, 'day').endOf('day');
  const endLastWeek = currentDate.subtract(2, 'day').startOf('day');

  const todayData: NotificationItemsPeriodData = {
    notificationItemsList: [],
    dateFrom: beginningToday.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    dateTo: endToday.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  };
  const yesterdayData: NotificationItemsPeriodData = {
    notificationItemsList: [],
    dateFrom: beginningYesterday.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    dateTo: endYesterday.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  };
  const lastSevenDaysData: NotificationItemsPeriodData = {
    notificationItemsList: [],
    dateFrom: beginningLastWeek.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    dateTo: endLastWeek.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  };
  const pastMonthsData: NotificationItemsPeriodData = {
    notificationItemsList: [],
    dateFrom: currentDate
      .subtract(365, 'day')
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    dateTo: currentDate
      .subtract(8, 'day')
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  };

  items.forEach((el) => {
    // ВАЖНО! - между опциями не должно быть неучтенных временных промежутков
    // каждая опция начинается там, где заканчивается предыдущая
    /* Сегодня */
    if (
      !el.timestamp.isAfter(endToday) &&
      !el.timestamp.isBefore(beginningToday)
    ) {
      todayData.notificationItemsList.push(el);
      /* Вчера */
    } else if (
      !el.timestamp.isAfter(beginningToday) &&
      !el.timestamp.isBefore(beginningYesterday)
    ) {
      yesterdayData.notificationItemsList.push(el);
      /* За последние 7 дней */
    } else if (
      !el.timestamp.isAfter(beginningYesterday) &&
      !el.timestamp.isBefore(currentDate.subtract(8, 'day').startOf('day'))
    ) {
      lastSevenDaysData.notificationItemsList.push(el);
      /* За прошедшие месяцы */
    } else if (
      el.timestamp.isBefore(currentDate.subtract(8, 'day').startOf('day'))
    ) {
      pastMonthsData.notificationItemsList.push(el);
    }
  });

  return {
    todayData,
    yesterdayData,
    lastSevenDaysData,
    pastMonthsData,
  };
};
