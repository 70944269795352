/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: false,
};

export const lineSlice = createSlice({
  name: 'line',
  initialState,
  reducers: {
    setIsActive(state, { payload }) {
      state.isActive = payload;
    },
  },
});

export const { actions: lineSliceActions } = lineSlice;
export const { reducer: lineSliceReducer } = lineSlice;
