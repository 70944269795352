import { requiredField } from 'shared/lib/utils/validateForm';

export const MAX_LENGTH = 500;
export const MIN_LENGTH = 5;

export const userUnbanRules = {
  documentId: (hasForceUnban: boolean) =>
    hasForceUnban ? undefined : { ...requiredField() },

  comment: () => ({
    ...requiredField(),
    maxLength: {
      value: MAX_LENGTH,
      message: `Максимальная длина комментария ${MAX_LENGTH} символов`,
    },
    minLength: {
      value: MIN_LENGTH,
      message: `Минимальная длина комментария ${MIN_LENGTH} символов`,
    },
  }),
};
