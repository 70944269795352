import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../contents/Typography';
import { Chips } from '../../../special/Chips';

type SelectedItemChipsProps<TOption, TValue> = {
  isSearchFieldError?: boolean;
  selectedOptions: TOption[];
  getLabel?: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  getAvatar?: (option: TOption) => string | undefined;
  handleDelete: (value: TOption) => void;
  handleClear: () => void;
};

export const SelectedItemChips = <T, V>(
  props: SelectedItemChipsProps<T, V>,
) => {
  const {
    isSearchFieldError,
    selectedOptions,
    getLabel,
    getOptionValue,
    getAvatar,
    handleDelete,
    handleClear,
  } = props;

  const { t } = useTranslation();

  return (
    <Stack direction='column' gap='8px'>
      {isSearchFieldError && (
        <Typography variant='t3' color='var(--color-pink-100)'>
          {t('To search, enter more than 3 characters')}
        </Typography>
      )}

      <Chips
        isExpandable
        isClearable
        items={selectedOptions}
        getLabel={getLabel}
        getId={getOptionValue}
        getAvatar={getAvatar}
        onDelete={handleDelete}
        onClear={handleClear}
      />
    </Stack>
  );
};
