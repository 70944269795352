import { Breadcrumb, BreadcrumbHint } from '@sentry/react';

export function generateDomElementDescription(element: HTMLElement) {
  const tag = element.tagName.toLowerCase();
  const classes = Array.from(element.classList).join('.');
  const { id } = element;

  return tag + (classes ? `.${classes}` : '') + (id ? `#${id}` : '');
}

export function clearString(line: string) {
  return line.replace(/[\s{2,}|\\n|\\t]/g, ' ').trim();
}

/**
 * Текст для консоли в sentry по клику на кнопку
 */
function handleUiClickBreadcrumb(breadcrumb: Breadcrumb, event: any) {
  const closestButton = event.target.closest('button');
  if (!closestButton) return breadcrumb;

  const elementDescription = generateDomElementDescription(closestButton);
  const mark = closestButton.dataset.sentryMark; // Для кнопок с тегом [data-sentry-mark="some text"]
  const text = clearString(closestButton.innerText);

  const message = [
    mark ? `Mark: "${mark}"` : null,
    text ? `Inner text: "${text}"` : null,
    `Button target: ${elementDescription}`,
    closestButton !== event.target
      ? `Real target: ${breadcrumb.message}`
      : null,
  ]
    .filter((item) => item)
    .join('\n');

  return {
    ...breadcrumb,
    category: 'ui.button',
    message,
  };
}

/**
 * Обработка сообщений попадаемых в консоль
 */
export function beforeBreadcrumb(
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint,
): Breadcrumb | null {
  if (breadcrumb.category === 'ui.click')
    return handleUiClickBreadcrumb(breadcrumb, hint?.event);
  return breadcrumb;
}
