import { AutocompleteRenderOptionState } from '@mui/material';
import { createContext } from 'react';

type TUniversalAutocompleteContext<T extends object> = {
  countItems: () => number;
  loadMore?: () => void;
  isFetching: () => boolean;
  render: (
    item: React.HTMLAttributes<HTMLElement> & { key: string },
    data: T,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  pageSize?: number;
  slots?: {
    top?: React.ReactNode;
    bottom?: React.ReactNode;
  };
};

export const UniversalAutocompleteContext = createContext<
  TUniversalAutocompleteContext<any>
>({
  countItems: () => 0,
  isFetching: () => false,
  render: () => null,
  pageSize: 20,
});
