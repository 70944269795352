import { Radio as MUIRadio, RadioProps } from '@mui/material';
import { styled } from '@mui/system';

import { classNames } from 'shared/lib/utils/classNames';

const McRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  overflow: 'hidden',
  borderStyle: 'solid',
  borderWidth: `1px`,
  borderColor: '#656A83',
  backgroundColor: '#FFFFFF',
  '.Mui-focusVisible &': {
    outline: '2px auto #435DE555',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    borderColor: '#435DE5',
  },
  'input:disabled ~ &': {
    borderColor: '#E3E5F1',
  },
}));

const McRadioCheckedIcon = styled(McRadioIcon)({
  borderRadius: '50%',
  borderColor: '#435DE5',
  'input:hover ~ &': {
    borderColor: '#435DE5',
  },
  'input:disabled ~ &': {
    borderColor: '#B5BAD0',
  },
});

export const Radio = (props: RadioProps) => {
  const { size, className, ...otherProps } = props;

  const _size = size === undefined ? 'medium' : size;

  return (
    <MUIRadio
      disableRipple
      color='default'
      checkedIcon={<McRadioCheckedIcon />}
      icon={<McRadioIcon />}
      className={classNames('', {}, [className, _size])}
      {...otherProps}
    />
  );
};
