import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

import { RequiredStar } from '../../../../helpers/RequiredStar';
import cls from './textFieldStyle.module.scss';

export const TextField = forwardRef(
  (props: TextFieldProps, ref: TextFieldProps['ref']) => {
    const { InputProps, InputLabelProps, ...args } = props;

    const fullWidth = args?.fullWidth ?? true;
    const size = args?.size ?? 'medium';

    // !!!ОТСУТСТВИЕ ЗНАЧЕНИЯ В ПРОПСЕ SIZE ВОЗВРАЩАЕТ ВЫСОТУ ИНПУТА В 48PX!!!
    return (
      <MUITextField
        ref={ref}
        {...args}
        fullWidth={fullWidth}
        size={size}
        className={
          !args?.size && !args?.className ? cls.CustomSize : args?.className
        }
        data-sentry-mark={args?.label || args?.placeholder}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <>
              {props?.required && <RequiredStar error={!!args.error} />}
              {InputProps?.endAdornment}
            </>
          ),
        }}
        InputLabelProps={{
          ...InputLabelProps,
          required: InputLabelProps?.required || false,
        }}
      />
    );
  },
);

TextField.displayName = 'TextField';

// export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
//   TextFieldNonRef,
// );
