import { Stack } from '@mui/material';
import { Suspense } from 'react';

import { NavbarLogin } from 'widgets/Navbar';
import { PageLoader } from 'widgets/PageLoader';

interface UnauthorizedPageProps {
  children: React.ReactNode;
}

export const UnauthorizedPage = (props: UnauthorizedPageProps) => {
  const { children } = props;

  return (
    <Stack
      flex={1}
      alignItems='center'
      minHeight='calc(100vh - var(--navbar-height) - 110px)'
    >
      <NavbarLogin />
      <Stack alignItems='center' justifyContent='center'>
        <Suspense fallback={<PageLoader />}>{children}</Suspense>
      </Stack>
    </Stack>
  );
};
