import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
} from '@mui/material';

export const IconButton = (props: MUIIconButtonProps) => {
  const { children } = props;

  return <MUIIconButton {...props}>{children}</MUIIconButton>;
};
