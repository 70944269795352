import { nameFormat } from 'shared/lib/utils/nameFormat';

export const getFullName = (state: RootState) => {
  if (state?.user?.personalData) {
    const { surname, name, patronymic } = state.user.personalData;

    return nameFormat(name, surname, patronymic);
  }

  return '';
};
