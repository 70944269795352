import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { authSliceActions } from 'processes/auth';
import { getIsLoading, userActions } from 'processes/user';

import { Navbar } from 'widgets/Navbar';
import { PageError } from 'widgets/PageError';
import { PageLoader } from 'widgets/PageLoader';

import { GET_ME } from 'shared/api/authApi';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { setBrowserStorage } from 'shared/lib/utils/storage';

interface InternalResourceGuardProps {
  children?: React.ReactNode;
  beforeEach?: () => void;
}

export const InternalResourceGuard = (props: InternalResourceGuardProps) => {
  const { children, beforeEach } = props;

  useEffect(() => {
    beforeEach?.();
  }, [beforeEach]);

  const dispatch = useAppDispatch();
  const errorHandlerContext = useErrorHandlerContext();

  const [getMe, { error }] = useLazyQuery(GET_ME, {
    context: errorHandlerContext({
      message: 'Не удалось загрузить данные пользователя',
    }),
  });
  const isLoading = useSelector(getIsLoading);

  const getMeInfo = useCallback(async () => {
    try {
      dispatch(userActions.setIsLoading('loading'));

      // После логина, делаем запрос данных о текущем юзере
      const response = await getMe();
      const userData = response.data?.accounts.getMe;

      if (userData) {
        // Тип URL не сериализуется в redux, поэтому приводим его к строке
        userData.currentPhoto = userData?.currentPhoto?.url
          ? {
              __typename: 'Photo',
              url: String(userData?.currentPhoto?.url) || undefined,
            }
          : undefined;
        dispatch(userActions.setUser(userData));
        dispatch(authSliceActions.setOwnerId(userData.owner.id));
      }
      dispatch(userActions.setIsLoading('success'));
      setBrowserStorage('ACCOUNT_ID', response.data?.accounts.getMe.id);
      dispatch(authSliceActions.setAccountId(response.data?.accounts.getMe.id));
    } catch (err) {
      console.error('Не удалось получить GET_ME', err);
      dispatch(userActions.setError(String(err)));
      dispatch(userActions.setIsLoading('error'));
    }
  }, [dispatch, getMe]);

  // При первом рендере, делаем запрос данных о текущем юзере
  useEffect(() => {
    getMeInfo();
  }, [getMeInfo]);

  if (error) {
    return (
      <>
        <Navbar />
        <PageError />
      </>
    );
  }

  return isLoading === 'loading' || isLoading === 'idle' ? (
    <PageLoader />
  ) : (
    children
  );
};
