import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBreadcrumb } from 'shared/ui/special/OrgUnitBreadcrumbs/types';

import { OrgUnitDetailsSchema } from '../types/orgUnitDetailsSchema';

const initialState: OrgUnitDetailsSchema = {
  orgUnitsHistory: [],
};

export const orgUnitDetailsSlice = createSlice({
  name: 'orgUnitDetails',
  initialState,
  reducers: {
    removeHistoryItem: (state) => {
      state.orgUnitsHistory.pop();
    },
    addHistoryItem: (state, { payload }: PayloadAction<IBreadcrumb>) => {
      // Получаем последний элемент, на который кликнули
      const lastOrgUnit =
        state.orgUnitsHistory[state.orgUnitsHistory.length - 1];

      const { id, subtreeCounts } = payload;

      // Проверка нужна, чтобы не было добавления OU при повторных кликах по одному и тому же OU
      if (lastOrgUnit?.id !== id) {
        state.orgUnitsHistory.push({
          id,
          directDescendants: subtreeCounts?.directDescendants || 0,
        });
      }
    },
    resetHistory: (state) => {
      // XXX: eslint-disable-next-line no-param-reassign это свойство не дает использовать
      // мутабельный стиль внутри редюсеров, под капотом RTQ использует immer, который
      // в любом случае приводит наш код к иммутабельному стилю, а
      // каждый раз спредить стейт не удобно (...state), предлагаю выключить это правило
      // eslint-disable-next-line no-param-reassign
      state.orgUnitsHistory = [];
    },
  },
});

export const { actions: orgUnitDetailsActions } = orgUnitDetailsSlice;
export const { reducer: orgUnitDetailsReducer } = orgUnitDetailsSlice;
