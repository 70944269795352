export enum OrgUnitsRouteNames {
  ORG_UNIT_DETAILS_AUDIT_HISTORY = 'org_unit_details_audit_history',
  ORG_UNIT_DETAILS_EMPLOYEES = 'org_unit_details_employees',
  ORG_UNIT_DETAILS_EXAMS = 'org_unit_details_exams',
  ORG_UNIT_DETAILS_HISTORY = 'org_unit_details_history',
  ORG_UNIT_DETAILS_HOSTS = 'org_unit_details_hosts',
  ORG_UNIT_DETAILS_INFO = 'org_unit_details_info',
  ORG_UNIT_DETAILS_ORGS = 'org_unit_details_orgs',
  ORG_UNIT_DETAILS_SETTINGS = 'org_unit_details_settings',
  ORG_UNITS = 'org_units',
}
