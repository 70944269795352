import { useMutation, useSubscription } from '@apollo/client';
import { IlBell } from '@mc/react-icons/il';
import { Badge, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { NotificationsDrawer } from 'features/Notification';

import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { useRetryAttempt } from 'shared/lib/hooks/useRetryAttempt';
import { useSubscriptionManager } from 'shared/lib/hooks/useSubscriptionManager';
import { logger } from 'shared/lib/utils/logger';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { NavbarMenuItemContainer } from '../NavbarMenuItemContainer/NavbarMenuItemContainer';
import { notificationSetLastOpened } from './api/notificationSetLastOpened.gql';
import { NotificationsTracking } from './api/notificationTracking.gql';
import cls from './NavbarNotificationsControl.module.scss';

const logWs = logger('WS', '[NavbarNotificationsControl]');

export const NavbarNotificationsControl = () => {
  const [hasNew, setHasNew] = useState<boolean>(false);
  const [hasUnread, setHasUnread] = useState(false);

  const { onErrorHandler } = useRetryAttempt();
  const errorHandlerContext = useErrorHandlerContext();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [setLastOpened] = useMutation(notificationSetLastOpened, {
    context: errorHandlerContext({
      message: 'Не удалось сообщить об открытии панели уведомлений на сервер',
    }),
  });

  const { skip, run, restart } = useSubscriptionManager();
  useEffect(() => {
    run();
  }, [run]);

  useSubscription(NotificationsTracking, {
    skip,
    onData: (res) => {
      if (res.data.data?.myNotifications.__typename === 'Disconnect') {
        logWs('disconnect с бэка');
        return;
      }

      const hasNewInWS =
        res.data.data?.myNotifications.__typename === 'NewNotifications' &&
        res.data.data?.myNotifications.hasNew;

      const hasUnreadInWS =
        res.data.data?.myNotifications.__typename === 'NewNotifications' &&
        res.data.data?.myNotifications.hasUnread;

      setHasNew(hasNewInWS);
      setHasUnread(hasUnreadInWS);
    },
    onError: (err) => {
      logWs('[ERROR]', err?.message || err);
      onErrorHandler({
        repeat: restart,
        stop: () => {
          // TODO: Сделать отображение ошибки с ребутом подписки
        },
      });
    },
  });

  const handleOpenDrawer = async () => {
    try {
      setHasNew(false);
      setIsDrawerOpen(true);

      const res = await setLastOpened();

      if (res.errors) {
        console.error(res.errors);
        throw new Error(
          'Не удалось сообщить об открытии панели уведомлений на сервер',
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <NavbarMenuItemContainer className={cls.Root}>
      <Stack
        width='100%'
        height='100%'
        justifyContent='center'
        alignItems='center'
        onClick={handleOpenDrawer}
      >
        <Badge
          color='error'
          variant='dot'
          overlap='circular'
          badgeContent=' '
          invisible={!hasUnread}
        >
          <IconWrapper
            style={{ color: 'var(--color-monochrome-grey-500)' }}
            Svg={IlBell}
          />
        </Badge>
      </Stack>
      <NotificationsDrawer
        open={isDrawerOpen}
        hasNew={hasNew}
        onUpdateHasNew={(val) => {
          setHasNew(val);
        }}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      />
    </NavbarMenuItemContainer>
  );
};
