import { gql } from '../__generated__';

export const USER_GET_ACTIVE_BAN = gql(/* GraphQL */ `
  query USER_GET_ACTIVE_BAN($id: UUID!, $pagination: Pagination!) {
    bans {
      getList(filters: { id: $id, isActive: true }, pagination: $pagination) {
        items {
          id
          reason
          timestamp
          sourceExam {
            data {
              ... on ProcessedExam {
                preprocessed {
                  base {
                    id
                    serial
                  }
                }
              }

              ... on ProcessingFailure {
                preprocessed {
                  base {
                    id
                    serial
                  }
                }
              }

              ... on ProcessingTechCancelled {
                preprocessed {
                  base {
                    id
                    serial
                  }
                }
              }

              ... on ProcessingUserCancelled {
                preprocessed {
                  base {
                    id
                    serial
                  }
                }
              }

              ... on PreprocessingFailure {
                base {
                  id
                  serial
                }
              }
            }
          }
          actor {
            ... on Person {
              id
              personalData {
                name
                surname
                patronymic
              }
            }

            ... on System {
              type
            }

            ... on Bot {
              type
            }
          }
        }
      }
    }
  }
`);
