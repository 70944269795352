import { Timespan } from '@rsdk/common';
import { useCallback, useRef } from 'react';

const MAX_RETRY_COUNT = 10;

export const useRetryAttempt = (
  { attempts }: { attempts: number } = { attempts: MAX_RETRY_COUNT },
) => {
  const count = useRef(0);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const incrementCount = useCallback(() => {
    if (!timeoutId.current) {
      timeoutId.current = setTimeout(() => {
        count.current = 0;
        timeoutId.current = null;
      }, new Timespan(30, 's').millis());
    }

    count.current += 1;
  }, []);

  const onErrorHandler = useCallback(
    ({ repeat, stop }: { repeat: () => void; stop: () => void }) => {
      if (count.current >= attempts) {
        stop();
      } else {
        incrementCount();
        setTimeout(repeat, new Timespan(count.current + 1, 's').millis());
      }
    },
    [incrementCount, attempts],
  );

  return {
    onErrorHandler,
    getCount: () => count.current,
  };
};
