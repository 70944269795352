import { IlGlobe1 } from '@mc/react-icons/il';
import { useMemo } from 'react';

import { createSelectors } from 'shared/lib/external/zustand';
import { applyPlural } from 'shared/lib/utils/applyPlural';
import { toUserTime } from 'shared/lib/utils/datetime';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';
import { Tooltip } from 'shared/ui/helpers/Tooltip';

import { useServerInfoStore } from '../store/ServerInfo.store';

const dictPluralize = {
  seconds: ['секунду', 'секунды', 'секунд'],
  minutes: ['минуту', 'минуты', 'минут'],
  hours: ['час', 'часа', 'часов'],
};

export const IndicatorClientTimeOffset = () => {
  const serverInfoStore = createSelectors(useServerInfoStore);
  const clientOffset = serverInfoStore.use.clientOffset?.();
  const _clientOffset = clientOffset || 0;
  const _clientOffsetWithAbs = Math.abs(clientOffset || 0);

  const tooltipText = useMemo(() => {
    const time = toUserTime(_clientOffsetWithAbs / 1000);
    const [s, m, h] = time.split(':').reverse().map(Number);

    const timeText = [
      h > 0 ? `${h} ${applyPlural(h, dictPluralize.hours)}` : null,
      m > 0 ? `${m} ${applyPlural(m, dictPluralize.minutes)}` : null,
      s > 0 ? `${s.toFixed(0)} ${applyPlural(s, dictPluralize.seconds)}` : null,
    ]
      .filter(Boolean)
      .join(' ');

    return `Клиентское время ${_clientOffset > 0 ? 'спешит' : 'отстаёт'} на ${timeText}`;
  }, [_clientOffset, _clientOffsetWithAbs]);

  if (_clientOffsetWithAbs < 3000) {
    return null;
  }

  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          position: 'fixed',
          zIndex: 9999,
          bottom: 0,
          left: 0,
        }}
      >
        <IconWrapper
          Svg={IlGlobe1}
          width={16}
          height={16}
          style={{
            color:
              _clientOffsetWithAbs > 7000
                ? 'var(--color-pink-200)'
                : 'var(--color-yellow-200)',
            width: 16,
            height: 16,
          }}
        />
      </div>
    </Tooltip>
  );
};
