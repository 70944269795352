import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from 'shared/lib/utils/classNames';
import { Loader } from 'shared/ui/helpers/Loader';

import cls from './PageLoader.module.scss';

interface PageLoaderProps {
  className?: string;
}

export const PageLoader: FC<PageLoaderProps> = (props) => {
  const { className, ...otherProps } = props;

  const { t } = useTranslation();

  return (
    <div
      className={classNames(cls.PageLoader, {}, [className])}
      {...otherProps}
    >
      <Loader>
        {t('Loading')}
        ...
      </Loader>
    </div>
  );
};
