import { gql } from '../__generated__';

export const GET_ME = gql(/* GraphQL */ `
  query GET_ME {
    accounts {
      getMe {
        id
        email
        personalData {
          name
          surname
          patronymic
        }
        role {
          type
          name
          permissions {
            key
            description
          }
        }
        currentPhoto {
          url
        }
        owner {
          ... on OrgUnit {
            id
            displayShortname
          }
          ... on OrgUnitPreview {
            id
            displayShortname
          }
        }
        accessLinks {
          id
        }
      }
    }
  }
`);
