/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { ApolloLink, gql } from '@apollo/client';
import { DirectiveNode, FieldNode, print, visit } from 'graphql';

interface Variables {
  [key: string]: any;
}

/**
 * Проверить содержит ли переменная 'skip'
 */
const shouldSkipVariable = (name: string, variables: Variables): boolean => {
  return name.includes('skip') && !!variables;
};

/**
 * Удаление директивы skip из запроса (по значению переменной)
 */
const removeDirectiveSkip = (node: FieldNode, variables: Variables) => {
  // Ищем директиву skip в ноде
  const directiveSkip = node.directives?.find(
    (el: DirectiveNode) => el.name.value === 'skip',
  );

  // Если директива skip не найдена, то прерываем скрипт
  if (!directiveSkip) return node;

  /** Первый аргумент директивы skip */
  const arg = directiveSkip?.arguments?.[0].value;

  /** Ключ переменной */
  const argKey = arg && 'name' in arg && arg.name.value;

  /** Значение из списка variables для переменной */
  const argValue = argKey ? variables[argKey] : undefined;

  // Удалить ноду, где есть директива skip и argValue = true
  // (@skip = true)
  if (argValue) return null;

  /** Нода без директив skip */
  const nodeFilteredDirective = {
    ...node,
    directives: node.directives?.filter(
      (el: DirectiveNode) => el.name.value !== 'skip',
    ),
  };

  return nodeFilteredDirective;
};

/**
 * Middleware, убирающий из запроса поля и переменные,
 * содержащие skip со значением true
 */
export const removeSkip = new ApolloLink((operation, forward) => {
  /** Дерево запроса */
  const query = operation?.query;

  /** Список всех переменных запроса */
  const variables: Variables = operation.variables || {};

  // Обходим документ, удаляя поля и переменные с директивой @skip
  const newDocument = visit(query, {
    // Проверка переменных
    VariableDefinition: (node) => {
      /** Название переменной в блоке variables запроса */
      const { value } = node.variable.name;

      // Удаляем переменную, если она должна быть пропущена
      if (shouldSkipVariable(value, variables)) return null;
      return node;
    },
    Field: {
      leave(node) {
        const newNode = removeDirectiveSkip(node, variables);
        return newNode;
      },
    },
  });

  // Преобразуем обновленный документ обратно в строку запроса
  operation.query = gql(print(newDocument));

  return forward(operation);
});
