import { gql } from '@apollo/client';

export const SyncHostVerification = gql(/* GraphQL */ `
  mutation SyncHostVerification($input: SyncHostInput!) {
    verifications {
      syncHost(input: $input) {
        items {
          certificateNumber
          date
          dueDate
          authority
          result
        }
      }
    }
  }
`);
