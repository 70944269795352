import { IlShield } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { getCanMakeActionRule } from 'processes/user';

import { classNames } from 'shared/lib/utils/classNames';
import { Dropdown, DropdownItem } from 'shared/ui/buttons/Dropdown';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import clsContainer from '../NavbarMenuItemContainer/NavbarMenuItemContainer.module.scss';
import cls from './NavbarAdminpanelMenu.module.scss';

export const NavbarAdminpanelMenu = () => {
  const hasAccessToPermissionGroups = useSelector(
    getCanMakeActionRule('role_management.method.get_permission_groups'),
  );
  const hasAccessToRoles = useSelector(
    getCanMakeActionRule('role_management.method.get_roles'),
  );

  const hasAccessToHostTypesGetList = useSelector(
    getCanMakeActionRule('host_types.method.get_list'),
  );

  const hasAccessToAudit = useSelector(
    getCanMakeActionRule('admin_panel.audit.view'),
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const isActive = location.pathname.includes('adminpanel');

  const getContainerClass = () => {
    if (isActive) return cls.AdminPanelAnchorActive;
    if (isOpen) return cls.AdminPanelAnchorOpen;
    return cls.AdminPanelAnchor;
  };

  /**
   * Список элементов для Dropdown.
   * Высчитывается на основе прав доступа.
   */
  const dropdownItems = useMemo(() => {
    const arr: DropdownItem[] = [];

    if (hasAccessToPermissionGroups) {
      arr.push({
        id: 'roles',
        content: <Typography variant='t2'>Управление ролями</Typography>,
        onClick: () => navigate('/adminpanel/roles'),
        disabled: !hasAccessToPermissionGroups,
      });
    }

    if (hasAccessToRoles) {
      arr.push({
        id: 'groups',
        content: <Typography variant='t2'>Группы разрешений</Typography>,
        onClick: () => navigate('/adminpanel/groups'),
        disabled: !hasAccessToRoles,
      });
    }

    if (hasAccessToHostTypesGetList) {
      arr.push({
        id: 'firmware',
        content: <Typography variant='t2'>Версии прошивок</Typography>,
        onClick: () => navigate('/adminpanel/firmware'),
        disabled: !hasAccessToHostTypesGetList, // XXX: заменить на корректные пермишены
      });
    }

    if (hasAccessToAudit) {
      arr.push({
        id: 'audit',
        content: <Typography variant='t2'>Аудит</Typography>,
        onClick: () => navigate('/adminpanel/audit'),
        disabled: !hasAccessToAudit,
      });
    }

    return arr;
  }, [
    hasAccessToPermissionGroups,
    hasAccessToRoles,
    hasAccessToHostTypesGetList,
    hasAccessToAudit,
    navigate,
  ]);

  return (
    <Dropdown
      items={dropdownItems}
      trigger={
        <Stack
          className={classNames(clsContainer.Item, {}, [getContainerClass()])}
        >
          <Stack
            className={cls.AdminPanelContainer}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IconWrapper Svg={IlShield} />
            <Typography variant='h4' className={cls.Title}>
              Админпанель
            </Typography>
          </Stack>
        </Stack>
      }
      onClose={() => {
        setIsOpen(false);
      }}
      onTrigger='hover'
    />
  );
};
