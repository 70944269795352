import { ApolloClient, ApolloLink } from '@apollo/client';

import { apolloCache } from '../helpers/apolloCache';
import { cleanVariables } from '../links/cleanVariables';
import { errorLink } from '../links/errorLink';
import { removeSkip } from '../links/removeSkip';
import { removeUnusedFragments } from '../links/removeUnusedFragments';
import { removeUnusedVariables } from '../links/removeUnusedVariables';
import { requestIdLink } from '../links/requestIdLink';
import { restLink } from '../links/restLink';
import { scalarLink } from '../links/scalarLink';
import { defaultOptions } from './config';

/**
 * Apollo Client без авторизации (не поддерживает subscriptions)
 */
export const apolloClientWithoutAuth = new ApolloClient({
  connectToDevTools: import.meta.env.__DEV__,
  link: ApolloLink.from([
    errorLink,
    scalarLink,
    cleanVariables,
    removeSkip,
    removeUnusedFragments,
    removeUnusedVariables,
    requestIdLink,
    restLink,
  ]),
  cache: apolloCache,
  defaultOptions,
});
