import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useHostReport } from 'entities/Host';

import { hostReportContextDefaultValue } from './const';
import { useIsTherePendingLogReport } from './hooks/useIsTherePendingLogReport';

export const HostReportDataContext = createContext<
  ReturnType<typeof useHostReport> &
    ReturnType<typeof useIsTherePendingLogReport>
>(hostReportContextDefaultValue);

export const HostReportDataContextProvider = (props: {
  children: ReactNode;
  hostId: string;
}) => {
  const { children, hostId } = props;

  const hostReportsHookData = useHostReport(hostId);

  const handleLastReportPendingFinish = useCallback(() => {
    hostReportsHookData.refetchAll();
  }, [hostReportsHookData]);

  const hostLastLogReportHookData = useIsTherePendingLogReport(hostId, {
    onPendingFinish: handleLastReportPendingFinish,
  });

  // обертка над хэндлером изменения фильтра,
  // добавляет обновление данных для состояния кнопки "Запросить логи"
  const handleChangeFilters = useCallback<
    typeof hostReportsHookData.changeFilters
  >(
    (...args) => {
      hostReportsHookData.changeFilters(...args);
      hostLastLogReportHookData.refetchLastLogReport();
    },
    [hostLastLogReportHookData, hostReportsHookData],
  );

  const value = useMemo(
    () => ({
      ...hostLastLogReportHookData,
      ...hostReportsHookData,
      changeFilters: handleChangeFilters,
    }),
    [hostReportsHookData, hostLastLogReportHookData, handleChangeFilters],
  );

  return (
    <HostReportDataContext.Provider value={value}>
      {children}
    </HostReportDataContext.Provider>
  );
};

export const useHostReportDataContext = () => useContext(HostReportDataContext);
