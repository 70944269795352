import { setContext } from '@apollo/client/link/context';

import { getUniqueId } from 'shared/lib/external/nanoid';

/**
 * Добавляет заголовок с номером запроса
 */
export const requestIdLink = setContext(async (_, ctx) => {
  return {
    ...ctx,
    headers: {
      ...ctx.headers,
      'X-RequestID': getUniqueId(),
    },
  };
});
