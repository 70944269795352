import { AUTH_OPERATIONS_IGNORE, REFRESH_TOKENS_NAME } from 'shared/const/auth';

/**
 * Проверяет, нужна ли авторизация для запроса
 */
export const checkNeedAuth = (operationName?: string) => {
  if (!operationName) return false;

  const result =
    operationName !== REFRESH_TOKENS_NAME &&
    AUTH_OPERATIONS_IGNORE.indexOf(operationName) === -1;

  return result;
};
