import { ApolloLink } from '@apollo/client';

import { removeNestedFields } from 'shared/lib/utils/removeNestedFields';

/**
 * Удаляет __typename из variables (в глубину)
 *
 * TODO: удалить после переработки параметров запроса из форм.
 */
export const cleanVariables = new ApolloLink((operation, forward) => {
  const { input } = operation.variables || {};
  if (!input) return forward(operation);

  const newInput = removeNestedFields(input, '__typename', () => {
    console.warn(
      `В запросе ${operation.operationName} обнаружен __typename`,
      input,
    );
  });
  // eslint-disable-next-line no-param-reassign
  operation.variables = {
    ...operation.variables,
    input: newInput,
  };

  return forward(operation);
});
