import { LoginType } from 'shared/api/__generated__';

export const LoginTypesMap: Record<LoginType, string> = {
  [LoginType.PHONE]: 'Телефон',
  [LoginType.EMAIL]: 'E-mail',
  [LoginType.SNILS]: 'СНИЛС',
  [LoginType.DRIVERS_LICENSE]: 'ВУ',
  [LoginType.RFID]: 'RFID-карта',
  [LoginType.FACE]: 'FaceID',
  [LoginType.PN]: 'Табельный номер',
};
