import {
  DRIVERS_LICENSE_SCHEMA,
  EMAIL_SCHEMA,
  PASSWORD_SCHEMA,
  PHONE_SCHEMA,
  SNILS_SCHEMA,
} from '@mc/domain-validation';
import { ControllerProps } from 'react-hook-form';

import { LoginType } from 'shared/api/__generated__';
import { requiredField } from 'shared/lib/utils/validateForm';

type LoginRulesType = {
  [key in 'password' | LoginType]: ControllerProps['rules'];
};

export const loginRules: LoginRulesType = {
  [LoginType.EMAIL]: {
    ...requiredField(),
    pattern: {
      value: new RegExp(EMAIL_SCHEMA.pattern!),
      message: 'Невалидный email',
    },
  },
  [LoginType.DRIVERS_LICENSE]: {
    ...requiredField(),
    pattern: {
      value: new RegExp(DRIVERS_LICENSE_SCHEMA.pattern!),
      message: DRIVERS_LICENSE_SCHEMA.description,
    },
  },

  [LoginType.PHONE]: {
    ...requiredField(),
    pattern: {
      value: new RegExp(PHONE_SCHEMA.pattern!),
      message: PHONE_SCHEMA.description,
    },
  },

  [LoginType.SNILS]: {
    ...requiredField(),
    pattern: {
      value: new RegExp(SNILS_SCHEMA.pattern!),
      message: SNILS_SCHEMA.description,
    },
  },

  [LoginType.FACE]: undefined,
  [LoginType.PN]: undefined,
  [LoginType.RFID]: undefined,

  password: {
    ...requiredField(),
    pattern: {
      value: new RegExp(PASSWORD_SCHEMA.pattern!),
      message: PASSWORD_SCHEMA.description,
    },
  },
};
