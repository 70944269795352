import { gql } from 'shared/api/__generated__';

export const NotificationSetIsReadByDate = gql(/* GraphQL */ `
  mutation NotificationSetIsReadByDate($input: SetReadOrUnreadByDateInput!) {
    notifications {
      setReadOrUnreadByDate(input: $input) {
        nothing
      }
    }
  }
`);
