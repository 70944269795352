import { PaletteMode, PaletteOptions } from '@mui/material';

export const createPalette = (
  cssVar: (key: string) => string,
  mode: PaletteMode,
): PaletteOptions => ({
  mode,
  primary: {
    main:
      mode === 'light'
        ? cssVar('--color-blue-100')
        : cssVar('--color-blue-100'),
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: cssVar('--color-green-100'),
    contrastText: '#FFFFFF',
  },
  error: {
    main: cssVar('--color-pink-100'),
    contrastText: '#FFFFFF',
  },
  neutral: {
    main: cssVar('--color-monochrome-grey-300'),
    contrastText: cssVar('--color-monochrome-grey-400'),
  },
  lightBlue: {
    main: cssVar('--color-blue-000'),
    contrastText: cssVar('--color-blue-200'),
  },
  default: {
    main: cssVar('--color-monochrome-grey-100'),
    contrastText: cssVar('--color-monochrome-grey-500'),
  },
  warning: {
    main: cssVar('--color-yellow-100'),
  },
});
