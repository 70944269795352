import { useCallback, useEffect, useRef, useState } from 'react';

type TimeoutId = ReturnType<typeof setTimeout>;

/**
 * Хук, который позволяет вызывать функцию каждые delay милисекунд (последний вызов функции срабатывает всегда)
 */
export function useThrottle<T extends (...args: (any | unknown)[]) => void>(
  /** Отложенная функция, должна всегда быть через useCallback */
  callback: T,
  delay: number,
): T {
  const lastCall = useRef<TimeoutId | null>(null);
  const lastExecution = useRef<number>(Date.now());

  const [throttledCallback, setThrottledCallback] = useState<T>(callback);

  useEffect(() => {
    setThrottledCallback(() => callback);
  }, [callback]);

  const throttled = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      if (lastCall.current) {
        clearTimeout(lastCall.current);
      }

      if (now - lastExecution.current >= delay) {
        lastExecution.current = now;
        throttledCallback(...args);
      } else {
        lastCall.current = setTimeout(
          () => {
            lastExecution.current = Date.now();
            throttledCallback(...args);
          },
          delay - (now - lastExecution.current),
        );
      }
    },
    [delay, throttledCallback],
  ) as T;

  useEffect(() => {
    return () => {
      if (lastCall.current) clearTimeout(lastCall.current);
    };
  }, []);

  return throttled;
}
