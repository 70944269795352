import { onError } from '@apollo/client/link/error';
import { GraphQLFormattedError } from 'graphql';

import { captureException } from 'shared/lib/external/sentry';

class GqlError extends Error {
  gqlErrorOriginal: GraphQLFormattedError;

  constructor(
    message: string,
    payload: {
      operationName: string;
      code: unknown;
      gqlErrorOriginal: GraphQLFormattedError;
    },
  ) {
    super(message);
    this.name = `GqlError [${payload.code || ''}] ${payload.operationName}`;
    this.gqlErrorOriginal = payload.gqlErrorOriginal;
    // this.stack = payload.gqlErrorOriginal;
  }
}

/**
 * Отображение ошибок Apollo в консоли
 * NOTE: ВАЖНО: только отображение!
 */
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors) {
      const ctx = operation.getContext();
      const requestId = ctx.headers['X-RequestID'];

      if (ctx.errorHandler) {
        ctx.errorHandler({ requestId, errors: response?.errors });
      }

      (graphQLErrors || []).forEach((err) => {
        const { message, locations, path, extensions } = err;

        // eslint-disable-next-line no-console
        console.error(
          `[GQL error]:\n`,
          `\tOperation: ${operation.operationName}\n`,
          `\tVars: ${JSON.stringify(operation.variables)}\n`,
          `\tMessage: ${message},\n`,
          `\tLocation: ${locations ? JSON.stringify(locations) : 'undefined'},\n`,
          `\tPath: ${path}`,
        );

        captureException(
          new GqlError(message, {
            operationName: operation.operationName,
            code: extensions?.code,
            gqlErrorOriginal: err,
          }),
          { requestId },
        );
      });
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[GQL network error]: ${networkError}`);
    }
  },
);
