import { memo, MutableRefObject, ReactNode, useRef } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import cls from './Page.module.scss';

interface PageProps {
  className?: string;
  children: ReactNode;
  onScrollEnd?: () => void;
}
/**
 * Компонент для реализации InfiniteScroll
 */
export const Page = memo((props: PageProps) => {
  const { className, children, onScrollEnd } = props;

  const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <main className={classNames(cls.Page, {}, [className])} ref={wrapperRef}>
      {children}
      {onScrollEnd ? <div className={cls.trigger} ref={triggerRef} /> : null}
    </main>
  );
});

Page.displayName = 'Page';
