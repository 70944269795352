import { gql } from 'shared/api/__generated__';

export const NotificationSetIsReadAll = gql(/* GraphQL */ `
  mutation NotificationSetIsReadAll {
    notifications {
      setIsReadAll {
        nothing
      }
    }
  }
`);
