import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import error404image from 'shared/assets/images/error404image.svg';
import { GlobalRoutePaths } from 'shared/const/router';
import { Button } from 'shared/ui/buttons/Button';
import { Photo } from 'shared/ui/contents/Photo';
import { Typography } from 'shared/ui/contents/Typography';

import { ERROR_CODES, ErrorCodeMappedMessage } from '../const';
import cls from './NotFoundResourceWidget.module.scss';

interface NotFoundResourceWidgetProps {
  code?: ERROR_CODES;
  message?: string;
}

export const NotFoundResourceWidget = (props: NotFoundResourceWidgetProps) => {
  const { code = ERROR_CODES.NOT_FOUND, message } = props;

  const navigate = useNavigate();

  const handleOnClickMain = () => {
    navigate(GlobalRoutePaths.getRouteMain());
  };

  return (
    <Stack height='75vh' justifyContent='center' alignItems='center'>
      <Stack className={cls.Container}>
        {code === ERROR_CODES.NOT_FOUND && (
          <Photo size={336} src={error404image} />
        )}
        <Typography className={cls.Text} variant='h1'>
          {`Ошибка ${code}`}
        </Typography>
        <Typography className={cls.Text} variant='h3'>
          {message || ErrorCodeMappedMessage[code]}
        </Typography>
        <Stack className={cls.ButtonContainer}>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={handleOnClickMain}
          >
            На главную
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
