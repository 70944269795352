import { gql } from '../__generated__';

export const LOGOUT = gql(/* GraphQL */ `
  mutation LOGOUT($input: LogOutInput!) {
    auth {
      logOut(input: $input) {
        nothing
      }
    }
  }
`);
