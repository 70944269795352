import { gql } from 'shared/api/__generated__';

export const NotificationsTracking = gql(/* GraphQL */ `
  subscription NotificationsTracking {
    myNotifications {
      ... on NewNotifications {
        hasNew
        hasUnread
      }
      ... on Disconnect {
        disconnect
      }
      ... on TokenUpdateSubscriptionEvent {
        updateToken
      }
    }
  }
`);
