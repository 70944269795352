import { IlHome1, IlUser2 } from '@mc/react-icons/il';
import { IconType } from '@mc/react-icons/lib';
import { Stack } from '@mui/material';
import { CSSProperties } from 'react';

import { IconWrapper } from '../../../helpers/IconWrapper';
import { Size } from '../types/ImageContainer';
import cls from './EmptyPhoto.module.scss';
import { PhotoPreviewStub } from './PhotoPreviewStub';

type EmptyPhotoProps = {
  style?: CSSProperties;
  height?: number;
  width?: number;
  onClick?: () => void;
  customIcon?: IconType;
  type?: 'organization' | 'user';
  uuid?: string;
  variant?: 'default' | 'primary';
  borderRadius?: Size;
};

/**
 * Контейнер для пустой фотки
 */
export const EmptyPhoto = (props: EmptyPhotoProps) => {
  const {
    style,
    height,
    width,
    onClick,
    customIcon,
    type = 'user',
    variant,
    uuid,
    borderRadius = 'var(--border-radius-photo)',
  } = props;

  const sizeStyle = height && width ? { height, width } : undefined;

  const _style = { ...sizeStyle, borderRadius, ...style };

  return (
    <Stack
      className={cls.EmptyPhotoContainer}
      style={_style}
      onClick={() => onClick?.()}
    >
      {variant === 'primary' ? (
        <PhotoPreviewStub iconType={type} uuid={uuid || ''} />
      ) : (
        <IconWrapper
          className={cls.EmptyPhotoIcon}
          color='inherit'
          Svg={customIcon || (type === 'organization' ? IlHome1 : IlUser2)}
        />
      )}
    </Stack>
  );
};
