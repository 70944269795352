import { useEffect, useMemo, useRef, useState } from 'react';

export const useObserverResizeBox = ({
  trigger,
}: {
  trigger?: React.ReactNode;
}) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const changeVisibility = useMemo(() => {
    return !!trigger;
  }, [trigger]);

  useEffect(() => {
    if (!ref.current) return undefined;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current?.clientHeight || 0);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      setHeight(0);
    };
  }, [ref, changeVisibility]);

  return { ref, height };
};
