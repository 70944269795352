import { captureException as SentryCaptureException } from '@sentry/react';

export const captureException = (
  error: Error,
  payload: {
    requestId: string;
  },
) => {
  if (!import.meta.env.__SENTRY_DSN__) return;

  try {
    // eslint-disable-next-line consistent-return
    return SentryCaptureException(error, (scope) => {
      scope.setTag('requestId', payload.requestId);
      return scope;
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Sentry captureException failed:', err);
  }
};
