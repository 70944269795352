import { TypedDocumentNode } from '@apollo/client';
import { useCallback, useState } from 'react';

import { useApolloListQuery } from '../useApolloListQuery';

export const useInfiniteList = <TData, TVariables, TItem>(
  /** GQL запрос */
  query: TypedDocumentNode<TData, TVariables>,
  /** Параметры запроса */
  variables: TVariables & {
    filters?: Record<string, unknown>;
    pagination: {
      limit: number;
      offset: number;
    };
  },
  /** Функция для преобразования результата запроса */
  transform: (data: TData) => {
    items: TItem[];
    total: number;
  },
  /** contextMessage нужен для отображения уведомления об ошибке */
  contextMessage: { title?: string; message: string },
  /** Флаг, который позволяет пропустить запрос если true */
  skip?: boolean,
) => {
  const { pagination: initialPagination } = variables;
  const [pagination, setPagination] = useState(initialPagination);
  const { title, message } = contextMessage;

  const { loading, data, error, isFullLoaded, refetch } = useApolloListQuery(
    query,
    {
      ...variables,
      pagination,
    },
    transform,
    { title, message },
    { skip },
  );

  const changeFilters = useCallback(() => {
    setPagination((prev) => ({
      ...prev,
      offset: 0,
    }));
  }, []);

  const loadMore = useCallback(() => {
    if (loading || isFullLoaded) return;
    setPagination((prev) => {
      const newPagination = { ...prev };
      newPagination.offset = data.items.length;
      return newPagination;
    });
  }, [loading, isFullLoaded, data.items.length]);

  return {
    loadMore,
    error,
    refetch,
    data,
    loading,
    changeFilters,
    variables,
    setPagination,
    isFullLoaded,
  };
};
