import { IlPauseCircle, IlPlayCircle } from '@mc/react-icons/il';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';

import {
  getMedCabinetNeedPause,
  medCabinetActions,
} from 'processes/medcabinet';

import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

export const NavbarNeedPauseControl = () => {
  const needPause = useSelector(getMedCabinetNeedPause);
  const dispatch = useAppDispatch();

  const activateNeedPause = () => {
    dispatch(medCabinetActions.setNeedPause(!needPause));
  };

  return (
    <Stack>
      <Button
        size='small'
        variant={needPause ? 'contained' : 'outlined'}
        color='primary'
        style={{ borderRadius: '36px', gap: '6px' }}
        onClick={activateNeedPause}
      >
        <Typography noWrap variant='b2'>
          {needPause ? 'Пауза после осмотра' : 'Выйти на паузу после осмотра'}
        </Typography>

        <IconWrapper Svg={needPause ? IlPlayCircle : IlPauseCircle} />
      </Button>
    </Stack>
  );
};
