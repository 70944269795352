import { gql } from 'shared/api/__generated__';

export const NotificationGetList = gql(/* GraphQL */ `
  query NotificationGetList(
    $filters: NotificationsFilters!
    $pagination: Pagination!
  ) {
    notifications {
      getMy(filters: $filters, pagination: $pagination) {
        items {
          id
          timestamp
          header
          text
          isRead
        }
        total
      }
    }
  }
`);
