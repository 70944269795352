import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Client, createClient, MessageType } from 'graphql-ws';

import { TOKEN_EXPIRATION_MARGIN } from 'shared/const/auth';
import { getUniqueId } from 'shared/lib/external/nanoid';
import { dateNowWithServerOffset } from 'shared/lib/utils/dates';
import { logger } from 'shared/lib/utils/logger';
import { parseAccessToken } from 'shared/lib/utils/tokens';
import { IAccessToken } from 'shared/lib/utils/tokens/tokens.types';

import { getAccessTokenPromise } from '../helpers/authToken';
import { sendNewTokenInSocket } from '../helpers/sendNewTokenInSocket';

const logWs = logger('WS');

const getTtl = (expInSec: number) =>
  expInSec * 1000 -
  TOKEN_EXPIRATION_MARGIN.millis() -
  dateNowWithServerOffset().getTime();

const updateWsToken = async (client: Client) => {
  let accessToken: string | null = null;

  try {
    accessToken = await getAccessTokenPromise();
    if (accessToken) {
      await sendNewTokenInSocket({ client, token: accessToken }).next();
    } else throw Error('Вернулся пустой токен');
  } catch (err) {
    console.error('Не удалось обновить токен, ', err);
    throw err;
  }

  return accessToken;
};

/**
 * Link terminating для websocket подключений
 */
export const subscriptionLink = new GraphQLWsLink(
  (() => {
    let timerId: NodeJS.Timeout | null = null;
    let token: IAccessToken | null = null;

    const client = createClient({
      url: `${import.meta.env.__API_GQLGW_WS_URL__}`,
      lazy: true,
      // connectionAckWaitTimeout: 10000,
      // lazyCloseTimeout: 10000,
      connectionParams: async () => {
        const tokenAsString = await getAccessTokenPromise();
        token = parseAccessToken(tokenAsString);

        return {
          headers: {
            'x-requestid': getUniqueId(),
          },
          // Считаем, что к моменту активации токен уже
          // был провалидирован и обновлён
          authorization: `Bearer ${tokenAsString}`,
        };
      },
    });

    const updateTokenLoop = async () => {
      const accessToken = await updateWsToken(client);
      const parsed = parseAccessToken(accessToken);

      if (parsed) {
        token = parsed;
        const ttl = getTtl(token.exp);
        timerId = setTimeout(updateTokenLoop, ttl);
      }
    };

    const stopUpdateTokenLoop = () => {
      if (timerId) clearTimeout(timerId);
    };

    client.on('connecting', () => {
      logWs('connecting');
    });

    client.on('connected', () => {
      logWs('connected');
      if (token) {
        if (timerId) clearTimeout(timerId);
        const ttl = getTtl(token.exp);
        timerId = setTimeout(updateTokenLoop, ttl);
      }
    });

    client.on('opened', () => {
      logWs('opened');
    });

    client.on('closed', () => {
      logWs('closed');
      stopUpdateTokenLoop();
    });

    client.on('error', (err) => {
      logWs('error', err);
      stopUpdateTokenLoop();
    });

    client.on('message', async (msg) => {
      if (msg.type === MessageType.Next) {
        try {
          const payloadString = JSON.stringify(msg.payload.data);

          // Проверяем, что в сообщении запрос на обновление токена
          if (
            payloadString &&
            payloadString.includes('TokenUpdateSubscriptionEvent')
          ) {
            updateTokenLoop();
          }
        } catch (err) {
          console.error('Ошибка при обработке сообщения: ', err);
        }
      }
    });

    return client;
  })(),
);
