import { BillingProfileOrigin } from 'shared/api/__generated__';

export const BillingProfileOriginMap = {
  [BillingProfileOrigin.INHERITED]: {
    text: 'Унаследован',
  },
  [BillingProfileOrigin.SELF]: {
    text: 'Платит за себя',
  },
  [BillingProfileOrigin.SET_DIRECTLY]: {
    text: 'Настроен напрямую',
  },
  [BillingProfileOrigin.NOT_SET]: {
    text: 'Не настроен',
  },
};
