import { setContext } from '@apollo/client/link/context';

import { getAccessTokenPromise } from '../helpers/authToken';
import { checkNeedAuth } from '../helpers/checkNeedAuth';

/**
 * Добавляет токен авторизации в заголовки запроса
 */
export const authLink = setContext(async ({ operationName }, ctx) => {
  const needAuth = checkNeedAuth(operationName);

  if (!needAuth) return ctx;

  // Это точка останова запроса.
  // Здесь функция вернёт:
  // - string - токен, если он есть и не истёк;
  // - или Promise, в котором по циклу проверяется наличие access токена.
  const accessToken = await getAccessTokenPromise();

  if (!accessToken) {
    throw new Error('authLink: Не удалось получить access токен');
  }

  return {
    ...ctx,
    headers: {
      ...ctx.headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});
