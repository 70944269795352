import { gql } from 'shared/api/__generated__';

export const HOSTS_FAKER_SEARCH = gql(/* GraphQL */ `
  query HOSTS_FAKER_SEARCH($filters: HostFilters!, $pagination: Pagination!) {
    hosts {
      getFilteredList(filters: $filters, pagination: $pagination) {
        items {
          id
          name
          configuration {
            hardwareId
          }
        }
        total
      }
    }
  }
`);
