import { gql } from '../__generated__';

export const USER_FORCE_UNBAN = gql(/* GraphQL */ `
  mutation USER_FORCE_UNBAN($input: ForceUnbanInput!) {
    bans {
      forceUnban(input: $input) {
        id
      }
    }
  }
`);
