// eslint-disable-next-line fsd-import/layer-imports
import { useServerInfoStore } from 'entities/Server';

/**
 * Получить текущую дату с учетом дельты серверного времени.
 * NOTE: возвращает realtime время приближенное к серверному.
 */
export const dateNowWithServerOffset = () => {
  const clientOffset = useServerInfoStore.getState().clientOffset || 0;

  const dtn = Date.now();
  const returnDate = new Date(dtn - clientOffset);

  // console.log('dateNowWithServerOffset', {
  //   clientTime: new Date(dtn),
  //   clientOffset,
  //   returnDate,
  // });

  return returnDate;
};

/**
 * Получить дату с учетом дельты серверного времени.
 * NOTE: возвращает realtime время приближенное к серверному.
 */
export const dateWithoutServerOffset = (date: Date) => {
  const clientOffset = useServerInfoStore.getState().clientOffset || 0;

  return new Date(+date + clientOffset);
};
