import { useMutation } from '@apollo/client';
import { IlMoreHorizontal } from '@mc/react-icons/il';
import { Menu, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';
import { getUserId } from 'processes/user';

import { getRouteUserDetailsSettings } from 'shared/const/router/users/users.paths';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { IconButton } from 'shared/ui/buttons/IconButton';
import { Typography } from 'shared/ui/contents/Typography';
import { Switch } from 'shared/ui/form/checkboxes/Switch';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { NotificationSetIsReadAll } from '../../api/NotificationSetIsReadAll.gql';
import cls from './NotificationDrawerMenu.module.scss';

interface NotificationDrawerMenuProps {
  /** Фильтр по прочитанности уведомлений */
  isUnreadOnly: boolean;
  hasNotificationItemsList: boolean;
  onClose: () => void;
  onSwitcherChange: () => void;
  onRefetch: () => void;
}

export const NotificationDrawerMenu = (props: NotificationDrawerMenuProps) => {
  const {
    isUnreadOnly,
    hasNotificationItemsList,
    onClose,
    onSwitcherChange,
    onRefetch,
  } = props;
  const [dropdownAnchor, setDropDownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const notificationsActions = useNotifications();
  const errorHandlerContext = useErrorHandlerContext();

  const [setIsReadAll] = useMutation(NotificationSetIsReadAll, {
    context: errorHandlerContext({
      message: 'Не удалось отметить как прочитанное',
    }),
  });

  const currentUserId = useSelector(getUserId);

  const isDropdownOpen = Boolean(dropdownAnchor);

  const isDisabledSwitcher = !hasNotificationItemsList && !isUnreadOnly;

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropDownAnchor(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setDropDownAnchor(null);
  };

  const handleReadAll = async () => {
    try {
      const res = await setIsReadAll();

      if (res.errors) {
        console.error(res.errors);
        throw new Error('Не удалось отметить как прочитанное');
      }

      handleCloseDropdown();
      onRefetch();
    } catch (err) {
      console.error(err);

      notificationsActions.showErrorNotification({
        message: 'Не удалось отметить как прочитанное',
      });
    }
  };

  return (
    <Stack>
      <IconButton onClick={handleOpenDropdown}>
        <IconWrapper Svg={IlMoreHorizontal} />
      </IconButton>
      <Menu
        anchorEl={dropdownAnchor}
        open={isDropdownOpen}
        onClose={handleCloseDropdown}
        classes={{
          paper: cls.Menu,
        }}
      >
        <MenuItem onClick={handleReadAll} disabled={!hasNotificationItemsList}>
          <Typography variant='t2'>Отметить все прочитанным</Typography>
        </MenuItem>
        <MenuItem
          className={cls.MenuItemWithBorder}
          onClick={onSwitcherChange}
          disabled={isDisabledSwitcher}
        >
          <Stack className={cls.SwitchRow}>
            <Switch
              size='small'
              checked={isUnreadOnly}
              disabled={isDisabledSwitcher}
              onChange={onSwitcherChange}
            />
            <Typography variant='t2'>Только непрочитанные</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseDropdown();
            onClose();
          }}
        >
          <Link to={getRouteUserDetailsSettings(currentUserId)}>
            <Typography variant='t2'>Уведомления</Typography>
          </Link>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
