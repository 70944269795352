import { useCallback, useRef, useState } from 'react';

export const useSubscriptionManager = () => {
  const count = useRef(0);

  const [skip, setSkip] = useState(true);
  const [called, setCalled] = useState(false);

  const run = useCallback(
    async ({ force }: { force: boolean } = { force: false }) => {
      setCalled(true);
      setSkip(false);
      if (force) count.current = 0;
    },
    [],
  );

  const restart = useCallback(() => {
    setSkip(true);
    count.current += 1;

    if (count.current > 3) {
      console.error('количество попыток перезапуска подписки превысило 3');
      return;
    }
    setTimeout(() => run(), 1000 * (count.current + 1));
  }, [run]);

  return {
    skip,
    called,
    countReconnection: count.current,
    run: () => run({ force: true }),
    restart,
  };
};
