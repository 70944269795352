/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { MedCabinetState } from 'entities/MedCabinet';

import { MedCabinetSchema } from '../types/medCabinetSchema';

const initialState: MedCabinetSchema = {
  status: MedCabinetState.enter,
  needPause: false,
};

export const medCabinetSlice = createSlice({
  name: 'medCabinet',
  initialState,
  reducers: {
    setNeedPause(state, { payload }) {
      state.needPause = payload;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { actions: medCabinetActions } = medCabinetSlice;
export const { reducer: medCabinetReducer } = medCabinetSlice;
