import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { authSliceActions, getOwnerId } from 'processes/auth';
import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import { ClientType, LoginType } from 'shared/api/__generated__';
import { LoginMutation } from 'shared/api/__generated__/graphql';
import { LOGIN } from 'shared/api/authApi';
import { GlobalRoutePaths, LoginRoutePaths } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { loginFormat } from 'shared/lib/utils/loginFormat';
import { getBrowserStorage, setBrowserStorage } from 'shared/lib/utils/storage';

interface UseLoginProps {
  clientType: ClientType;
  activeLoginType: LoginType;
  loginVal: string;
  password: string;
  setIsNotExist: (e: boolean) => void;
  isReLogin?: boolean;
}

const useLoginSwitch = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useCallback(
    (logIn: LoginMutation['auth']['logIn'], props: UseLoginProps) => {
      const { isReLogin = false } = props;

      if (isReLogin && logIn.__typename !== 'TokenPair') {
        throw new Error(
          `Не удалось авторизоваться: тип не реализован ${logIn.__typename}`,
        );
      }

      switch (logIn.__typename) {
        case 'TokenPair': {
          const { access, refresh } = logIn;

          const tokens = {
            access: access.value,
            refresh: refresh.value,
          };

          setBrowserStorage('REFRESH_TOKEN_KEY', tokens.refresh);
          dispatch(authSliceActions.setTokens(tokens));

          if (isReLogin) {
            dispatch(authSliceActions.hideLogin());
          } else {
            dispatch(authSliceActions.setLoginValue(props.loginVal));
            setBrowserStorage('LAST_LOGIN_VALUE', props.loginVal);

            dispatch(authSliceActions.setLoginType(props.activeLoginType));
            setBrowserStorage('LAST_LOGIN_TYPE', props.activeLoginType);

            setBrowserStorage('ACCOUNT_TYPE', logIn.__typename);

            navigate(GlobalRoutePaths.getRouteMain());
          }

          break;
        }

        case 'NeedToConfirm': {
          const { type, processToken } = logIn;

          dispatch(authSliceActions.setLoginValue(props.loginVal));
          setBrowserStorage('LAST_LOGIN_VALUE', props.loginVal);

          dispatch(authSliceActions.setLoginType(props.activeLoginType));
          setBrowserStorage('LAST_LOGIN_TYPE', props.activeLoginType);

          dispatch(authSliceActions.setSecretType(type));
          dispatch(authSliceActions.setProcessToken(processToken));

          navigate(LoginRoutePaths.getRouteLoginActivateCode());
          break;
        }

        case 'NeedToChooseAccount': {
          setBrowserStorage('ACCOUNT_TYPE', logIn.__typename);

          dispatch(authSliceActions.setLoginValue(props.loginVal));
          setBrowserStorage('LAST_LOGIN_VALUE', props.loginVal);

          dispatch(authSliceActions.setLoginType(props.activeLoginType));
          setBrowserStorage('LAST_LOGIN_TYPE', props.activeLoginType);

          dispatch(authSliceActions.setProcessToken(logIn.processToken));
          dispatch(authSliceActions.setAccounts(logIn.options));

          navigate(LoginRoutePaths.getRouteLoginAccounts());
          break;
        }

        default: {
          throw new Error(
            `Не удалось авторизоваться: тип не реализован ${logIn.__typename}`,
          );
        }
      }
    },
    [dispatch, navigate],
  );
};

export const useLogin = () => {
  const notification = useNotifications();
  const loginSwitch = useLoginSwitch();

  const ownerId = useSelector(getOwnerId);
  const [loginHandler] = useMutation(LOGIN);

  return useCallback(
    async (props: UseLoginProps) => {
      const { clientType, activeLoginType, loginVal, password, setIsNotExist } =
        props;

      try {
        const { data, errors } = await loginHandler({
          variables: {
            input: {
              clientType,
              login: loginFormat(activeLoginType, loginVal),
              secret: `password::${password}`,
              orgUnitId: ownerId || getBrowserStorage('ORG_UNIT_ID'),
            },
          },
        });

        if (!data || errors) {
          if (errors?.some((el) => el.extensions?.code === 404)) {
            setIsNotExist(true);

            throw new Error('Ошибка авторизации: Неверный логин или пароль');
          } else {
            throw new Error(
              'Ошибка авторизации: Сервер временно недоступен, пожалуйста, попробуйте позже',
            );
          }
        }

        const { logIn } = data.auth;
        loginSwitch(logIn, props);
      } catch (err) {
        console.error(err);

        if (
          err instanceof Error &&
          err.message !== 'Ошибка авторизации: Неверный логин или пароль'
        ) {
          notification.showErrorNotification({
            title: 'Ошибка авторизации',
            message: 'Сервер временно недоступен, пожалуйста, попробуйте позже',
          });
        }
      }
    },
    [loginHandler, ownerId, loginSwitch, notification],
  );
};
