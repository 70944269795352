export enum Size {
  l = 'l',
  s = 's',
}

export type Variants = {
  typographyVariant: 'h1' | 'h3';
  className: string;
};

export type CountdownProps = {
  timeLeft: Nullable<number>;
  size: keyof typeof Size;
  onTick?: (timeLeft: number) => void;
  roundRotation?: boolean;
};
