export enum Color {
  GREEN = 'var(--color-green-200)',
  PINK = 'var(--color-pink-200)',
  YELLOW = 'var(--color-yellow-200)',
}

export enum Background {
  GREEN = 'var(--color-green-000)',
  PINK = 'var(--color-pink-000)',
  YELLOW = 'var(--color-yellow-000)',
}

export enum Title {
  LOAD_ERROR = 'Ошибка',
  LOADED = 'Загружен',
  PENDING = 'Запрошен',
}

export type colorChip = Color.GREEN | Color.PINK | Color.YELLOW;
export type backgroundChip =
  | Background.GREEN
  | Background.PINK
  | Background.YELLOW;

export interface ChipStatusData {
  color: colorChip;
  background: backgroundChip;
  title: Title.LOAD_ERROR | Title.LOADED | Title.PENDING;
}

export interface ChipStyle {
  minHeight: string;
  color: colorChip;
  background: backgroundChip;
  borderRadius: string;
  padding: string;
  cursor: string;
}
