import { HostFilters, Pagination } from 'shared/api/__generated__';

export type { HostFilters, Pagination } from 'shared/api/__generated__';
export { EnrollmentStatus } from 'shared/api/__generated__';
export interface IHostsFilterVariables {
  filters: HostFilters;
  pagination: Pagination;
}

export enum HostReportFilter {
  ALL = 'all',
  ERRORS = 'errors',
  LOGS = 'logs',
}
