import { Chip } from '@mui/material';

import cls from './ActionErrorChip.module.scss';

interface ActionErrorChipProps {
  label?: string;
}

export const ActionErrorChip = (props: ActionErrorChipProps) => {
  const { label = 'Упс! что-то пошло не так! Попробуйте снова немного позже' } =
    props;

  return <Chip label={label} variant='filled' className={cls.ErrorChip} />;
};
