import { Box, Stack } from '@mui/material';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getIsLineActive } from 'processes/line';

import { getUniqueId } from 'shared/lib/external/nanoid';
import { classNames } from 'shared/lib/utils/classNames';

import { getNavbarItems } from '../../model/selectors/getNavbarItems';
import { NavbarControls } from '../NavbarControls/NavbarControls';
import { NavbarLogo } from '../NavbarLogo/NavbarLogo';
import { NavbarMenuItem } from '../NavbarMenuItem/NavbarMenuItem';
import cls from './Navbar.module.scss';

interface NavbarLineProps {
  className?: string;
}

export const Navbar = React.memo((props: NavbarLineProps) => {
  const { className } = props;

  const navbarItems = useSelector(getNavbarItems).map((el) => {
    const key = getUniqueId();
    return { _key: key, ...el };
  });
  const isLineActive = useSelector(getIsLineActive);
  return (
    <Box className={classNames(cls.Navbar, {}, [className])}>
      <NavbarLogo />

      <Box className={classNames(cls.Menu)}>
        {!isLineActive && (
          <Stack role='navigation' className={classNames(cls.MenuContainer)}>
            {navbarItems.map((elem) => (
              <Fragment key={elem._key}>
                {'path' in elem ? (
                  <NavbarMenuItem key={elem.path} item={elem} />
                ) : (
                  elem
                )}
              </Fragment>
            ))}
          </Stack>
        )}
      </Box>

      <NavbarControls />
    </Box>
  );
});

Navbar.displayName = 'Navbar';
