/**
 * Удаление поля из объекта и всех вложенных объектов.
 * @param obj - объект, из которого нужно удалить поле
 * @param fieldName - имя поля, которое нужно удалить
 * @param cb - функция, которая будет вызвана при обнаружении поля
 */
export function removeNestedFields<T>(
  obj: T,
  fieldName: number | string,
  cb?: () => void,
): T {
  // Если это не объект, то возвращаем его (кроме массивов) и дат
  if (
    typeof obj !== 'object' ||
    obj === null ||
    obj instanceof Date ||
    obj instanceof File ||
    obj instanceof Blob ||
    obj instanceof FormData
  )
    return obj;

  // Если это массив, то применяем функцию к каждому элементу массива
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      removeNestedFields(item, fieldName, cb),
    ) as unknown as T;
  }

  const newObj: Partial<T> = { ...obj };

  // eslint-disable-next-line no-restricted-syntax
  for (const key in newObj) {
    // Проверяем наличие ключа в объекте (для guard-for-in)
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      // Если ключ совпадает с именем поля, то удаляем его
      if (key === fieldName) {
        // eslint-disable-next-line no-param-reassign
        delete newObj[key];
        // ... и вызываем callback для оповещения о совпадении
        if (cb) cb();
        // eslint-disable-next-line no-continue
        continue;
      }

      // Если значение ключа - объект, то применяем функцию к нему
      if (
        typeof newObj[key] === 'object' &&
        newObj[key] !== null &&
        !(newObj[key] instanceof Date)
      ) {
        newObj[key] = removeNestedFields(newObj[key], fieldName, cb);
      }
    }
  }

  return newObj as T;
}
