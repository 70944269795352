import { gql } from '../__generated__';

export const USER_UNBAN = gql(/* GraphQL */ `
  mutation USER_UNBAN($input: UnbanInput!) {
    bans {
      unban(input: $input) {
        id
      }
    }
  }
`);
