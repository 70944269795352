import { unreachable } from '@rsdk/common';

type FormatType = 'long' | 'short';

/**
 * Функция по форматированию ФИО
 */
export const nameFormat = (
  name: string,
  surname: string,
  patronymic?: Maybe<string>,
  format: FormatType = 'long',
): string => {
  let result = '';

  switch (format) {
    case 'short': {
      // Формат: Фамилия И.О.
      const nameInitial = name.charAt(0);
      const patronymicInitial = patronymic?.charAt(0);

      result = `${surname} ${nameInitial}.${
        patronymicInitial ? `${patronymicInitial}.` : ''
      }`;
      break;
    }
    case 'long': {
      // Формат: Фамилия Имя Отчество
      result = `${surname} ${name} ${patronymic || ''}`;
      break;
    }
    default:
      unreachable(format);
  }

  return result.trim();
};
