import { GraphQLFormattedError } from 'graphql';

// !!! Временное решение, пока нет расшифровки кодов ошибок с сервера

/**
 * @description Обрезает ошибку, которая содержат нечитаемую инфу после '>'
 */
export const splitGraphQLError = (error: GraphQLFormattedError): string => {
  return error.message?.split('>')[0] || '';
};

/**
 * @description Обрезает ошибки, которые содержат нечитаемую инфу после '>' и конкатенирует их
 */
export const splitGraphQLErrors = (
  errors: readonly GraphQLFormattedError[],
  concat: string = '; ',
): string => {
  return errors.map(splitGraphQLError).join(concat);
};
