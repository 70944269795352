import { gql } from '../__generated__';

export const SIGNING_SIGN_DOCUMENT = gql(/* GraphQL */ `
  mutation SIGNING_SIGN_DOCUMENT($input: SignInput!) {
    signing {
      core {
        sign(input: $input) {
          id
          certificate {
            id
          }
          timestamp
          url
          signatureUrl
        }
      }
    }
  }
`);
