import { HostStatus } from '../../__generated__';

export const HostMappedStatus: Record<HostStatus, string> = {
  [HostStatus.PENDING]: 'Ожидает сопряжения',
  [HostStatus.ENROLLED]: 'Подключен',
  [HostStatus.ENROLLMENT_CANCELED]: 'Подключение отменено пользователем',
  [HostStatus.ENROLLMENT_TIMEOUT]: 'Подключение не состоялось из-за таймаута',
  [HostStatus.UNENROLLED]: 'Отключен',
  [HostStatus.DISABLED_DIRECTLY]: 'Деактивирован',
  [HostStatus.DISABLED_INDIRECTLY]: 'Деактивирован (наследовано)',
  [HostStatus.DELETED]: 'Удалён',
};
