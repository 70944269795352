import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';

import cls from './PageError.module.scss';

interface PageErrorProps {
  children?: React.ReactNode;
}

const reloadPage = () => {
  window.location.reload();
};

export const PageError = (props: PageErrorProps) => {
  const { children } = props;

  const { t } = useTranslation();

  return (
    <Stack className={cls.PageError}>
      <Typography className={cls.Text} variant='h2'>
        {t('There is no information available')}
      </Typography>

      {children && (
        <Typography className={cls.Text} variant='body1'>
          {children}
        </Typography>
      )}

      <Button
        variant='contained'
        size='large'
        color='primary'
        onClick={reloadPage}
      >
        {t('Reload page')}
      </Button>
    </Stack>
  );
};
