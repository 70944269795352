import { EmployeeImportPage } from 'pages/EmployeeImport';

import {
  EmployeeImportRouteNames,
  EmployeeImportRoutePaths,
} from 'shared/const/router';

import { TRouteProps } from './types';

export const EmployeeImportRoutes: Record<
  EmployeeImportRouteNames,
  TRouteProps
> = {
  [EmployeeImportRouteNames.EMPLOYEE_IMPORT]: {
    path: EmployeeImportRoutePaths.getEmployeeImport(),
    element: <EmployeeImportPage />,
    isAuth: true,
    needRules: ['accounts.method.import'],
  },
};
