import { plural } from '../../external/plural';

// TODO: когда появится поддержка других языков
// /** Список поддерживаемых plural языков */
// type pluralLang = 'ru';

/**
 * Определение склонений для слов в зависимости от числа.
 * @description
 * Отличие от обычного plural:
 * - передаётся массив, а не последовательность - это позволит подключить другие языки.
 * - передаётся массив из трёх слов:
 *   - для русского языка: первое - ед. число, второе - множественное,
 *     третье - для чисел, кратных пяти.
 */
export const applyPlural = (
  count: number,
  words: string[],
  // TODO: когда появится поддержка других языков
  // /** Язык склонения */
  // lang: pluralLang = 'ru',
) => {
  const [one, two, five] = words;
  return plural(count, one, two, five);

  // TODO: когда появится поддержка других языков
  // switch (lang) {
  //   case 'ru': {
  //     const [one, two, five] = words;
  //     return plural(count, one, two, five);
  //   }
  //   default: {
  //     const [one, two, five] = words;
  //     return plural(count, one, two, five);
  //   }
  // }
};
