import { useEffect, useRef } from 'react';

interface UseIntersectionParams {
  threshold?: number;
  rootMargin?: string;
  container?: Nullable<HTMLElement>;
}

export const useIntersection = (
  callback: () => void,
  {
    threshold = 0,
    rootMargin = '0px',
    container = null,
  }: UseIntersectionParams = {},
) => {
  const triggerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) callback();
      },
      { rootMargin, threshold, root: container },
    );

    if (triggerRef.current) {
      observer.observe(triggerRef.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [triggerRef, callback, threshold, rootMargin, container]);

  return { triggerRef };
};
