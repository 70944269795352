/**
 * Тип аккаунта. Пользователь получает тип при создании и не может
 * изменить его в дальнейшем. Роли ему будут доступны из тех, что
 * относятся к этому типу (если нужно будет - это можно будет потом
 * изменить)
 */
export enum AccountType {
  /** Администратор. Основная функция - настройка системы */
  ADMIN = 'ADMIN',
  /**
   * Базовый пользователь. Такие пользователи могут иметь доступ
   * лишь к минимальному работу функций: прохождение осмотров и ЛК
   */
  BASIC = 'BASIC',
  /** Боты - автоматизированные учётки */
  BOT = 'BOT',
  /** Внутренние и клиентские менеджеры. Основная функция - контролирующая */
  MANAGER = 'MANAGER',
  /** Медицинский работник. Основная функция - обработка осмотров */
  MEDIC = 'MEDIC',
  /**
   * Пользователь, всегда обладающий всеми правами
   * и доступом ко всем объектам
   */
  ROOT = 'ROOT',
  /** Система MC2 */
  SYSTEM = 'SYSTEM'
}

export enum AgreementType {
  /** Пользовательское соглашение */
  EULA = 'EULA',
  /** Согласие на обработку персональных данных */
  PD_CONSENT = 'PD_CONSENT'
}

/** Алгоритм расчёта чек-суммы файлов прошивки */
export enum Algorithm {
  /** CRC32 */
  CRC32 = 'CRC32'
}

/** Тип ответа */
export enum AnswerType {
  /** Да или нет */
  BOOLEAN = 'BOOLEAN',
  /** Целое число */
  INT = 'INT',
  /** Мульти-выбор из списка */
  OPTION_MULTI = 'OPTION_MULTI',
  /** Выбор из списка */
  OPTION_SINGLE = 'OPTION_SINGLE',
  /** Текст */
  TEXT = 'TEXT'
}

export enum AssignedOrTaken {
  ASSIGNED = 'ASSIGNED',
  TAKEN = 'TAKEN'
}

export enum AuthType {
  /** Глобальная аутентификация */
  GLOBAL = 'GLOBAL',
  /** Локальная аутентификация */
  LOCAL = 'LOCAL'
}

/** Причина блокировки работника */
export enum BanReason {
  /** Жалобы */
  COMPLAINTS = 'COMPLAINTS',
  /** Травма */
  INJURY = 'INJURY',
  /** Признаки болезни */
  SIGNS_OF_ILLNESS = 'SIGNS_OF_ILLNESS',
  /** Признаки интоксикации */
  SIGNS_OF_INTOXICATION = 'SIGNS_OF_INTOXICATION'
}

/** Статус блокировки работника */
export enum BanStatus {
  /** Действует */
  ACTIVE = 'ACTIVE',
  /** Снята вручную */
  DISCARDED = 'DISCARDED',
  /** Истёк срок действия */
  OUTDATED = 'OUTDATED'
}

/** Тип баннера */
export enum BannerType {
  /**
   * Баннер, который частично блокирует функции ПАК,
   * например, пользователь может войти в свой ЛК, но не может пройти осмотр
   */
  ALERT = 'ALERT',
  /** Баннер, который полностью блокирует работу ПАК */
  BLOCK = 'BLOCK',
  /** Информационный баннер. Никак не блокирует работу ПАК */
  INFO = 'INFO'
}

/** Базовый статус */
export enum BaseStatus {
  /** Удалена */
  DELETED = 'DELETED',
  /** Деактивирована непосредственно */
  DISABLED_DIRECTLY = 'DISABLED_DIRECTLY',
  /** Деактивирована косвенно (через вышестоящую по иерархии сущность) */
  DISABLED_INDIRECTLY = 'DISABLED_INDIRECTLY',
  /** Активна */
  ENABLED = 'ENABLED'
}

export enum BillingProfileOrigin {
  /** Унаследован от OU выше по дереву */
  INHERITED = 'INHERITED',
  /** Не настроен */
  NOT_SET = 'NOT_SET',
  /** Сам является контрагентом и платит сам за себя */
  SELF = 'SELF',
  /** Явная привязка к контрагенту */
  SET_DIRECTLY = 'SET_DIRECTLY'
}

/**
 * По идее, есть ещё тип клиенты APP (интеграция, приложение) но
 * на контекст активации это никак не повлияет, т. к. у этого
 * типа клиентов, как и ботов не будет паролей
 */
export enum ClientType {
  /** Персональный ПАК */
  PERSONAL_HOST = 'PERSONAL_HOST',
  /** Не персональный ПАК (BUSINESS или PUBLIC) */
  SHARED_HOST = 'SHARED_HOST',
  /** Веб-интерфейс */
  WEB = 'WEB'
}

/** Источни единого времени осмотра */
export enum CommonTimeOrigin {
  /** Время ошибки препроцессинга */
  FAILURE = 'FAILURE',
  /** Время принятия решения */
  RESOLUTION = 'RESOLUTION',
  /** Время подписи */
  SIGNATURE = 'SIGNATURE'
}

/** Нарушение технических условий */
export enum ConditionViolation {
  /** Ошибка оборудования */
  DEVICE_ERROR = 'DEVICE_ERROR',
  /** Целостность корпуса ПАК нарушена */
  HULL_INTEGRITY_COMPROMISED = 'HULL_INTEGRITY_COMPROMISED',
  /** Влажность вне зоны допустимых границ */
  HUMIDITY_OUT_OF_RANGE = 'HUMIDITY_OUT_OF_RANGE',
  /** Освещённость вне зоны допустимых границ */
  ILLUMINATION_OUT_OF_RANGE = 'ILLUMINATION_OUT_OF_RANGE',
  /** Осмотр был отменён вручную */
  MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
  /** Целостность ПО нарушена */
  SOFTWARE_INTEGRITY_COMPROMISED = 'SOFTWARE_INTEGRITY_COMPROMISED',
  /** Температура окружающей среды вне зоны допустимых границ */
  TEMPERATURE_OUT_OF_RANGE = 'TEMPERATURE_OUT_OF_RANGE',
  /** Сбой синхронизации времени */
  TIME_OUT_OF_SYNC = 'TIME_OUT_OF_SYNC',
  /** Завершён пользователем */
  USER_CANCELLED = 'USER_CANCELLED',
  /** Осмотр незавершён из-за таймаута */
  USER_TIMEOUTED = 'USER_TIMEOUTED',
  /** Видео не успело загрузиться в отведённое для этого время */
  VIDEO_TIMEOUT = 'VIDEO_TIMEOUT'
}

/** Способ подключения устройства */
export enum ConnectionType {
  /** Беспроводное подключение по Bluetooth */
  BLUETOOTH = 'BLUETOOTH',
  /** Подключение через периферийный хаб */
  HUB = 'HUB',
  /** Подключение по USB */
  USB = 'USB'
}

export enum ContentType {
  /** Картинка */
  IMAGE = 'IMAGE',
  /** Форматированный текст */
  RICH_TEXT = 'RICH_TEXT',
  /** Просто текст */
  TEXT = 'TEXT'
}

/** Род замечания к осмотру (медицинское, административное и т. д.) */
export enum CumulativeResolution {
  /** Административное замечание */
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  /** Алкоголь */
  ALCOHOL = 'ALCOHOL',
  /** Мошенничество */
  FRAUD = 'FRAUD',
  /** Невозможно идентифицировать Работника */
  IDENTIFICATION = 'IDENTIFICATION',
  /** Медицинское замечание */
  MEDICAL = 'MEDICAL',
  /** Успех */
  SUCCESS = 'SUCCESS',
  /** Техническое замечание */
  TECHNICAL = 'TECHNICAL'
}

/** Тип устройства */
export enum DeviceType {
  /** Внешняя камера */
  CAMERA = 'CAMERA',
  /** Периферийный хаб */
  HUB = 'HUB',
  /** Многофункциональный измеритель MedUnit */
  MED_UNIT = 'MED_UNIT',
  /** Принтер */
  PRINTER = 'PRINTER',
  /** Считыватель RFID/NFC */
  READER = 'READER'
}

/**
 * Статус ПАК
 * Схема переходов - тут:
 * https://drive.google.com/file/d/11ZHti0zMXch7Qzoi86LOUhKZgOU4V32c/view?usp=sharing
 */
export enum EnrollmentStatus {
  /** Подключен */
  ENROLLED = 'ENROLLED',
  /** Подлючение отменено пользователем */
  ENROLLMENT_CANCELED = 'ENROLLMENT_CANCELED',
  /** Подключение не состоялось из-за таймаута */
  ENROLLMENT_TIMEOUT = 'ENROLLMENT_TIMEOUT',
  /** Ожидает подключения */
  PENDING = 'PENDING',
  /** Отключен */
  UNENROLLED = 'UNENROLLED'
}

/** Тип события */
export enum EventType {
  /** Начальное событие */
  INIT = 'INIT',
  /** Конечное событие */
  TERMINATED = 'TERMINATED',
  /** Обновленное событие */
  UPDATED = 'UPDATED'
}

/** Статус осмотра, который находится в работе */
export enum ExamInProgressStatus {
  /** Осмотр в обработке */
  IN_PROCESSING = 'IN_PROCESSING',
  /** Осмотр ещё проходится на ПАК(начат) */
  ON_MOBILE = 'ON_MOBILE',
  /** Осмотр ожидает подписания */
  WAITING_FOR_SIGNING = 'WAITING_FOR_SIGNING',
  /** Осмотр ожидает видео */
  WAITING_FOR_VIDEO = 'WAITING_FOR_VIDEO'
}

/**
 * Род осмотра. На этом признаке завязана вся бизнес-логика вокруг осмотра.
 * По сути есть только 3 рода: PRETEST, POSTTEST и LINE, внутри
 * которых могут быть различные типы. Например PRETEST может быть двух типов
 * PRESHIFT (предрейсовый) и PRETRIP (предсменный), которые по сути отличаются
 * только текстовками
 */
export enum ExamKind {
  /** В течение рабочего дня (во время смены) */
  LINE = 'LINE',
  /** После завершения работы */
  POSTTEST = 'POSTTEST',
  /** Перед выходом на работу (предварительный) */
  PRETEST = 'PRETEST'
}

/** Пол */
export enum Gender {
  /** GENDER_FEMALE - она */
  FEMALE = 'FEMALE',
  /** GENDER_MALE - он */
  MALE = 'MALE'
}

/** Вид действия, совершаемого на OU при модификации гостевых связей гостевых */
export enum GuestLinkAction {
  /** Добавление */
  ADD = 'ADD',
  /** Удаление */
  REMOVE = 'REMOVE'
}

/** Ключи событий с ПАК */
export enum HostEventKey {
  /** Указание жалоб */
  COMPLAINTS = 'COMPLAINTS',
  /** Подписание ИДС */
  CONSENT = 'CONSENT',
  /** Завершение осмотра */
  FINISHED = 'FINISHED',
  /** Измерение уровня алкоголя */
  MEASUREMENT_ALCOMETRY = 'MEASUREMENT_ALCOMETRY',
  /** Измерение температуры тела */
  MEASUREMENT_THERMOMETRY = 'MEASUREMENT_THERMOMETRY',
  /** Измерение давления и пульса */
  MEASUREMENT_TONOMETRY = 'MEASUREMENT_TONOMETRY',
  /** Начало осмотра */
  STARTED = 'STARTED'
}

/** Статус лог-файла или crash-report'a */
export enum HostReportStatus {
  /** Загружен */
  LOADED = 'LOADED',
  /** Не удалось загрузить */
  LOAD_ERROR = 'LOAD_ERROR',
  /** Ожидает загрузки с ПАК */
  PENDING = 'PENDING'
}

/** Тип файла */
export enum HostReportType {
  /** Crash-report */
  CRASH_REPORT = 'CRASH_REPORT',
  /** Лог */
  LOG = 'LOG'
}

/** Вычисляется из BaseStatus и EnrollmentStatus */
export enum HostStatus {
  /** Удалён */
  DELETED = 'DELETED',
  /** Деактивирован непосредственно */
  DISABLED_DIRECTLY = 'DISABLED_DIRECTLY',
  /** Деактивирован косвенно (через вышестоящую по иерархии сущность) */
  DISABLED_INDIRECTLY = 'DISABLED_INDIRECTLY',
  /** Подключен */
  ENROLLED = 'ENROLLED',
  /** Подлючение отменено пользователем */
  ENROLLMENT_CANCELED = 'ENROLLMENT_CANCELED',
  /** Подключение не состоялось из-за таймаута */
  ENROLLMENT_TIMEOUT = 'ENROLLMENT_TIMEOUT',
  /** Ожидает подключения */
  PENDING = 'PENDING',
  /** Отключен */
  UNENROLLED = 'UNENROLLED'
}

export enum HostType {
  /** MedControl Бизнес (ПАК общего пользования) */
  MC_BUSINESS = 'MC_BUSINESS',
  /** MedControl Персональный */
  MC_PERSONAL = 'MC_PERSONAL',
  /** MedControl СП (станция печати) */
  MC_PRINTING_STATION = 'MC_PRINTING_STATION'
}

/**
 * Статус документа. Поскольку документы бывают разного назначения,
 * у них могут быть разные статусы.
 */
export enum InboundDocumentStatus {
  /** Действует */
  ACTIVE = 'ACTIVE',
  /** Архивирован */
  ARCHIVED = 'ARCHIVED',
  /** Отклонён */
  DECLINED = 'DECLINED',
  /** Истёк срок действия */
  OUTDATED = 'OUTDATED',
  /** Ожидает подтверждения */
  PENDING = 'PENDING',
  /** Принят и использован */
  USED = 'USED'
}

/** Тип входящего документа */
export enum InboundDocumentType {
  /** Справка о ХТИ (химиотерапии) */
  CHEMOTHERAPY_CERTIFICATE = 'CHEMOTHERAPY_CERTIFICATE',
  /** Официальное обращение клиента */
  CLIENT_APPEAL = 'CLIENT_APPEAL',
  /** Заключение предварительного или периодического медицинского осмотра */
  CONCLUSION_OF_PRELIMINARY_OR_PERIODIC_MEDICAL_EXAM = 'CONCLUSION_OF_PRELIMINARY_OR_PERIODIC_MEDICAL_EXAM',
  /** Справка о состоянии здоровья */
  HEALTH_CERTIFICATE = 'HEALTH_CERTIFICATE',
  /** Справка о травме */
  INJURY_CERTIFICATE = 'INJURY_CERTIFICATE',
  /** Акт медицинского освидетельствования */
  ON_SITE_EXAM_PROTOCOL = 'ON_SITE_EXAM_PROTOCOL'
}

export enum InboxItemKind {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED'
}

/** Тип измеренного показателя */
export enum IndicatorType {
  /** Количество алкоголя в крови */
  ALCOHOL = 'ALCOHOL',
  /** Уровень кислорода в крови (%) */
  OXIGENATION = 'OXIGENATION',
  /** Диастолическое давление */
  PRESSURE_DIA = 'PRESSURE_DIA',
  /** Систолическое давление */
  PRESSURE_SYS = 'PRESSURE_SYS',
  /** ЧСС (пульс) */
  PULSE = 'PULSE',
  /** Температура тела (градус Цельсия) */
  TEMPERATURE = 'TEMPERATURE'
}

/** Расположение травмы */
export enum InjuryLocation {
  /** Слева */
  LEFT = 'LEFT',
  /** Слева и справа */
  LEFT_AND_RIGHT = 'LEFT_AND_RIGHT',
  /** Справа */
  RIGHT = 'RIGHT'
}

/** Тип травмы */
export enum InjuryType {
  /** Надбровной области */
  BROW = 'BROW',
  /** Грудной клетки неуточненная */
  CHEST = 'CHEST',
  /** Подбородочной области */
  CHIN_AREA = 'CHIN_AREA',
  /** Уха */
  EAR = 'EAR',
  /** Века */
  EYELID = 'EYELID',
  /** Лобной области */
  FRONTAL = 'FRONTAL',
  /** Волосистой области */
  HAIRY_AREA = 'HAIRY_AREA',
  /** Воспаление века неуточненное */
  INFLAMMATION_EYELID = 'INFLAMMATION_EYELID',
  /** Подглазничной области */
  INFRAORBITAL_REGION = 'INFRAORBITAL_REGION',
  /** Нижней конечности */
  LOWER_LIMB = 'LOWER_LIMB',
  /** Нижней губы */
  LOWER_LIP = 'LOWER_LIP',
  /** Множественные травмы головы */
  MULTIPLE_HEAD_INJURIES = 'MULTIPLE_HEAD_INJURIES',
  /** Шеи неуточненная */
  NECK = 'NECK',
  /** Спинки носа */
  NOSAL_DORSUM = 'NOSAL_DORSUM',
  /** Носа */
  NOSE = 'NOSE',
  /** Другой тип травмы */
  OTHER = 'OTHER',
  /** Параорбитальная гематома */
  PARAORBITAL_HEMATOMA = 'PARAORBITAL_HEMATOMA',
  /** Околоушно-жевательной области */
  PAROTID_MASTICATORY_REGION = 'PAROTID_MASTICATORY_REGION',
  /** Мягких тканей лица */
  SOFT_TISSUE = 'SOFT_TISSUE',
  /** Височной области */
  TEMPORAL_REGION = 'TEMPORAL_REGION',
  /** Верхней конечности */
  UPPER_LIMB = 'UPPER_LIMB',
  /** Верхней губы */
  UPPER_LIP = 'UPPER_LIP',
  /** Скуловой области */
  ZYGOMATIC_AREA = 'ZYGOMATIC_AREA'
}

/** Тип взаимодействия */
export enum InteractionKind {
  /** Подписание соглашения (оферта, индивидуальное добровольное согласие (ИДС)) */
  AGREEMENT = 'AGREEMENT',
  /** Прохождение медосмотра */
  EXAM = 'EXAM',
  /** Фотографирование */
  PHOTO = 'PHOTO',
  /** Прохождение опроса */
  SURVEY = 'SURVEY'
}

/** Тип идентификатора */
export enum LoginType {
  /** Водительское удостоверение */
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  /** Адрес электронной почты */
  EMAIL = 'EMAIL',
  /** Лицо */
  FACE = 'FACE',
  /** Мобильный телефон */
  PHONE = 'PHONE',
  /** Табельный номер */
  PN = 'PN',
  /** UUID СКУД-карты */
  RFID = 'RFID',
  /** СНИЛС */
  SNILS = 'SNILS'
}

/** Тип измерения */
export enum MeasurementType {
  /** Алкометрия */
  ALCOMETRY = 'ALCOMETRY',
  /** Пульс-оксиметрия */
  PULSEOXIMETRY = 'PULSEOXIMETRY',
  /** Термометрия */
  THERMOMETRY = 'THERMOMETRY',
  /** Тонометрия */
  TONOMETRY = 'TONOMETRY'
}

export enum MedcabLoadStatus {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW'
}

/** Тип значения метрики */
export enum MetricValueType {
  /** Булево */
  BOOLEAN = 'BOOLEAN',
  /** Число */
  NUMBER = 'NUMBER',
  /** Строка */
  STRING = 'STRING'
}

/** Каналы доставки уведомления */
export enum NotificationChannel {
  /** По email */
  EMAIL = 'EMAIL',
  /** По SMS */
  SMS = 'SMS',
  /** Уведомление в АРМ */
  WEB = 'WEB'
}

/** Объект события */
export enum ObjectType {
  /** Аккаунт */
  ACCOUNT = 'ACCOUNT',
  /** Хост */
  HOST = 'HOST',
  /** Орг юнит */
  ORG_UNIT = 'ORG_UNIT'
}

export enum OnlineExamActionType {
  LOOK_INTO_CAMERA = 'LOOK_INTO_CAMERA',
  TAKE_OFF_GLASSES = 'TAKE_OFF_GLASSES',
  TAKE_OFF_HAT = 'TAKE_OFF_HAT'
}

/** Тип OU */
export enum OrgUnitType {
  /** Юридическое лицо */
  COMPANY = 'COMPANY',
  /** Подразделение */
  DEPARTMENT = 'DEPARTMENT',
  /** Группа */
  GROUP = 'GROUP',
  /** ИП */
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR'
}

/** Тип исходящего документа */
export enum OutboundDocumentType {
  /** Выписка из мед. журнала */
  MEDJOURNAL_STATEMENT = 'MEDJOURNAL_STATEMENT',
  /** Направление к врачу */
  REFERRAL = 'REFERRAL',
  /** Направление на мед. освидетельствование */
  REFERRAL_FOR_MEDICAL_INSPECTION = 'REFERRAL_FOR_MEDICAL_INSPECTION',
  /** Протокол контроля трезвости */
  SOBRIETY_PROTOCOL = 'SOBRIETY_PROTOCOL'
}

/** Причина паузы */
export enum PauseReason {
  /** Просто пауза */
  PAUSE = 'PAUSE',
  /** Отмена назначенного осмотра */
  REJECT_EXAM = 'REJECT_EXAM',
  /** Проблемы с ЭЦП - отсутствует информация о сертификате */
  SIGNATURE_NOT_FOUND = 'SIGNATURE_NOT_FOUND'
}

/** Причина отклонения фото */
export enum PhotoDefect {
  /** Закрыты глаза */
  CLOSED_EYES = 'CLOSED_EYES',
  /** Обрезанное лицо */
  CROPPED_FACE = 'CROPPED_FACE',
  /** Наличие темных очков */
  DARK_GLASSES = 'DARK_GLASSES',
  /** Посторонние предметы в кадре */
  FOREIGN_OBJECTS = 'FOREIGN_OBJECTS',
  /** Наличие головного убора */
  HEADDRESS_IN_PHOTO = 'HEADDRESS_IN_PHOTO',
  /** Засветка */
  ILLUMINATION = 'ILLUMINATION',
  /** Низкая освещённость */
  LOW_LIGHT = 'LOW_LIGHT',
  /** Низкое разрешение */
  LOW_RESOLUTION = 'LOW_RESOLUTION',
  /** Посторонние в кадре */
  MANY_FACES = 'MANY_FACES',
  /** Неактуальное фото */
  NON_ACTUAL_PHOTO = 'NON_ACTUAL_PHOTO',
  /** Нет лица */
  NO_FACE = 'NO_FACE',
  /** Неравномерное освещение */
  UNEVEN_LIGHT = 'UNEVEN_LIGHT'
}

/** Источник фото */
export enum PhotoSource {
  /** Добавлена с ПАК */
  HOST = 'HOST',
  /** Добавлена из интерфейса */
  WEB = 'WEB'
}

/** Статус фото */
export enum PhotoStatus {
  /** В архиве/Отклонена */
  ARCHIVED = 'ARCHIVED',
  /** Не верифицирована */
  UNVERIFIED = 'UNVERIFIED',
  /** Верифицирована */
  VERIFIED = 'VERIFIED'
}

/** Ключи событий при постобработке осмотра */
export enum PostprocessingEventKey {
  /** Аккаунт заблокирован */
  ACCOUNT_BANNED = 'ACCOUNT_BANNED',
  /** Талон сгенерирован */
  RECEIPT_GENERATED = 'RECEIPT_GENERATED',
  /** Талон напечатан */
  RECEIPT_PRINTED = 'RECEIPT_PRINTED',
  /** Осмотр сохранён */
  SAVED = 'SAVED',
  /** Видео онлайн-осмотра загружено */
  VIDEO_UPLOADED = 'VIDEO_UPLOADED'
}

/** Ключи событий при пред обработке осмотра */
export enum PreprocessingEventKey {
  /** Аккаунт заблокирован */
  ACCOUNT_BANNED = 'ACCOUNT_BANNED',
  /** Аккаунт неактивен */
  ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
  /** Произошла ошибка оборудования */
  DEVICE_ERROR = 'DEVICE_ERROR',
  /** Ошибка проверки соответствия хоста */
  HOST_MISMATCH = 'HOST_MISMATCH',
  /** Один из показателей вышел за технические границы */
  INDICATORS_EXCEED_TECH_LIMITS = 'INDICATORS_EXCEED_TECH_LIMITS',
  /** Один из показателей вышел за границы измерений */
  INDICATORS_EXCEED_THRESHOLDS = 'INDICATORS_EXCEED_THRESHOLDS',
  /** Указан неверный тип осмотра */
  INVALID_EXAM_TYPE = 'INVALID_EXAM_TYPE',
  /** Указан неверный хост */
  INVALID_HOST = 'INVALID_HOST',
  /** Осмотр был отменён вручную */
  MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
  /** Ожидался шаг с измерениями */
  MEASUREMENT_EXPECTED = 'MEASUREMENT_EXPECTED',
  /** У работодателя отсутствует платёжный профиль */
  MISSING_BILLING_PROFILE = 'MISSING_BILLING_PROFILE',
  /** Отсутствуют доступные категории для прохождения осмотра */
  MISSING_CATEGORIES = 'MISSING_CATEGORIES',
  /** Для прохождения осмотра необходимо наличие фото профиля */
  NEED_ACCOUNT_PHOTO = 'NEED_ACCOUNT_PHOTO',
  /** Осмотр не содержит необходимых данных */
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
  /** Выполнены не все измерения */
  NOT_ENOUGH_INDICATORS = 'NOT_ENOUGH_INDICATORS',
  /** Осмотр не требует видео */
  NO_VIDEO = 'NO_VIDEO',
  /** Не найден работодатель работника */
  ORG_UNIT_NOT_FOUND = 'ORG_UNIT_NOT_FOUND',
  /** Осмотр конфликтует с ожидаемой схемой */
  SCHEMA_CONFLICT = 'SCHEMA_CONFLICT',
  /** Осмотр доставлен на сервер */
  STARTED = 'STARTED',
  /** Один из шагов отменён */
  STEP_CANCELLED = 'STEP_CANCELLED',
  /** Таймаут на одном из шагов */
  STEP_TIMEOUT = 'STEP_TIMEOUT',
  /** Осмотр приостановлен */
  SUSPENDED = 'SUSPENDED',
  /** Получены не все результаты шагов */
  UNFINISHED_EXAM = 'UNFINISHED_EXAM',
  /** Осмотр прошёл проверки */
  VALID = 'VALID',
  /** Видео не успело загрузиться в отведенное время */
  VIDEO_TIMEOUT = 'VIDEO_TIMEOUT',
  /** Осмотр получил видео */
  VIDEO_UPLOADED = 'VIDEO_UPLOADED',
  /** Осмотр завершён с нарушениями */
  VIOLATIONS = 'VIOLATIONS'
}

/** Источник задания на печать */
export enum PrintingTaskOrigin {
  /** Сгенерировано на основании осмотра */
  EXAM = 'EXAM',
  /** Повторная печать с ПАК */
  HOST = 'HOST',
  /** Повторная печать с web-интерфейса */
  WEB = 'WEB'
}

/** Ключи событий при обработке осмотра */
export enum ProcessingEventKey {
  /** Запрошено действие медиком */
  ACTION_REQUESTED = 'ACTION_REQUESTED',
  /** Осмотр назначен на медика */
  ASSIGNED_ON = 'ASSIGNED_ON',
  /** Медик не успел взять в работу */
  ASSIGN_TIMEOUT = 'ASSIGN_TIMEOUT',
  /** Осмотр обработан ассистентом */
  ASSISTANT_RESOLVED = 'ASSISTANT_RESOLVED',
  /** Осмотр закрыт по таймауту автоматически */
  CLOSED_BY_TIMEOUT = 'CLOSED_BY_TIMEOUT',
  /** Соглашение подписано */
  CONSENT_FILE_SIGNED = 'CONSENT_FILE_SIGNED',
  /** ИДС отправлен на подпись */
  CONSENT_FILE_SIGNING_REQUESTED = 'CONSENT_FILE_SIGNING_REQUESTED',
  /** Истекло время подписания файлов у медика, осмотр возвращён в очередь */
  FILE_SIGNING_TIMEOUT = 'FILE_SIGNING_TIMEOUT',
  /** Неявка пользователя на повторный осмотр */
  MISSING_RE_EXAMINATION = 'MISSING_RE_EXAMINATION',
  /** Осмотр приостановлен, назначен повторный осмотр */
  PAUSED = 'PAUSED',
  /** Осмотр обработан */
  PROCESSED = 'PROCESSED',
  /** Попытка(и) продлить осмотр сверх установленного лимита пролонгаций */
  PROLONGATION_LIMIT_EXCEED = 'PROLONGATION_LIMIT_EXCEED',
  /** Решение подписано */
  RESOLUTION_FILE_SIGNED = 'RESOLUTION_FILE_SIGNED',
  /** Решение отправлено на подпись */
  RESOLUTION_FILE_SIGNING_REQUESTED = 'RESOLUTION_FILE_SIGNING_REQUESTED',
  /** Истекло время принятия решения у медика, осмотр возвращён в очередь */
  RESOLUTION_TIMEOUT = 'RESOLUTION_TIMEOUT',
  /** Вынесено решение по осмотру медиком */
  RESOLVED = 'RESOLVED',
  /** Медик приостановил смену, осмотр возвращен в очередь */
  SHIFT_PAUSED = 'SHIFT_PAUSED',
  /** Истекло допустимое время обработки */
  SLA_TIMEOUT = 'SLA_TIMEOUT',
  /** Осмотр поступил в очередь */
  STARTED = 'STARTED',
  /** Осмотр был приостановлен из-за тех. сбоя на одной из сторон */
  STOPPED_BY_TECH_PROBLEM = 'STOPPED_BY_TECH_PROBLEM',
  /** Осмотр взят в работу медиком */
  TAKEN_BY_MEDIC = 'TAKEN_BY_MEDIC',
  /** Технический сбой мед. кабинета у медика. Осмотр возвращен в очередь */
  TECH_PROBLEM = 'TECH_PROBLEM',
  /** Осмотр был возвращён в работу после приостановки из-за тех. сбоя */
  TECH_PROBLEM_RESOLVED = 'TECH_PROBLEM_RESOLVED',
  /** Техническое расхождение временных меток у медика */
  TIMELINE_OUT_OF_BOUNDS = 'TIMELINE_OUT_OF_BOUNDS',
  /** Осмотр был завершён из-за тех. сбоя на одной из сторон */
  TIMEOUTED_BY_TECH_PROBLEM = 'TIMEOUTED_BY_TECH_PROBLEM',
  /** Пролонгировано время обработки медика */
  TIME_PROLONGED = 'TIME_PROLONGED'
}

/** Результат обработки осмотра */
export enum ProcessingResult {
  /** Нарушение тех. условий */
  CONDITIONS_VIOLATION = 'CONDITIONS_VIOLATION',
  /** Ошибка оборудования */
  DEVICE_ERROR = 'DEVICE_ERROR',
  /** Осмотр был отменён вручную */
  MANUALLY_CANCELLED = 'MANUALLY_CANCELLED',
  /** Успешно обработан */
  PROCESSED = 'PROCESSED',
  /** Таймаут обработки */
  PROCESSING_TIMEOUT = 'PROCESSING_TIMEOUT',
  /** Технический сбой */
  TECHNICAL_RESOLUTION = 'TECHNICAL_RESOLUTION',
  /** Ошибка, связанная с тех. сбоем при проведении осмотра */
  TECH_CANCELLED = 'TECH_CANCELLED',
  /** Осмотр не завершён из-за отмены */
  USER_CANCELLED = 'USER_CANCELLED',
  /** Осмотр не завершён из-за таймаута */
  USER_TIMEOUTED = 'USER_TIMEOUTED',
  /** Сбой видео */
  VIDEO_TIMEOUT = 'VIDEO_TIMEOUT'
}

/** Статус осмотра в обработка */
export enum ProcessingStatus {
  /** Назначен на медика */
  ASSIGNED = 'ASSIGNED',
  /** Ожидает решения по следующему осмотру в серии */
  SUSPENDED = 'SUSPENDED',
  /** Взят медиком в работу */
  TAKEN = 'TAKEN',
  /** Ожидает назначения на медика */
  WAITING_FOR_MEDIC = 'WAITING_FOR_MEDIC',
  /** Ожидает решения по серии осмотров */
  WAITING_FOR_SEQUENCE_RESOLUTION = 'WAITING_FOR_SEQUENCE_RESOLUTION',
  /** Ожидает подписи */
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE'
}

/** Ключи замечаний */
export enum RemarkKey {
  /** Обнаружен алкоголь */
  ALCOHOL = 'ALCOHOL',
  /** Алкотестирование вне зоны видимости */
  ALCOMETRY_OUT_OF_SIGHT = 'ALCOMETRY_OUT_OF_SIGHT',
  /** Нарушение правил алкотестирования */
  ALCOMETRY_RULES = 'ALCOMETRY_RULES',
  /** Другая проблема */
  ANOTHER_FAILURE = 'ANOTHER_FAILURE',
  /** Низкое качество фото */
  BAD_PHOTO = 'BAD_PHOTO',
  /** Некорректная видеофиксация */
  BAD_VIDEO = 'BAD_VIDEO',
  /** Не снят пластырь/повязка */
  BANDAGE = 'BANDAGE',
  /** Не снят головной убор/верхняя одежда */
  CLOTHES = 'CLOTHES',
  /** Наличие жалоб */
  COMPLAINTS = 'COMPLAINTS',
  /** Нахождение вне зоны видимости камеры */
  EMPLOYEE_OUT_OF_SIGHT = 'EMPLOYEE_OUT_OF_SIGHT',
  /** Не сняты очки */
  GLASSES = 'GLASSES',
  /** Травма */
  INJURY = 'INJURY',
  /** Недостаточное освещение */
  LOW_LIGHT = 'LOW_LIGHT',
  /** Не снята маска/перчатки */
  MASK_OR_GLOVES = 'MASK_OR_GLOVES',
  /** Не удалось загрузить фото */
  NO_PHOTO = 'NO_PHOTO',
  /** Не удалось загрузить видео */
  NO_VIDEO = 'NO_VIDEO',
  /** Засвет видео */
  OVERLIGHTING = 'OVERLIGHTING',
  /** АД выходит за границы нормы */
  PRESSURE = 'PRESSURE',
  /** ЧСС выходит за границы нормы */
  PULSE = 'PULSE',
  /** Нарушение порядка прохождения */
  REGULATIONS = 'REGULATIONS',
  /** Посторонние в кадре */
  STRANGERS = 'STRANGERS',
  /** Признаки особенности поведения */
  STRANGE_BEHAVIOR = 'STRANGE_BEHAVIOR',
  /** Температура выходит за границы нормы */
  TEMPERATURE = 'TEMPERATURE',
  /** Термометрия вне зоны видимости */
  TERMOMETRY_OUT_OF_SIGHT = 'TERMOMETRY_OUT_OF_SIGHT',
  /** Нарушение правил термометрии */
  TERMOMETRY_RULES = 'TERMOMETRY_RULES',
  /** Тонометрия вне зоны видимости */
  TONOMETRY_OUT_OF_SIGHT = 'TONOMETRY_OUT_OF_SIGHT',
  /** Нарушение правил тонометрии */
  TONOMETRY_RULES = 'TONOMETRY_RULES',
  /** Неустойчивая концентрация внимания */
  UNSTABLE_CONCENTRATION = 'UNSTABLE_CONCENTRATION',
  /** Препятствие видеозаписи */
  VANDALISM = 'VANDALISM',
  /** Прохождение осмотра посторонним */
  WRONG_PERSON = 'WRONG_PERSON'
}

/** Формат отчёта */
export enum ReportFormat {
  /** PDF */
  PDF = 'PDF',
  /** XLSX */
  XLSX = 'XLSX'
}

/** Статус выполнения заявки на получение отчёта */
export enum ReportPendingStatus {
  /** Отчёт формируется */
  GENERATING = 'GENERATING',
  /** Отчёт сформирован и находится на стадии подписания */
  SIGNING = 'SIGNING'
}

/** Статус выполнения заявки на получение отчёта */
export enum ReportStatus {
  /** Заявка выполнена */
  DONE = 'DONE',
  /** Выполнение заявки завершилось ошибкой */
  ERROR = 'ERROR',
  /** Отчёт формируется */
  GENERATING = 'GENERATING',
  /** Отчёт сформирован и находится на стадии подписания */
  SIGNING = 'SIGNING'
}

/** Тип секрета */
export enum SecretType {
  /**
   * API-ключ - по сути, тот же многоразовый пароль,
   * но выдаваемый для приложений (ботов)
   */
  API_KEY = 'API_KEY',
  /** Лицо */
  FACE = 'FACE',
  /** Одноразовый код (one time password) */
  OTP = 'OTP',
  /** Долговременный пароль, многоразовый пароль */
  PASSWORD = 'PASSWORD',
  /** Пин код */
  PIN = 'PIN',
  /** Идентификатор СКУД-карты */
  RFID = 'RFID',
  /** Идентификатор смарт-карты (карты тахографа) */
  SMARTCARD_ID = 'SMARTCARD_ID'
}

/** Статус сиквенса в обработке */
export enum SequenceStatus {
  /** Назначен на медика */
  ASSIGNED = 'ASSIGNED',
  /** Создан */
  CREATED = 'CREATED',
  /** Обработан */
  RESOLVED = 'RESOLVED',
  /** Взят в работу */
  TAKEN = 'TAKEN'
}

export enum ServicingProfileOrigin {
  /** Унаследован от OU выше по дереву */
  INHERITED = 'INHERITED',
  /** Не настроен (ни одной категории не привязано) */
  NOT_SET = 'NOT_SET',
  /** Сам является мед. оператором и обслуживает себя сам */
  SELF = 'SELF',
  /** Явная привязка к мед. оператору(ам) */
  SET_DIRECTLY = 'SET_DIRECTLY'
}

/** Состояние смены */
export enum ShiftStatus {
  /** Смена закрыта */
  FINISHED = 'FINISHED',
  /** На медика назначен осмотр */
  HAS_ASSIGNMENT = 'HAS_ASSIGNMENT',
  /** Смена открыта и готова к назначению осмотров */
  IDLE = 'IDLE',
  /** Смена открыта, но на паузе - осмотры не принимаем */
  PAUSED = 'PAUSED',
  /** Медик работает над осмотром */
  WORKING_ON_EXAM = 'WORKING_ON_EXAM',
  /** Медик работает над онлайн осмотром */
  WORKING_ON_ONLINE_EXAM = 'WORKING_ON_ONLINE_EXAM',
  /** Медик работает над серией осмотров */
  WORKING_ON_SEQUENCE = 'WORKING_ON_SEQUENCE'
}

/** Признак целостности ПО */
export enum SoftwareIntegrity {
  /** Чек-сумма прошивки указанной версии не совпадает с чек-суммой эталонной прошивки */
  CORRUPTED_FIRMWARE = 'CORRUPTED_FIRMWARE',
  /** Чек-сумма прошивки указанной версии совпадает с чек-суммой эталонной прошивки */
  SUCCESS = 'SUCCESS',
  /** Прошивка указанной версии не существует в списке эталонных прошивок */
  UNKNOWN_FIRMWARE = 'UNKNOWN_FIRMWARE'
}

/** Тип шага */
export enum StepKind {
  /**
   * Подтверждение ознакомления с документом
   * (не ИДС, не внутри шагов осмотра)
   */
  AGREEMENT = 'AGREEMENT',
  /** ИДС: Подтверждение согласия на мед. вмешательство */
  CONSENT = 'CONSENT',
  /** Вывод данных для ознакомпления */
  CONTENT = 'CONTENT',
  /** Измерение */
  MEASUREMENT = 'MEASUREMENT',
  /** Фотографирование */
  PHOTO = 'PHOTO',
  /** Вопрос */
  QUESTION = 'QUESTION'
}

/** Статус шага */
export enum StepStatus {
  /** Шаг не пройден */
  FAILED = 'FAILED',
  /** Не дошли до шага */
  NOT_REACHED = 'NOT_REACHED',
  /** Шаг пропущен */
  SKIPPED = 'SKIPPED',
  /** Шаг успешно пройден */
  SUCCEEDED = 'SUCCEEDED',
  /** Шаг отменён пользователем */
  USER_CANCELLED = 'USER_CANCELLED',
  /** На шаге истёк таймер (конкретного шага или общий) */
  USER_TIMEOUTED = 'USER_TIMEOUTED'
}

/** Тип секрета, который хранит сервис аутентификации */
export enum StoredSecretType {
  /** Долговременный пароль, многоразовый пароль */
  PASSWORD = 'PASSWORD',
  /** Идентификатор СКУД-карты */
  RFID = 'RFID',
  /** Идентификатор смарт-карты (карты тахографа) */
  SMARTCARD_ID = 'SMARTCARD_ID'
}

/** Возможные индикаторы выхода за границу допуска */
export enum ThresholdViolation {
  TOO_HIGH = 'TOO_HIGH',
  TOO_LOW = 'TOO_LOW'
}

/** Источник границ допуска */
export enum UnbanType {
  /** Блокировка снята автоматически */
  AUTO = 'AUTO',
  /** Блокировка снята вручную */
  MANUAL = 'MANUAL'
}

/** Результат поверки */
export enum VerificationStatus {
  /** ПАК поверен */
  OK = 'OK',
  /** Поверка устарела */
  OUTDATED = 'OUTDATED'
}

/** Типы осмотров, для которых может загружаться видео */
export enum VideoExamType {
  /** Обычный осмотр */
  OFFLINE = 'OFFLINE',
  /** Онлайн осмотр */
  ONLINE = 'ONLINE'
}
