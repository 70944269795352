import { Stack } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getLoginValue } from 'processes/auth';

import { ClientType, LoginType } from 'shared/api/__generated__';
import { LoginRoutePaths } from 'shared/const/router';
import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';
import { PasswordField } from 'shared/ui/form/inputs/PasswordField';

import { useLogin } from '../../../lib/hooks/useLogin';
import { LoginFormControl } from '../../../lib/types';
import { LoginTextFiled } from '../../../lib/utils/LoginTextFiled';
import { loginRules } from '../../../lib/validation';
import cls from './ReLoginForm.module.scss';

export const ReLoginForm = () => {
  const handleMouseEvents = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const userLogin = useSelector(getLoginValue);

  // const lastLoginType =
  //   (getLocalStorageValue(LAST_LOGIN_TYPE, () => {
  //       notificationsActions.showErrorNotification({
  //         message:
  //           'В вашем браузере отключена работа с LocalStorage, приложение может работать некорректно.',
  //       });
  //   }) as LoginType) || LoginType.PHONE;

  // const [activeLoginType, setActiveLoginType] = useState(lastLoginType);
  const [loading, setLoading] = useState(false);
  const [isNotExist, setIsNotExist] = useState(false);

  const { control, getValues, setValue, handleSubmit } =
    useForm<LoginFormControl>({
      defaultValues: {
        activeLoginType: LoginType.EMAIL,
        login: '',
        password: '',
      },
    });

  useEffect(() => {
    if (userLogin) setValue('login', userLogin || '');
  }, [userLogin, setValue]);

  const login = useLogin();

  const sendForm = useCallback(async () => {
    setLoading(true);
    try {
      await login({
        clientType: ClientType.WEB,
        activeLoginType: getValues('activeLoginType'),
        loginVal: getValues('login'),
        password: getValues('password'),
        setIsNotExist,
        isReLogin: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [getValues, login]);

  const verifyAndSubmit = useCallback(() => {
    handleSubmit(sendForm)();
  }, [handleSubmit, sendForm]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        verifyAndSubmit();
      }
    },
    [verifyAndSubmit],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Stack className={cls.Container}>
      <Stack gap='.5rem'>
        <Typography variant='h2' align='left'>
          Сессия истекла
        </Typography>
        <Typography variant='h4' align='left'>
          Время жизни сессии истекло. Для продолжения работы введите пароль.
        </Typography>
      </Stack>

      <Stack gap='1rem'>
        <LoginTextFiled
          activeLoginType={getValues('activeLoginType')}
          control={control}
          disabled
          showSx
        />

        <Stack gap='0.5rem'>
          <Controller
            name='password'
            control={control}
            rules={{ ...loginRules.password }}
            render={({ field, fieldState }) => (
              <PasswordField
                {...field}
                placeholder='Пароль'
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setIsNotExist(false);
                }}
                error={!!fieldState.error || isNotExist}
                helperText={
                  fieldState.error?.message ||
                  (isNotExist && 'Ошибка авторизации. Неверный пароль')
                }
                sx={{
                  '.MuiInputBase-input': {
                    height: '38px',
                  },
                }}
              />
            )}
          />
        </Stack>

        <Button
          type='submit'
          variant='contained'
          color='primary'
          size='extraLarge'
          loading={loading}
          onClick={verifyAndSubmit}
        >
          Войти
        </Button>
      </Stack>

      <Stack direction='row' justifyContent='space-between'>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            window.location.href = LoginRoutePaths.getRouteLogin();
          }}
          size='medium'
        >
          Выйти из системы
        </Button>
      </Stack>
    </Stack>
  );
};
