export const enum ERROR_CODES {
  NO_ACCESS = '403',
  NOT_FOUND = '404',
}

export const ErrorCodeMappedMessage: Record<ERROR_CODES, string> = {
  [ERROR_CODES.NOT_FOUND]: 'Такой страницы нет. Возможно, она в разработке.',
  [ERROR_CODES.NO_ACCESS]:
    'У вас нет доступа к данной странице. Обратитесь к вашему менеджеру или администратору.',
};
