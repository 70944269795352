import { gql } from '@apollo/client';

export const USER_ENABLE = gql`
  mutation USER_ENABLE($input: EnableAccountInput!) {
    accounts {
      enable(input: $input) {
        ... on Person {
          id
        }
      }
    }
  }
`;
