import { useState } from 'react';

import { FakerDrawer } from '../FakerDrawer/FakerDrawer';
import { FakerStickyButton } from '../FakerStickyButton/FakerStickyButton';

export const FakerWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FakerStickyButton onOpen={() => setIsOpen(true)} />
      <FakerDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
