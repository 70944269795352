import { AccountsFakerSearchQuery } from 'shared/api/__generated__/graphql';

export type AccountsFakerSearchRequest = AccountsFakerSearchQuery;
export type AccountsFakerSearchPage =
  AccountsFakerSearchRequest['accounts']['getFilteredList'];
export type AccountsFakerSearchItem =
  NonNullable<AccountsFakerSearchPage>['items'][0];
export type AccountsFakerSearchItemPerson = Extract<
  AccountsFakerSearchItem,
  { __typename: 'Person' }
>;

export const guardIsPerson = (
  item: AccountsFakerSearchItem,
): item is AccountsFakerSearchItemPerson => item.__typename === 'Person';
