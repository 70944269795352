import { useCallback, useEffect, useMemo, useState } from 'react';

import { AutocompleteValue } from '../../inputs/UniversalAutocomplete';
import {
  SearchSelectBase,
  SearchSelectBaseProps,
  SearchSelectOption,
} from '../SearchSelectBase/SearchSelectBase';

interface SearchSelectMultipleProps<TOption, TValue> {
  multiple: true;
  value: AutocompleteValue<TValue, true>;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, true>,
    options: TOption[],
  ) => void;
}
interface SearchSelectSingleProps<TOption, TValue> {
  multiple?: false;
  value: AutocompleteValue<TValue, false>;
  /** Обработчик изменения значения */
  onChange: (
    value: AutocompleteValue<TValue, false>,
    options: TOption[],
  ) => void;
}

export type SearchSelectProps<
  TOption,
  TValue,
  Multi extends boolean | undefined = undefined,
> = Omit<
  SearchSelectBaseProps<TOption, TValue, Multi>,
  | 'clearSearchOnClose'
  | 'enableFilterOptions'
  | 'multiple'
  | 'onChange'
  | 'onChangeSearch'
  | 'options'
  | 'search'
  | 'selectedOptions'
  | 'value'
> & {
  /** Есть ли ошибка валидации */
  validationError?: boolean;
  /** Локальная фильтрация элементов списка */
  localFilterOptions?: boolean;
  /** Ширина выпадающего списка */
  menuWidth?: number;
  /** Функция получения элементов списка по строке поиска */
  getOptions?: () => Promise<TOption[]>;
  options?: TOption[];
} & TernaryUnion<
    Multi,
    SearchSelectMultipleProps<TOption, TValue>,
    SearchSelectSingleProps<TOption, TValue>
  >;

export function SearchSelect<
  TOption,
  TValue,
  Multi extends boolean | undefined = undefined,
>(searchSelectProps: SearchSelectProps<TOption, TValue, Multi>) {
  const {
    getOptionValue = (option: TOption) =>
      (option as SearchSelectOption).id as TValue,
    getOptionLabel = (option: TOption) => (option as SearchSelectOption).label,
    options: optionsExternal,
    getOptions,
    multiple,
    menuWidth,
    value,
    onChange,
    localFilterOptions,
    ...restProps
  } = searchSelectProps;

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [optionsServer, setOptionsServer] = useState<TOption[]>([]);
  const fetchOptions = useCallback(async () => {
    if (!getOptions) return;

    setIsLoadingOptions(true);

    const options = await getOptions();

    setOptionsServer(options);
    setIsLoadingOptions(false);
  }, [getOptions]);

  useEffect(() => {
    if (getOptions) fetchOptions();
  }, [fetchOptions, getOptions]);

  const options = optionsExternal || optionsServer;

  const valueToOptions = useCallback(
    (_value: TValue | TValue[] | null) => {
      // multiple
      if (multiple && Array.isArray(_value)) {
        return (_value
          ?.map((itemValue) =>
            options.find((option) => itemValue === getOptionValue?.(option)),
          )
          .filter((option) => !!option) || []) as TOption[];
      }

      // single
      const valueOption = options.find(
        (option) => _value === getOptionValue?.(option),
      );
      return valueOption ? [valueOption] : [];
    },
    [getOptionValue, options, multiple],
  );

  const selectedOptions: TOption[] = useMemo(
    () => valueToOptions(value),
    [value, valueToOptions],
  );

  const [search, setSearch] = useState('');

  /** локальная фильтрация для списка полученых опций */
  const filteredOptions = useMemo(() => {
    if (!search) {
      return options || [];
    }

    const searchValue = search.toLowerCase();

    return (options || []).filter((option) =>
      getOptionLabel(option).toLowerCase().includes(searchValue),
    );
  }, [search, options, getOptionLabel]);

  const searchSelectBaseProps = {
    ...restProps,
    menuWidth,
    getOptionValue,
    getOptionLabel,
    search,
    selectedOptions,
    onChangeSearch: setSearch,
    enableFilterOptions: true,
    clearSearchOnClose: !restProps.disableSearch,
    options,
    isLoading: isLoadingOptions,
  };

  return multiple ? (
    <SearchSelectBase
      {...searchSelectBaseProps}
      options={localFilterOptions ? filteredOptions : options}
      multiple
      onChange={onChange}
    />
  ) : (
    <SearchSelectBase {...searchSelectBaseProps} onChange={onChange} />
  );
}
