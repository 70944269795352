/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { IParsedSubject } from 'features/Signing';

import { FileForSign } from 'shared/api/__generated__';

type SigningStatus = 'active' | 'error' | 'idle' | 'loading' | 'notSet';

export interface IDocumentToSign {
  /** Id документа в БД */
  id: string;
  /** Объект с данными о файле(id, deadline и url для скачивания) */
  file: FileForSign;
  /** Текущее состояние файла в цикле подписи */
  status: 'error' | 'idle' | 'inProgress' | 'signed';
  /** Количество попыток подписания */
  attempts: number;
}

export interface ISigningSlice {
  /** Данные о сертификате */
  certificate: {
    /** Thumbrint сертификата - уникален */
    serial: string;
    /** Id сертификата в БД */
    certificateId: string;
    /** Название орг юнита */
    orgUnitName: string;
    /** Информация о владельце сертификата */
    subject?: IParsedSubject;
    /** Дата выдачи */
    issuedAt: string;
    /** Дата истечения */
    expiresAt: string;
  };
  /** Счетчик при апдейте, которого переходим к следующему документу в очереди  */
  signingCounter: number;
  /**
   * Определяет есть ли в данный момент активная подпись.
   * Нужно для отрисовки состояния в дропдауне
   */
  isSigning: boolean;
  /** Массив документов на подпись */
  documentsToSign: IDocumentToSign[];
  /** Массив Id подписанных документов */
  documentsSigned: string[];
  /**
   * Определяет наличие ошибок, связанных с сертификатом.
   * Нужно для отрисовки состояния в дропдауне
   */
  isCertificateError: boolean;
  /**
   * Определяет наличие ошибок, связанных с процессом подписания.
   * Нужно для отрисовки состояния в дропдауне
   */
  isSigningError: boolean;
  /**
   * Определяет наличие ошибок, связанных с WS соединением.
   * Нужно для отрисовки состояния в дропдауне
   */
  isConnectionError: boolean;

  /** Статус подписи документов */
  status: SigningStatus;
}

const initialState: ISigningSlice = {
  signingCounter: 0,
  status: 'idle',
  certificate: {
    serial: '',
    certificateId: '',
    orgUnitName: '',
    subject: undefined,
    issuedAt: '',
    expiresAt: '',
  },
  isSigning: false,
  documentsToSign: [],
  documentsSigned: [],
  isCertificateError: false,
  isSigningError: false,
  isConnectionError: false,
};

export const signingSlice = createSlice({
  name: 'signingDocuments',
  initialState,
  reducers: {
    /** Статус активации подписи документов */
    setStatus: (state, { payload }: { payload: SigningStatus }) => {
      state.status = payload;
    },
    /** Выставляет флаг, указывающий на наличие ошибки связанной с сертификатом */
    setCertificateError: (state, { payload }) => {
      state.isCertificateError = payload;
    },
    /** Выставляет флаг, указывающий на наличие ошибки связанной с подписанием */
    setIsSigningError: (state, { payload }) => {
      state.isSigningError = payload;
    },
    /** Выставляет флаг, указывающий на наличие ошибки связанной с WS соединением */
    setIsConnectionError: (state, { payload }) => {
      state.isConnectionError = payload;
    },
    /** Выставляет флаг, указывающий на наличие активного процесса подписания */
    setIsSigning: (state, { payload }) => {
      state.isSigning = payload;
    },
    /** Сохраняет данные о сертификате в стор */
    setCertificate: (state, { payload }) => {
      state.certificate = payload;

      // Чистим очередь, если сертификат был изменён
      state.documentsToSign = [];
      state.documentsSigned = [];
    },
    /** Очищает выбранный сертификат */
    clearCertificate: (state) => {
      state.certificate = initialState.certificate;

      // Чистим очередь, если сертификат был сброшен
      state.documentsToSign = [];
      state.documentsSigned = [];
    },
    /** Добавляет документ в очередь на подписание */
    addDocumentsToSign: (state, { payload }) => {
      state.documentsToSign.push(payload);
    },
    /** Добавляет Id подписанного документа в список подписанных документов */
    addSignedDocuments: (state, { payload }) => {
      state.documentsSigned.push(payload);
    },
    /** Изменяет состояние документа в очереди на подписание */
    changeDocumentStatus: (state, { payload }) => {
      const { id, status } = payload;
      const fileToChangeIdx = state.documentsToSign.findIndex(
        (el) => el.id === id,
      );
      state.documentsToSign.splice(fileToChangeIdx, 1, {
        ...state.documentsToSign[fileToChangeIdx],
        status,
        attempts:
          status === 'error'
            ? state.documentsToSign[fileToChangeIdx].attempts + 1
            : state.documentsToSign[fileToChangeIdx].attempts,
      });
    },
    /** Возобновляет подписание, при достижении лимита неуспешных попыток */
    resumeSigning: (state) => {
      const fileToChangeIdx = state.documentsToSign.findIndex(
        (el) => el.status === 'inProgress' || el.status === 'error',
      );

      state.documentsToSign.splice(fileToChangeIdx, 1, {
        ...state.documentsToSign[fileToChangeIdx],
        status: 'idle',
        attempts: 0,
      });
      state.signingCounter += 1;
      state.isSigningError = false;
    },
    /** Очищает очередь */
    clearDocuments: (state) => {
      state.documentsToSign = [];
      state.documentsSigned = [];
    },
    /** Обновляет счетчик цикла */
    tickCounter: (state) => {
      state.signingCounter += 1;
    },
  },
});

export const { actions: signingActions } = signingSlice;
export const { reducer: signingReducer } = signingSlice;
