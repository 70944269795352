import { IlClose } from '@mc/react-icons/il';
import { Box, Stack } from '@mui/material';

import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import cls from './CertificateSelectModal.module.scss';

interface ErrorScreenContentProps {
  title: string;
  description: string;
}

export const ErrorScreenContent = (props: ErrorScreenContentProps) => {
  const { title, description } = props;
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      gap='.5rem'
      textAlign='center'
    >
      <Box className={cls.IconContainer}>
        <IconWrapper Svg={IlClose} className={cls.CrossIcon} />
      </Box>
      <Typography variant='h3'>{title}</Typography>
      <Typography variant='t2' className={cls.GreyText} px='5rem'>
        {description}
      </Typography>
    </Stack>
  );
};
