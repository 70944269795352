import { AccountType } from '../../__generated__';

export const RoleMappedType: Record<AccountType, string> = {
  [AccountType.BASIC]: 'Работник',
  [AccountType.MANAGER]: 'Менеджер',
  [AccountType.ADMIN]: 'Администратор',
  [AccountType.MEDIC]: 'Медработник',
  [AccountType.BOT]: 'Бот',
  [AccountType.SYSTEM]: 'Система',
  [AccountType.ROOT]: 'ROOT',
};
