import { Chip, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'shared/ui/buttons/Button';
import { Typography } from 'shared/ui/contents/Typography';

import { fakeExamPost } from '../../../../../api/FakeExamPost';
import { FakeExamItem, fakeExamsGet } from '../../../../../api/FakeExamsGet';

interface FakerExamActionsProps {
  params: {
    host: Nullable<string>;
    account: Nullable<string>;
  };
}

export const FakerExamActions = (props: FakerExamActionsProps) => {
  const { params } = props;

  const { host, account } = params;
  const [fakes, setFakes] = useState<FakeExamItem[]>([]);

  const [status, setStatus] = useState<
    'error' | 'idle' | 'inProgress' | 'success'
  >('idle');
  const [chipMessage, setChipMessage] = useState<any>(null);

  useEffect(() => {
    fakeExamsGet().then((res) => {
      setFakes(res.fakes);
    });
  }, []);

  const handlerCreateExamFake = useCallback(
    async (key: string) => {
      if (!host || !account) return;
      setStatus('inProgress');
      setChipMessage('Грузим...');

      await fakeExamPost({ key, hostId: host, employeeId: account })
        .then((res) => {
          if (res.success === true) {
            setStatus('success');
            setChipMessage('Осмотр успешно добавлен');
          } else {
            throw new Error(`${JSON.stringify(res)}`);
          }
        })
        .catch(() => {
          setStatus('error');
          setChipMessage('Произошла ошибка');
        });
    },
    [host, account],
  );

  const disabled = useMemo(() => {
    return !host || !account;
  }, [host, account]);

  const chipColor = useMemo(() => {
    switch (status) {
      case 'inProgress':
        return 'info';
      case 'error':
        return 'error';
      case 'success':
        return 'success';
      default:
        return 'default';
    }
  }, [status]);

  return (
    <Stack gap='1rem'>
      <Stack style={{ width: '100%' }} gap='0.5rem'>
        <Typography variant='h2'>Выберите фейковый осмотр</Typography>
        {disabled && (
          <div
            style={{
              borderRadius: '20px',
              padding: 8,
              border: '1px solid var(--color-blue-050)',
              textAlign: 'center',
            }}
          >
            <Typography variant='h4' color='var(--color-blue-100)'>
              Для добавления осмотра, выберите ПАК и Аккаунт
            </Typography>
          </div>
        )}
      </Stack>

      <Stack
        style={{ width: '100%' }}
        flexDirection='row'
        flexWrap='wrap'
        gap='0.5rem'
      >
        {fakes.map((el) => (
          <Button
            key={el.key}
            disabled={disabled}
            onClick={() => handlerCreateExamFake(el.key)}
            size='small'
            variant='outlined'
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: el.color,
                marginRight: 6,
                borderRadius: '100%',
                border: '1px solid #ccc',
              }}
            />
            {el.name}
          </Button>
        ))}
      </Stack>

      <div
        style={{
          position: 'fixed',
          bottom: 5,
          right: 5,
          maxWidth: '500px',
        }}
      >
        {chipMessage && (
          <Chip label={chipMessage} variant='filled' color={chipColor} />
        )}
      </div>
    </Stack>
  );
};
