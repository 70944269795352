import { MedCabinetPage } from 'pages/MedCabinet';

import {
  MedCabinetRouteNames,
  MedCabinetRoutePaths,
} from 'shared/const/router';

import { TRouteProps } from './types';

export const MedCabinetRoutes: Record<MedCabinetRouteNames, TRouteProps> = {
  [MedCabinetRouteNames.MED_CABINET]: {
    path: MedCabinetRoutePaths.getRouteMedCabinet(),
    element: <MedCabinetPage />,
    isAuth: true,
  },
};
