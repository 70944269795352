import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { formatDate } from 'shared/lib/utils/dates';
import { Typography } from 'shared/ui/contents/Typography';

import cls from './NavbarSigningControl.module.scss';

interface SigningDropdownContentProps {
  title: string;
  description?: string;
  orgUnitName?: string;
  children?: ReactNode;
  validTo?: string;
  serial?: string;
}

export const SigningDropdownContent = (props: SigningDropdownContentProps) => {
  const { title, description, children, orgUnitName, validTo, serial } = props;
  return (
    <Stack className={cls.DropdownBody} gap='.5rem'>
      <Typography variant='h4'>{title}</Typography>
      {description && (
        <Typography variant='t3' className={cls.Description}>
          {description}
        </Typography>
      )}
      {validTo && serial && (
        <Stack className={cls.CertInfoContainer}>
          {orgUnitName && (
            <Typography variant='t3' className={cls.CertOUName}>
              {`«${orgUnitName}»`}
            </Typography>
          )}
          <Stack className={cls.CertInfoRow}>
            <Typography variant='t3' className={cls.CertInfo}>
              {`${serial}`}
            </Typography>
            <Typography variant='t3' className={cls.Description}>
              {`до ${formatDate(validTo, 'DD.MM.YYYY', { withoutTz: true })}`}
            </Typography>
          </Stack>
        </Stack>
      )}

      {children}
    </Stack>
  );
};
