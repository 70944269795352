/* eslint-disable no-await-in-loop */
import { Timespan } from '@rsdk/common';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useNotifications } from 'processes/notifications/ui/Notifications.context';

import { createSelectors } from 'shared/lib/external/zustand';
import { executeWithStrategy } from 'shared/lib/utils/executeWithStrategy';

import { useServerInfoStore } from '../store/ServerInfo.store';

const ERROR_MESSAGES = {
  badTime: 'Обнаружено расхождение времени с сервером',
};

const isBadTime = (
  /** Время разницы между клиентом и сервером, ms */
  offset: number,
) => Math.abs(offset) >= new Timespan(5, 'seconds').millis();

const useRunOnce = (fn: () => any) => {
  const isRunningOnce = useRef(false);

  useEffect(() => {
    if (isRunningOnce.current === false) {
      isRunningOnce.current = true;
      (async () => {
        await fn();
      })();
    }
  }, []);

  return {};
};

export const useCheckServerInfo = () => {
  const serverInfoStore = createSelectors(useServerInfoStore);
  const fetchServerTime = serverInfoStore.use.fetchServerTime();
  const lastUpdate = serverInfoStore.use.lastUpdate?.();
  const clientOffset = serverInfoStore.use.clientOffset();

  const [status, setStatus] = useState<
    'error' | 'idle' | 'loading' | 'success'
  >('idle');

  const [hasProblem, setHasProblem] = useState<string>();
  const badTime = useRef<number[]>([]);
  const nextCheck = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isBadTime(clientOffset)) {
      if (!hasProblem) {
        badTime.current.push(clientOffset);

        if (badTime.current.length >= 4) {
          setHasProblem(ERROR_MESSAGES.badTime);
          console.error(ERROR_MESSAGES.badTime, badTime.current);
          badTime.current = [];

          nextCheck.current = setTimeout(() => {
            setHasProblem(undefined);
          }, new Timespan(15, 'minutes').millis());
        }
      }
    } else {
      clearTimeout(nextCheck.current);
      badTime.current = [];
      setHasProblem(undefined);
    }
  }, [clientOffset]);

  const notificationsActions = useNotifications();

  useEffect(() => {
    if (hasProblem) {
      notificationsActions.showErrorNotification({
        title: hasProblem,
        message:
          'Пожалуйста, обратитесь в техническую поддержку вашей компании',
      });
    }
  }, [hasProblem, notificationsActions]);

  const cb = useCallback(async () => {
    setStatus('loading');
    try {
      const stopExecute = executeWithStrategy({
        base: {
          fn: async () => {
            const res = await fetchServerTime();
            if (status !== 'success') {
              setStatus('success');
            }
            return res;
          },
          interval: new Timespan(10, 'minutes').millis(),
          checkStopCondition: () => false,
        },
        errors: {
          maxErrorRetries: 10,
          errorRetryInterval: new Timespan(5, 'seconds').millis(),
          onStop: () => {
            setStatus('error');
          },
        },
        check: {
          checkCondition: (value) => isBadTime(value.clientOffset),
          retryCount: 3,
          retryInterval: 2000,
        },
      });

      return await stopExecute;
    } catch (err) {
      console.error('Не удалось получить серверное время', err);
      setStatus('error');
    }
    return undefined;
  }, [fetchServerTime, status]);

  useRunOnce(cb);

  return {
    status,
  };
};
