import { NotFoundResourceWidget } from 'widgets/NotFoundResourceWidget';

import { classNames } from 'shared/lib/utils/classNames';
import { Page } from 'shared/ui/helpers/Page';

import cls from './NotFoundPage.module.scss';

export interface NotFoundPageProps {
  className?: string;
}

export const NotFoundPage = (props: NotFoundPageProps) => {
  const { className } = props;

  return (
    <Page className={classNames(cls.NotFoundPage, {}, [className])}>
      <NotFoundResourceWidget />
    </Page>
  );
};
