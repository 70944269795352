import { IconType } from '@mc/react-icons';
import { Menu, MenuItem, MenuProps, Stack, styled } from '@mui/material';
import {
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames';
import { cssVar } from 'shared/lib/utils/cssVar';

import { IconWrapper } from '../../../helpers/IconWrapper';
import { Button, IButtonProps } from '../../Button';
import cls from './Dropdown.module.scss';

export interface DropdownItem {
  id: string;
  disabled?: boolean;
  content?: ReactNode;
  contentColor?: string;
  onClick?: () => void;
  href?: string;
  notCloseAfterClick?: boolean;
  icon?: IconType;
}

interface DropdownMenuProps {
  anchorEl: HTMLElement | null;
  handleCloseMenu: () => void;
  onTrigger: 'click' | 'hover';

  /** Список для рендера MenuItem */
  items: DropdownItem[];

  /** Ширина подложки меню */
  paperWidth?: number;
}

interface DropdownProps {
  /** Список для рендера MenuItem */
  items?: DropdownItem[];

  /** Компонент, который триггерит открытие меню (аналог children для button) */
  trigger?:
    | ReactElement<{
        onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
        onMouseEnter?: (e: MouseEvent<HTMLButtonElement>) => void;
      }>
    | string;

  /** Ширина подложки меню */
  paperWidth?: number;

  /** Размер trigger элемента */
  size?: IButtonProps['size'];

  /** Disable */
  disabled?: boolean;

  /** Доп. функция при закрытии */
  onClose?: () => void;

  /** Тип тригера кнопки */
  onTrigger: 'click' | 'hover';
}

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    '& .MuiPaper-root': {
      margin: '0 0 0 -10px',
      padding: `4px 10px 10px 10px`,
      background: 'transparent',
      width: 'auto',
      boxShadow: 'none',
      pointerEvents: 'auto',

      '& .MuiList-root': {
        borderRadius: '8px',
        background: cssVar('--color-monochrome-white'),
        boxShadow: cssVar('--shadow-small'),
        overflow: 'hidden',
      },

      '& .MuiMenuItem-root': {
        '&:hover': {
          color: cssVar('--color-blue-100'),
        },
      },
    },
  }),
);

const DropdownMenu = (props: DropdownMenuProps) => {
  const { anchorEl, onTrigger, handleCloseMenu, items, paperWidth } = props;

  return (
    <StyledMenu
      className={classNames(cls.Menu)}
      id='menu'
      disableScrollLock
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      autoFocus={false}
      onClose={handleCloseMenu}
      slotProps={{
        root: {
          sx: {
            pointerEvents: onTrigger === 'hover' ? 'none' : 'auto',
          },
        },
      }}
    >
      {items.map((elem) => (
        <MenuItem
          className={classNames(cls.MenuItem)}
          key={elem.id}
          onClick={() => {
            if (!elem.notCloseAfterClick) handleCloseMenu();
            elem?.onClick?.();
          }}
          disabled={elem.disabled}
        >
          {elem?.icon && (
            <IconWrapper
              Svg={elem.icon}
              className={classNames(cls.MenuItemIcon)}
              color={elem.contentColor}
            />
          )}
          {elem.content}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export const Dropdown = (props: DropdownProps) => {
  const {
    trigger,
    onTrigger,
    size,
    items = [],
    paperWidth,
    disabled,
    onClose,
  } = props;

  const triggerIsElement = isValidElement(trigger);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsActive((prev) => !prev);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setIsActive(false);
    onClose?.();
  };

  const triggerWithProps = triggerIsElement
    ? cloneElement(trigger, {
        [onTrigger === 'hover' ? 'onMouseEnter' : 'onClick']: (
          e: MouseEvent<HTMLButtonElement>,
        ) => handleOpenMenu(e),
      })
    : undefined;

  return (
    <Stack onMouseLeave={onTrigger === 'hover' ? handleCloseMenu : undefined}>
      {triggerIsElement ? (
        triggerWithProps
      ) : (
        <Button
          className={classNames('', { [cls.ActiveButton]: isActive }, [])}
          id='button'
          color='primary'
          variant='outlined'
          size={size}
          fullWidth
          onClick={handleOpenMenu}
          aria-controls='menu'
          disabled={disabled}
        >
          {trigger}
        </Button>
      )}
      <DropdownMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        items={items}
        paperWidth={paperWidth}
        onTrigger={onTrigger}
      />
    </Stack>
  );
};
