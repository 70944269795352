import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { HostFilters, Pagination } from 'entities/Host';

import { useDebounce } from 'shared/lib/hooks/useDebounce';
import { useErrorHandlerContext } from 'shared/lib/hooks/useErrorHandlerContext';
import { UniversalAutocomplete } from 'shared/ui/form/inputs/UniversalAutocomplete';

import { HOSTS_FAKER_SEARCH } from '../../../../../api/HostsFakerSearch.gql';
import { IItem } from '../../../../../types';

interface FakerExamHostSelectProps {
  inputValue: Nullable<IItem>;
  onChangeInputValue: (value: Nullable<IItem>) => void;
  filters?: HostFilters;
}

const STEP = 10;

export const FakerExamHostSelect = (props: FakerExamHostSelectProps) => {
  const { inputValue, onChangeInputValue, filters } = props;

  const errorHandlerContext = useErrorHandlerContext();

  const [isOpen, setIsOpen] = useState(false);

  const [params, setParams] = useState({
    search: '',
    filters,
    offset: 0,
    total: 0,
    isLoading: false,
    items: [] as IItem[],
  });
  const [value, setValue] = useState<Nullable<IItem>>(inputValue);
  const [memoVariables, setMemoVariables] = useState<string>('');

  const changeValue = useCallback(
    (el: Nullable<IItem>) => {
      onChangeInputValue(el);
      setValue(el);
    },
    [onChangeInputValue, setValue],
  );

  const changeParams = useCallback(
    (
      el:
        | ((prev: typeof params) => Partial<typeof params>)
        | Partial<typeof params>,
    ) => {
      setParams((prev) => {
        const newValue = typeof el === 'function' ? el(prev) : el;
        return { ...prev, ...newValue };
      });
    },
    [setParams],
  );

  /** Действия при изменении текста поиска OU */
  const changeSearchText = useCallback(
    (el: string) => {
      changeParams({
        offset: 0,
        search: el,
      });
    },
    [changeParams],
  );

  const [getList] = useLazyQuery(HOSTS_FAKER_SEARCH, {
    context: errorHandlerContext({
      message: 'Не удалось загрузить ПАКи',
    }),

    onCompleted: (res) => {
      const page = res.hosts.getFilteredList;
      const { total, items } = page;
      changeParams({ total });

      const selectValues = items.map((item) => ({
        id: item.id,
        label: item.configuration?.hardwareId || item.name,
      }));

      if (params.offset > 0) {
        changeParams((prev) => ({
          items: [...prev.items, ...selectValues],
        }));
      } else {
        changeParams({ items: selectValues });
      }

      changeParams({ isLoading: false });
    },
  });

  // Отложенный запрос списка OU
  const memoParams = useMemo(
    () => ({
      search: params.search,
      offset: params.offset,
    }),
    [params.search, params.offset],
  );
  const debouncedParams = useDebounce(memoParams);

  // При изменении внешнего значения - изменить внутреннее
  useEffect(() => setValue(inputValue), [inputValue]);
  useEffect(() => changeParams({ filters }), [filters, changeParams]);

  // Запрос списка OU
  useEffect(() => {
    changeParams({ isLoading: true });
    const variables: {
      filters: HostFilters;
      pagination: Pagination;
    } = {
      filters: {
        ...(!!debouncedParams.search && { search: debouncedParams.search }),
        ...(params.filters || {}),
      },
      pagination: { limit: STEP, offset: debouncedParams.offset },
    };

    if (JSON.stringify(variables) !== memoVariables && isOpen) {
      setMemoVariables(JSON.stringify(variables));
      getList({ variables });
    } else {
      changeParams({ isLoading: false });
    }
  }, [
    debouncedParams,
    memoVariables,
    getList,
    changeParams,
    params.filters,
    isOpen,
  ]);

  return (
    // Фильтр OU
    <UniversalAutocomplete
      options={params.items}
      isLoading={params.isLoading}
      isFullyLoaded={params.items.length === params.total}
      loadMore={() => {
        if (!params.isLoading)
          changeParams((prev) => ({
            ...prev,
            offset: prev.offset + STEP,
          }));
      }}
      value={value}
      onChangeValue={(el) => changeValue(el)}
      search={params.search}
      onChangeSearch={(search) => changeSearchText(search)}
      onOpenHandler={() => setIsOpen(true)}
      onCloseHandler={() => setIsOpen(false)}
      clearSearchOnClose
    />
  );
};
