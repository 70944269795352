import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { EnrollmentStatus } from 'shared/api/__generated__';

import { IItem } from '../../../../types';
import { FakerExamAccountSelect } from './ui/FakerExamAccountSelect';
import { FakerExamActions } from './ui/FakerExamActions';
import { FakerExamHostSelect } from './ui/FakerExamHostSelect';
import { FakerExamOrgUnitSelect } from './ui/FakerExamOrgUnitSelect';

export const FakerExam = () => {
  const [ou, setOu] = useState<Nullable<IItem>>(null);
  const [host, setHost] = useState<Nullable<IItem>>(null);
  const [account, setAccount] = useState<Nullable<IItem>>(null);

  // Фильтры зависимые, поэтому при изменении OU нужно
  // сбросить остальные фильтры
  useEffect(() => {
    setHost(null);
    setAccount(null);
  }, [ou]);

  return (
    <Stack flexDirection='column' gap='1rem'>
      <Typography variant='h2'>Параметры</Typography>

      <Stack gap='0.5rem'>
        <Typography variant='h4'>Выберите OU</Typography>
        <FakerExamOrgUnitSelect
          inputValue={ou}
          onChangeInputValue={(el) => setOu(el)}
        />
      </Stack>

      <Stack gap='0.5rem'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='end'
        >
          <Typography variant='h4'>Выберите ПАК</Typography>
          <Typography variant='caption' color='GrayText'>
            активные и отфильтрованы по OU
          </Typography>
        </Stack>
        <FakerExamHostSelect
          inputValue={host}
          onChangeInputValue={(el) => setHost(el)}
          filters={
            ou
              ? {
                  subtree: { in: [ou.id] },
                  enrollmentStatus: { in: [EnrollmentStatus.ENROLLED] },
                }
              : undefined
          }
        />
      </Stack>

      <Stack gap='0.5rem'>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='end'
        >
          <Typography variant='h4'>Выберите аккаунт</Typography>
          <Typography variant='caption' color='GrayText'>
            отфильтрованы по OU
          </Typography>
        </Stack>
        <FakerExamAccountSelect
          inputValue={account}
          onChangeInputValue={(el) => setAccount(el)}
          filters={{ ou: ou ? ou.id : null }}
        />
      </Stack>

      <Stack>
        <FakerExamActions
          params={{
            host: host ? host.id : null,
            account: account ? account.id : null,
          }}
        />
      </Stack>
    </Stack>
  );
};
