import { gql } from '../__generated__';

export const SIGNING_ASSERT_CERTIFICATE = gql(/* GraphQL */ `
  mutation SIGNING_ASSERT_CERTIFICATE($input: X509CertificateInput!) {
    signing {
      certificates {
        assert(input: $input) {
          certificate {
            id
            addedAt
            serial
            issuer
            subject
            issuedAt
            expiresAt
          }
          isJustCreated
        }
      }
    }
  }
`);
