/* eslint-disable no-param-reassign */
import { FieldPolicy, InMemoryCache } from '@apollo/client';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Subscription: {
      fields: {
        /**
         * Для обновления токена в подписках существует отдельный тип запроса с бэка
         * он не бъётся с основным типом и apollo client не может его замержить с предыдущими данными
         * так как набор полей на верхнем уровне не совпадает. Для этого мы переопределяем политику
         * слияния полей для типа Subscription и пропускаем все события с типом TokenUpdateSubscriptionEvent
         * так как они не должны влиять на данные в кеше. Это событие обрабатывается подпиской на события
         * on('message',...) напрямую в клиенте подписки.
         *
         * Здесь перечисляются все подписки с unions в которых есть тип TokenUpdateSubscriptionEvent.
         *
         * ### Например
         * В подписке queueStats могут приходить два типа данных (union):
         * {"queueStats":{"__typename":"MedcabQueueStats","categoryQueues":[...],"shiftsOverview":{...}}}
         * {"queueStats":{"__typename":"TokenUpdateSubscriptionEvent","updateToken":true}}
         *
         * в этом случае состав полей полностью различается и apollo client не может их замержить.
         * Поэтому, мы пропускаем все события с типом TokenUpdateSubscriptionEvent
         * и не обновляем кеш.
         */
        ...['queueStats', 'filesForSign', 'myEvents', 'myNotifications'].reduce(
          (agg: Record<string, FieldPolicy>, value) => {
            agg[value] = {
              merge(existing, incoming, { mergeObjects }) {
                if (incoming.__typename === 'TokenUpdateSubscriptionEvent') {
                  return existing;
                }
                return mergeObjects(existing, incoming);
              },
            };
            return agg;
          },
          {},
        ),
      },
    },
  },
});
