import { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { getCanMakeActionRule } from 'processes/user';

import { FakerWidget } from 'widgets/FakerWidget';
import { Navbar } from 'widgets/Navbar';
import { PageLoader } from 'widgets/PageLoader';

import { TPermissionKey } from 'shared/api/authApi/types';

import {
  CheckTokenGuard,
  InternalResourceGuard,
  PermissionsGuard,
} from '../../../lib';
import { ErrorBoundary } from '../../error';

interface AuthorizedPageProps {
  children: React.ReactNode;
  params?: {
    needRules?: TPermissionKey[];
    beforeEach?: () => void;
  };
}

export const AuthorizedPage = (props: AuthorizedPageProps) => {
  const { children, params } = props;
  const { needRules, beforeEach } = params || {};

  const canUseFakerExam = useSelector(
    getCanMakeActionRule('faker-service.method.add_test_exam'),
  );

  return (
    <CheckTokenGuard>
      <InternalResourceGuard beforeEach={beforeEach}>
        <PermissionsGuard needRules={needRules}>
          <>
            <Navbar />
            <ErrorBoundary>
              <Suspense fallback={<PageLoader />}>{children}</Suspense>
            </ErrorBoundary>
            {canUseFakerExam && <FakerWidget />}
          </>
        </PermissionsGuard>
      </InternalResourceGuard>
    </CheckTokenGuard>
  );
};
