import { ExamDetailsPage, ExamsPage } from 'pages/Exam';

import { ExamsRouteNames, ExamsRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const ExamsRoutes: Record<ExamsRouteNames, TRouteProps> = {
  [ExamsRouteNames.EXAMS]: {
    path: ExamsRoutePaths.getRouteExams(),
    element: <ExamsPage />,
    isAuth: true,
  },
  [ExamsRouteNames.EXAMS_DETAILS_INFO]: {
    path: ExamsRoutePaths.getRouteExamDetailsInfo(':id'),
    element: <ExamDetailsPage />,
    isAuth: true,
  },
  [ExamsRouteNames.EXAMS_DETAILS_CONDITIONS]: {
    path: ExamsRoutePaths.getRouteExamDetailsConditions(':id'),
    element: <ExamDetailsPage />,
    isAuth: true,
  },
  [ExamsRouteNames.EXAMS_DETAILS_HISTORY]: {
    path: ExamsRoutePaths.getRouteExamDetailsHistory(':id'),
    element: <ExamDetailsPage />,
    isAuth: true,
  },
  [ExamsRouteNames.EXAMS_DETAILS_RECEIPT]: {
    path: ExamsRoutePaths.getRouteExamDetailsReceipt(':id'),
    element: <ExamDetailsPage />,
    isAuth: true,
  },
};
