import { Timespan } from '@rsdk/common';

/** Код ошибки авторизации */
export const UNAUTHENTICATED_CODE = 401;

/**
 * Зазор к времени жизни токена.
 * Чтобы запрос успел выполниться до истечения жизни токена
 */
export const TOKEN_EXPIRATION_MARGIN = new Timespan(20, 's');

/** Название запросов на рефреш токена */
export const REFRESH_TOKENS_NAME = 'AUTH_REFRESH';

/** Список запросов доступных без авторизации */
export const AUTH_OPERATIONS_IGNORE = [
  'AUTH_CHOOSE_ACCOUNT',
  'AUTH_CONFIRM',
  'AUTH_RESEND_CODE',
  'GAIN_CHOOSE_ACCOUNT',
  'GAIN_CONFIRM',
  'GAIN_GAIN',
  'GAIN_RESEND_CODE',
  'GAIN_SET_PASSWORD_AND_LOGIN',
  'LOGIN',
];
