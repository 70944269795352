import {
  IlEyeClose as VisibilityOffIcon,
  IlEyeOpen as VisibilityIcon,
} from '@mc/react-icons/il';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { forwardRef, MouseEvent as MouseEventReact, useState } from 'react';

import { IconButton } from '../../../../buttons/IconButton';
import { IconWrapper } from '../../../../helpers/IconWrapper';
import { TextField } from '../../TextField';

export const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const { ...args } = props;

    const [showPassword, setShowPassword] = useState(false);
    const changeStatus = (
      e: MouseEventReact<HTMLButtonElement, MouseEvent>,
    ) => {
      e.preventDefault();
      setShowPassword((show) => !show);
    };

    return (
      <TextField
        {...args}
        inputRef={ref}
        type={showPassword ? 'text' : 'password'}
        size='medium'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={changeStatus} edge='end' tabIndex={-1}>
                <IconWrapper
                  color='var(--color-monochrome-grey-300)'
                  Svg={showPassword ? VisibilityOffIcon : VisibilityIcon}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  },
);

PasswordField.displayName = 'PasswordField';
