/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { AlertTypes } from 'shared/ui/helpers/Alert';

export interface INotificationSlice {
  open: boolean;
  message: string;
  title: string;
  type: AlertTypes;
  button?: {
    /** Текст на кнопке уведомления */
    text: string;
  } & (
    | {
        navigatePath: string;
      }
    | {
        useClickHandlerFromContext: true;
      }
  );
}

export const initialState: INotificationSlice = {
  open: false,
  message: '',
  title: '',
  type: AlertTypes.INFO,
  button: {
    text: '',
    navigatePath: '',
  },
};

const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showSuccessNotification: (
      state,
      action: {
        payload: {
          title?: string;
          message: string;
          button?: INotificationSlice['button'];
        };
      },
    ) => {
      const { title, message, button } = action.payload;
      state.open = true;
      state.type = AlertTypes.SUCCESS;
      state.title = title || 'Успешно';
      state.message = message || '';
      state.button = button;
    },
    showErrorNotification: (
      state,
      action: {
        payload: {
          title?: string;
          message: string;
          button?: INotificationSlice['button'];
        };
      },
    ) => {
      const { title, message, button } = action.payload;
      state.open = true;
      state.type = AlertTypes.ERROR;
      state.title = title || 'Ошибка';
      state.message = message || '';
      state.button = button;
    },
    showInfoNotification: (
      state,
      action: {
        payload: {
          title?: string;
          message: string;
          button?: INotificationSlice['button'];
        };
      },
    ) => {
      const { title, message, button } = action.payload;
      state.open = true;
      state.type = AlertTypes.INFO;
      state.title = title || 'Информация';
      state.message = message || '';
      state.button = button;
    },
    hideNotification: (state) => {
      state.open = false;
      state.title = '';
      state.message = '';
      state.button = undefined;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
