import { Box, Stack } from '@mui/material';

import RoundGradient from 'shared/assets/round-gradient.svg?react';
import RoundRed from 'shared/assets/round-red.svg?react';
import { classNames } from 'shared/lib/utils/classNames';
import { toUserTime } from 'shared/lib/utils/datetime';

import { Typography } from '../../Typography';
import cls from './Countdown.module.scss';
import { CountdownProps, Size, Variants } from './types';

const variants: Record<Size, Variants> = {
  [Size.s]: {
    typographyVariant: 'h3',
    className: cls.Small,
  },
  [Size.l]: {
    typographyVariant: 'h1',
    className: cls.Large,
  },
};

export const Countdown = (props: CountdownProps) => {
  const { timeLeft, size, roundRotation = false } = props;
  const { typographyVariant, className } = variants[size];

  return (
    <Stack
      flexDirection='row'
      gap='1rem'
      alignItems='center'
      justifyContent='center'
    >
      <Box className={classNames(cls.Timer)}>
        <Stack
          className={classNames(
            '',
            {
              [cls.RoundAnimation]: roundRotation,
            },
            [className],
          )}
        >
          {timeLeft && timeLeft < 60 ? <RoundRed /> : <RoundGradient />}
        </Stack>

        <Typography
          className={cls.Text}
          variant={typographyVariant}
          color='var(--color-blue-200)'
        >
          {timeLeft !== null && timeLeft >= 0 ? toUserTime(timeLeft) : '––:––'}
        </Typography>
      </Box>
    </Stack>
  );
};
