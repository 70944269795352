import { Typography as MUITypography, TypographyProps } from '@mui/material';

export const Typography = (props: TypographyProps) => {
  const { variant, color, ...args } = props;

  const prevVariant = variant || 't1';
  const prevColor = color || 'currentcolor';

  return <MUITypography {...args} color={prevColor} variant={prevVariant} />;
};
