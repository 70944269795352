import { Snackbar } from '@mui/material';

import { classNames } from 'shared/lib/utils/classNames';
import { Mods } from 'shared/lib/utils/classNames/classNames';
import { AlertTypes } from 'shared/ui/helpers/Alert';
import { getButtonData } from 'shared/ui/helpers/Alert/lib/helpers';
import { AlertWithRef as Alert } from 'shared/ui/helpers/Alert/ui/Alert';

import {
  useNotifications,
  useNotificationState,
} from './Notifications.context';
import cls from './NotificationsContainer.module.scss';

export const NotificationsContainer = () => {
  const { type, open, message, title, xRequestId, button } =
    useNotificationState();
  const { hideNotification } = useNotifications();

  const correctButtonInfo = getButtonData({
    type,
    xRequestId,
    button,
  });

  const handleClose = () => {
    hideNotification();
  };

  const mods: Mods = {
    [cls.ErrorContainer]: type === AlertTypes.ERROR,
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={7000}
      onClose={() => {
        handleClose();
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      message={message}
    >
      <div>
        <Alert
          type={type}
          title={title}
          message={message}
          onClose={handleClose}
          className={classNames(cls.NotificationsContainer, mods, [])}
          classes={{
            icon: cls.IconContainer,
            message: classNames(cls.MessageContainer, mods, []),
            action: cls.ActionContainer,
          }}
          buttonInfo={correctButtonInfo}
        />
      </div>
    </Snackbar>
  );
};
