export enum MedCabinetState {
  /** Без смены */
  enter = 'enter',
  /** Смена закрыта */
  finished = 'finished',
  /** Осмотр назначен на медработника */
  hasAssignment = 'hasAssignment',
  /** На линии (назначаются осмотры) */
  idle = 'idle',
  /** На паузе (может быть выставлена после таймаута или ошибки) */
  paused = 'paused',
  /** Осмотр взят в работу */
  working = 'working',
}

export enum MedCabinetEvent {
  /** Назначен осмотр */
  ASSIGNED = 'ASSIGNED',
  /** Вынести решение по осмотру */
  EXAM_RESOLUTION = 'EXAM_RESOLUTION',
  /** Завершить осморт */
  EXAM_TERMINATE = 'EXAM_TERMINATE',
  /** Закрыть смену */
  FINISH_SHIFT = 'FINISH_SHIFT',
  /** Нужна пауза, будет выставлена после решения по осмотру */
  NEED_PAUSE = 'NEED_PAUSE',

  /** Перейти на паузу */
  PAUSE = 'PAUSE',
  /** Вернуться на линию */
  RESUME = 'RESUME',
  /** Начать смену */
  START_SHIFT = 'START_SHIFT',
  /** Взять осмотр в работу */
  TAKEN = 'TAKEN',
}

export enum MedCabinetError {
  /**
   * Событие отключения полученное от сервера.
   * Может приходить по причине повторного подключения к одной подписке.
   */
  DISCONNECT = 'DISCONNECT',
  /** Сетевая ошибка */
  NETWORK = 'NETWORK',
  /** Запрос клиента не успел дойти до сервера за отведенное время. */
  OUT_OF_SYNC = 'OUT_OF_SYNC',
  /** Время на принятие решения истекло */
  TIMEOUT = 'TIMEOUT',
}
