import { ApolloLink } from '@apollo/client';
import { DocumentNode, Kind, visit } from 'graphql';

const getDefinedFragments = (document: DocumentNode) => {
  const fragments: string[] = [];
  visit(document, {
    [Kind.FRAGMENT_DEFINITION]: {
      leave(node) {
        fragments.push(node.name.value);

        return node;
      },
    },
  });
  return fragments;
};

const getUnusedFragments = (document: DocumentNode) => {
  const unusedFragments: string[] = getDefinedFragments(document);

  // ищем использование фрагментов через spread оператор (...) и удаляем из массива не используемых
  visit(document, {
    [Kind.FRAGMENT_SPREAD]: {
      leave(node) {
        const fragmentName = node.name.value;

        if (unusedFragments.includes(fragmentName)) {
          unusedFragments.splice(unusedFragments.indexOf(fragmentName), 1);
        }

        return node;
      },
    },
  });

  return unusedFragments;
};

const removeFragments = (fragments: string[], document: DocumentNode) => {
  return visit(document, {
    [Kind.FRAGMENT_DEFINITION]: {
      leave(node) {
        if (fragments.includes(node.name.value)) return null;

        return node;
      },
    },
  });
};

const removeUnusedFragmentDefinitions = (document: DocumentNode) => {
  let newDocument: DocumentNode = document;
  let unusedFragments: string[] = getUnusedFragments(newDocument);

  // цикл, чтобы удалять фрагменты, которые стали не используемыми после удаления фрагментов в предыдущей иттерации
  do {
    newDocument = removeFragments(unusedFragments, newDocument);
    unusedFragments = getUnusedFragments(newDocument);
  } while (unusedFragments.length);

  return newDocument;
};

/**
 * Middleware, убирающий из запроса не используемые фрагменты
 */
export const removeUnusedFragments = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-param-reassign
  operation.query = removeUnusedFragmentDefinitions(operation.query);

  return forward(operation);
});
