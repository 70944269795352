import { gql } from '@apollo/client';

export const USER_SET_ACCESS = gql`
  mutation USER_SET_ACCESS($input: SetAccessInput!) {
    accounts {
      setAccess(input: $input) {
        ... on Person {
          id
        }
      }
    }
  }
`;
