import { Complaints } from 'shared/api/types/_overwrite';

export const ComplaintsMap: Record<Complaints, { text: string }> = {
  [Complaints.GENERAL_SICKNESS]: { text: 'Общее недомогание' },
  [Complaints.GENERAL_WEAKNESS]: { text: 'Слабость' },
  [Complaints.DIZZINESS]: { text: 'Головокружение' },
  [Complaints.HEADACHE]: { text: 'Головная боль' },
  [Complaints.CHEST_PAIN]: { text: 'Боль в грудной клетке' },
  [Complaints.HEART_PAIN]: { text: 'Боль в области сердца' },
  [Complaints.ARRHYTHMIA]: { text: 'Нарушение сердечного ритма (перебои)' },
  [Complaints.STOMACH_ACHE]: { text: 'Боль в животе' },
  [Complaints.INDIGESTION]: { text: 'Расстройство пищеварения' },
  [Complaints.JOINT_PAIN]: { text: 'Суставная боль' },
  [Complaints.TOOTHACHE]: { text: 'Зубная боль' },
  [Complaints.COUGH_SHORT_BREATH]: { text: 'Кашель / отдышка' },
  [Complaints.RASH_ALLERGY]: { text: 'Сыпь / аллергия' },
  [Complaints.NO_COMPLAINTS]: { text: 'Жалоб нет' },
};
