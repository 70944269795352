import {
  PersonNameScalar,
  SizeScalar,
  TimespanScalar,
  URLScalar,
} from '@mc/graphql-shared.scalars';

import { IScalarConfig } from './scalars.types';

/**
 * Единое место для определения импортов скаляров
 */
const imports = {
  Timespan: `import { Timespan } from '@rsdk/common'`,
  Size: `import { Size } from '@rsdk/common'`,
};

// TODO: Добавить в пакет gql-scalars файл codegen.config.yaml
// И доработать для применимости на бэке и фронте
export const scalarConfig: IScalarConfig = {
  // TODO: Включить после реализации всех скаляров
  strictScalars: false,

  globalImports: [],

  items: {
    SNILS: { typeInTs: 'string' },
    Timespan: {
      typeInTs: 'Timespan',
      resolver: TimespanScalar,
      imports: [imports.Timespan],
    },
    Size: { typeInTs: 'Size', resolver: SizeScalar, imports: [imports.Size] },
    URL: { typeInTs: 'URL', resolver: URLScalar },
    INN: { typeInTs: 'string' },
    KPP: { typeInTs: 'string' },
    OGRN: { typeInTs: 'string' },
    PersonName: { typeInTs: 'string', resolver: PersonNameScalar },
  },
};

/**
 * Список уникальных импортов для скаляров
 */
export const scalarImports = () => {
  const globalImports = <string[]>[...scalarConfig.globalImports];

  const itemsImports = Object.values(scalarConfig.items).reduce((agg, item) => {
    if (item.imports) agg.push(...item.imports);
    return agg;
  }, [] as string[]);

  return [...new Set([...globalImports, ...itemsImports])];
};
