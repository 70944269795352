import { createUploadLink } from 'apollo-upload-client';

/**
 * Rest Link
 * @desc Используется обёртка для отправки файлов
 */
export const restLink = createUploadLink({
  uri: `${import.meta.env.__API_GQLGW_URL__}`,
  preserveHeaderCase: true,
});
