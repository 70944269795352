import { Stack } from '@mui/material';
import { forwardRef, MouseEvent } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import cls from './NavbarMenuItemContainer.module.scss';

interface NavbarMenuItemContainerProps {
  children?: React.ReactNode;
  anchor?: React.ReactNode;
  isActive?: boolean;
  className?: string;
  onClick?: (e: MouseEvent) => any;
}

export const NavbarMenuItemContainer = forwardRef<
  HTMLDivElement,
  NavbarMenuItemContainerProps
>((props: NavbarMenuItemContainerProps, ref) => {
  const { children, anchor, isActive, className, onClick } = props;

  if (anchor) {
    return (
      <>
        <Stack
          className={classNames(
            cls.Item,
            { [cls.active]: isActive },
            className ? [className] : undefined,
          )}
          onClick={(e) => onClick?.(e)}
        >
          {anchor}
        </Stack>
        {children}
      </>
    );
  }

  return (
    <Stack
      ref={ref}
      className={classNames(
        cls.Item,
        { [cls.active]: isActive },
        className ? [className, cls.Anchor] : undefined,
      )}
      onClick={(e) => onClick?.(e)}
    >
      {children}
    </Stack>
  );
});

NavbarMenuItemContainer.displayName = 'NavbarMenuItemContainer';
