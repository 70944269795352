import { Chip } from '@mui/material';

import { OrgUnitType } from 'shared/api/__generated__';

import { TOrgUnitsFilters } from '../../types';

type FilterKeyType = 'isCounterparty' | 'isReleasePoint';

type LabelType = 'С реквизитами' | 'Точка выпуска';

interface FilterChipProps {
  filterKey: FilterKeyType;
  label: LabelType;
  filters: TOrgUnitsFilters;
  onChangeFilters: (
    key: keyof TOrgUnitsFilters,
    value: TOrgUnitsFilters[keyof TOrgUnitsFilters],
  ) => void;
  disabled?: boolean;
}

/** Параметры для каждого типа фильтра */
const filterConfig: Record<
  FilterKeyType,
  {
    filterField: keyof TOrgUnitsFilters;
    activeValue: TOrgUnitsFilters[keyof TOrgUnitsFilters];
  }
> = {
  isCounterparty: {
    filterField: 'type',
    activeValue: {
      in: [OrgUnitType.COMPANY, OrgUnitType.INDIVIDUAL_ENTREPRENEUR],
    },
  },
  isReleasePoint: {
    filterField: 'isReleasePoint',
    activeValue: true,
  },
};

export const FilterChip = (props: FilterChipProps) => {
  const { filterKey, label, filters, onChangeFilters, disabled } = props;

  const { filterField, activeValue } = filterConfig[filterKey];
  const isFilterActive = !!filters[filterField];

  const handleFilterChange = () => {
    onChangeFilters(filterField, isFilterActive ? null : activeValue);
  };

  return (
    <Chip
      variant={isFilterActive ? 'filled' : 'outlined'}
      clickable
      label={label}
      color={isFilterActive ? 'lightBlue' : 'default'}
      onClick={handleFilterChange}
      disabled={disabled}
    />
  );
};
