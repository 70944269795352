import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getAccountName, getAccountPhoto } from 'processes/user';

import { NavbarMenuItemContainer } from '../NavbarMenuItemContainer/NavbarMenuItemContainer';
import { AccountDropdown } from './AccountDropdown';
import cls from './NavbarAccountControl.module.scss';

export const NavbarAccountControl = () => {
  const [anchorEl, setAnchorEl] = useState<Nullable<Element>>(null);
  const accountName = useSelector(getAccountName);
  const accountPhoto = useSelector(getAccountPhoto);

  const initials =
    `${accountName.surname[0] || ''}${accountName.name[0] || ''}` || '?';

  return (
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(null);
      }}
    >
      <NavbarMenuItemContainer
        className={cls.Root}
        onClick={(e) => {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
      >
        <div className={cls.ControlButton}>
          {accountPhoto ? (
            <img className={cls.Image} src={accountPhoto} alt='Фото' />
          ) : (
            <span>{initials}</span>
          )}
        </div>
        {anchorEl && <AccountDropdown />}
      </NavbarMenuItemContainer>
    </ClickAwayListener>
  );
};
