import { useHostReport } from 'entities/Host';

import { useIsTherePendingLogReport } from '../hooks/useIsTherePendingLogReport';

export const hostReportContextDefaultValue = {
  loading: false,
  data: { items: [], total: 0 },
  error: undefined,
  loadMore: () => {},
  changeFilters: () => {},
  variables: {
    filters: { hostId: '', type: [] },
    pagination: { offset: 0, limit: 6 },
  },
  isFullLoaded: false,
  refetch: (() => {}) as ReturnType<typeof useHostReport>['refetch'],
  refetchAll: (() => {}) as ReturnType<typeof useHostReport>['refetchAll'],
  setPagination: () => {},
  resetPaginationAndRefetch: () => {},
  isTherePendingLogReport: false,
  refetchLastLogReport: (() => {}) as ReturnType<
    typeof useIsTherePendingLogReport
  >['refetchLastLogReport'],
};
