// Значение с UUID преобразованным в число
export const NumberToColorConversion = <T extends string>(
  uuid: string,
  ColorIcon: T[],
): T => {
  // Принимаем в качестве аргумента массив цветов
  const colors = ColorIcon;
  // Удаляем дефисы
  const cleanUUID = uuid.replace(/-/g, '');

  // Преобразуем в большое число
  let number = 0;
  for (let i = 0; i < cleanUUID.length; i += 1) {
    number += cleanUUID.charCodeAt(i);
  }

  // Возвращаем цвет согласно остатку от числа, деленного на количество цветов
  return colors[number % colors.length];
};
