import { hasNonEmptyArray } from '../hasNonEmptyArray';

type TPrepareServerVars<K> = Record<
  string,
  Nullable<boolean | K | Record<string, string[]> | string | undefined>
>;

/**
 * Подготовить переменные перед запросом на сервер
 */
export const prepareServerVars = <T extends TPrepareServerVars<K>, K>(
  variables: T,
) => {
  return Object.keys(variables).reduce(
    (acc: TPrepareServerVars<K>, key: string) => {
      // Если поле содержит null, undefined, пустую строку или пустой массив,
      // то оно не должно быть отправлено на сервер
      if (
        variables[key] === undefined ||
        variables[key] === null ||
        variables[key] === '' ||
        // || (Array.isArray(variables[key]) && (variables[key] as K[]).length === 0)
        (typeof variables[key] === 'object' &&
          !hasNonEmptyArray(variables[key]))
      )
        return acc;

      acc[key] = variables[key];
      return acc;
    },
    {},
  );
};
