import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const UserInDocsContext = createContext<{
  needRefetchUserInDocsList: boolean;
  setNeedRefetchUserInDocsList: (value: boolean) => void;
}>({
  needRefetchUserInDocsList: false,
  setNeedRefetchUserInDocsList: () => {},
});

export function useUserInDocsContext() {
  return useContext(UserInDocsContext);
}

interface UserInDocsContextProviderProps {
  children: ReactNode;
}

export const UserInDocsContextProvider: FC<UserInDocsContextProviderProps> = ({
  children,
}) => {
  const [needRefetch, setNeedRefetch] = useState(false);

  const value = useMemo(
    () => ({
      needRefetchUserInDocsList: needRefetch,
      setNeedRefetchUserInDocsList: setNeedRefetch,
    }),
    [needRefetch],
  );

  return (
    <UserInDocsContext.Provider value={value}>
      {children}
    </UserInDocsContext.Provider>
  );
};
