import type { Dayjs } from 'dayjs';

import { dayjs } from '../../../external/dayjs';

export const createNewDate = (
  date?: Date | Dayjs | number | string | null | undefined,
  format?: string,
) => {
  return dayjs(date, format, true);
};
