import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import { NavbarMenuItemContainer } from '../NavbarMenuItemContainer/NavbarMenuItemContainer';
import { INavbarMenuItem } from './NavbarMenuItem.types';

interface NavbarMenuItemProps {
  item: INavbarMenuItem;
}

export const NavbarMenuItem = (props: NavbarMenuItemProps) => {
  const { item } = props;

  return (
    <NavLink to={item.path}>
      {({ isActive }) => (
        <NavbarMenuItemContainer isActive={isActive}>
          <Stack direction='row' gap='0.5rem' alignItems='center'>
            <IconWrapper Svg={item.Icon} />
            <Typography variant='h4'>{item.text}</Typography>
          </Stack>
        </NavbarMenuItemContainer>
      )}
    </NavLink>
  );
};
