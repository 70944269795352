import { unreachable } from '@rsdk/common';
import { Control, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { LoginType } from 'shared/api/__generated__';
import { TextField } from 'shared/ui/form/inputs/TextField';

import { LoginFormControl, ValidLoginTypes } from '../../types';
import { loginRules } from '../../validation';

type LoginTextFiledProps = {
  activeLoginType: ValidLoginTypes;
  control: Control<LoginFormControl>;
  isError?: boolean;
  isErrorMessage?: string;
  disabled?: boolean;
  showSx?: boolean;
};

export const LoginTextFiled = (props: LoginTextFiledProps) => {
  const {
    activeLoginType,
    control,
    isError,
    isErrorMessage,
    disabled = false,
    showSx = false,
  } = props;

  const inputStyles = showSx
    ? {
        sx: {
          '.MuiInputBase-input': {
            height: '38px',
          },
        },
      }
    : {};

  return (
    <Controller
      name='login'
      control={control}
      rules={{ ...loginRules[activeLoginType] }}
      render={({ field, fieldState }) => {
        switch (activeLoginType) {
          case LoginType.PHONE:
            return (
              <ReactInputMask
                mask='+7 (999) 999-99-99'
                placeholder='+7 (000) 000-00-00'
                disabled={disabled}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              >
                <TextField
                  {...field}
                  size='medium'
                  error={!!fieldState.error || isError}
                  helperText={
                    fieldState.error?.message || (isError && isErrorMessage)
                  }
                  {...inputStyles}
                />
              </ReactInputMask>
            );
          case LoginType.EMAIL:
            return (
              <TextField
                {...field}
                placeholder='Введите Email'
                size='medium'
                disabled={disabled}
                error={!!fieldState.error || isError}
                helperText={
                  fieldState.error?.message || (isError && isErrorMessage)
                }
                {...inputStyles}
              />
            );
          case LoginType.SNILS:
            return (
              <ReactInputMask
                mask='999-999-999 99'
                maskPlaceholder='X'
                placeholder='XXX-XXX-XXX XX'
                disabled={disabled}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              >
                <TextField
                  {...field}
                  size='medium'
                  error={!!fieldState.error || isError}
                  helperText={
                    fieldState.error?.message || (isError && isErrorMessage)
                  }
                  {...inputStyles}
                />
              </ReactInputMask>
            );
          case LoginType.DRIVERS_LICENSE:
            return (
              <ReactInputMask
                mask='99 99   999999'
                maskPlaceholder='X'
                placeholder='XX XX   XXXXXX'
                disabled={disabled}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              >
                <TextField
                  {...field}
                  size='medium'
                  error={!!fieldState.error || isError}
                  helperText={
                    fieldState.error?.message || (isError && isErrorMessage)
                  }
                  {...inputStyles}
                />
              </ReactInputMask>
            );
          default:
            return unreachable(activeLoginType);
        }
      }}
    />
  );
};
