import { Box } from '@mui/material';

import { classNames } from 'shared/lib/utils/classNames';

import { NavbarLoginLogo } from '../NavbarLoginLogo/NavbarLoginLogo';
import cls from './NavbarLogin.module.scss';

interface NavbarLineProps {
  className?: string;
}

export const NavbarLogin = (props: NavbarLineProps) => {
  const { className } = props;

  return (
    <Box className={classNames(cls.NavbarLogin, {}, [className])}>
      <NavbarLoginLogo />
    </Box>
  );
};
