import { PATH_TO_APP } from '../const';

const HOSTS_ROOT = `${PATH_TO_APP}/hosts`;

export const getRouteHosts = () => `${HOSTS_ROOT}`;
export const getRouteHostDetailsInfo = (id: string) => `${HOSTS_ROOT}/${id}`;
export const getRouteHostDetailsDevices = (id: string) =>
  `${HOSTS_ROOT}/${id}/devices`;

export const getRouteHostDetailsReport = (id: string, filter: string = 'all') =>
  `${HOSTS_ROOT}/${id}/report/${filter}`;
