/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoginType,
  PasswordPolicy,
  SecretType,
  Token,
} from 'shared/api/__generated__';
import {
  removeBrowserStorage,
  setBrowserStorage,
} from 'shared/lib/utils/storage';
import { parseAccessToken, parseRefreshToken } from 'shared/lib/utils/tokens';
import { IAccessToken } from 'shared/lib/utils/tokens/tokens.types';

import { IChooseAccountOption } from '../types';

interface IAuthSlice {
  prevPathname: string;
  processType: 'auth' | 'gain';
  loginType: LoginType;
  secretType: SecretType;
  loginValue: string;
  processToken: Nullable<Token>;
  accounts: IChooseAccountOption[];
  chosenAccount: Nullable<IChooseAccountOption>;
  activeAccountId: string | null;
  policy: Nullable<PasswordPolicy>;

  accessToken: Nullable<string>;
  parsedAccessToken: Nullable<IAccessToken>;
  accessExpiresAt: number;
  refreshExpiresAt: number;

  needAuthorize: boolean;
  ownerId?: string | null;
}

const initialState: IAuthSlice = {
  prevPathname: '',
  processType: 'auth',
  loginType: LoginType.PHONE,
  secretType: SecretType.OTP,
  loginValue: '',
  processToken: null,
  accounts: [],
  chosenAccount: null,
  activeAccountId: '',
  policy: null,

  accessToken: null,
  parsedAccessToken: null,
  accessExpiresAt: 0,
  refreshExpiresAt: 0,

  needAuthorize: false,
  ownerId: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProcessType: (state, { payload }) => {
      state.processType = payload;
    },
    setTokens: (
      state,
      action: PayloadAction<{ access: string | null; refresh: string }>,
    ) => {
      const { access, refresh } = action.payload;
      if (!access || !refresh) throw Error('No tokens provided');

      const parsedAccessToken = parseAccessToken(access);
      const parsedRefreshToken = parseRefreshToken(refresh);

      state.accessToken = access;
      state.parsedAccessToken = parsedAccessToken;
      state.accessExpiresAt = parsedAccessToken ? parsedAccessToken.exp : 0;
      state.refreshExpiresAt = parsedRefreshToken ? parsedRefreshToken.exp : 0;

      setBrowserStorage('REFRESH_TOKEN_KEY', refresh);
    },
    dropTokens: (state) => {
      state.accessToken = null;
      state.accessExpiresAt = 0;
      state.refreshExpiresAt = 0;
    },
    clearAccountData: () => {
      // state = { ...initialState, prevPathname: state.prevPathname };
      removeBrowserStorage('REFRESH_TOKEN_KEY');
      removeBrowserStorage('CERTIFICATE');
      removeBrowserStorage('ACCOUNT_TYPE');
      removeBrowserStorage('ORG_UNIT_ID');
      removeBrowserStorage('ACCOUNT_ID');
    },

    setLoginType: (state, action: PayloadAction<LoginType>) => {
      state.loginType = action.payload;
    },
    setSecretType: (state, action: PayloadAction<SecretType>) => {
      state.secretType = action.payload;
    },
    setLoginValue: (state, action: PayloadAction<string>) => {
      state.loginValue = action.payload;
    },
    setProcessToken: (state, action: PayloadAction<Token>) => {
      state.processToken = action.payload;
    },
    setProcessTokenExpiresAt: (state, action: PayloadAction<Date>) => {
      state.processToken!.expiresAt = action.payload;
    },
    setAccounts: (state, action: PayloadAction<IChooseAccountOption[]>) => {
      state.accounts = action.payload;
    },
    setChosenAccount: (
      state,
      action: PayloadAction<Nullable<IChooseAccountOption>>,
    ) => {
      state.chosenAccount = action.payload;
    },
    setPolicy: (state, action: PayloadAction<Nullable<PasswordPolicy>>) => {
      state.policy = action.payload;
    },
    setPrevPathname: (state, action: PayloadAction<string>) => {
      state.prevPathname = action.payload;
    },

    showLogin: (state) => {
      state.needAuthorize = true;
    },
    hideLogin: (state) => {
      state.needAuthorize = false;
    },

    setOwnerId: (state, action: PayloadAction<string | null>) => {
      state.ownerId = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string | null>) => {
      state.activeAccountId = action.payload;
    },
  },
});

export const authSliceReducer = authSlice.reducer;
export const authSliceActions = authSlice.actions;
