import { create } from 'zustand';
import type { StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export function createStore<T extends object, U extends object>(
  name: string,
  state: T,
  actions: StateCreator<
    T,
    [['zustand/devtools', never], ['zustand/immer', never]],
    [['zustand/immer', never], ['zustand/devtools', never]],
    U
  >,
) {
  if (process.env.NODE_ENV === 'development') {
    return create<T & U>()(
      devtools(
        immer((...a) => ({ ...state, ...(actions as any)(...a) })),
        { name },
      ),
    );
  }

  return create<T & U>()(
    immer((...a) => ({ ...state, ...(actions as any)(...a) })),
  );
}
