import { Stack } from '@mui/material';

import { HostReportStatus } from 'shared/api/__generated__';
import { Typography } from 'shared/ui/contents/Typography';

interface HostTooltipContentProps {
  fetchDate: string;
  createDate: string;
  status: HostReportStatus;
}
export const HostTooltipContent = (props: HostTooltipContentProps) => {
  const { fetchDate, createDate, status } = props;
  return (
    <Stack display='flex' flexDirection='column'>
      <Typography variant='t3' noWrap>
        Запрошено: {fetchDate ?? '-'}
      </Typography>
      <Typography variant='t3' noWrap>
        Создано: {createDate ?? '-'}
      </Typography>
      {status && status === 'LOAD_ERROR' && (
        <Typography color='var(--color-pink-200)' variant='t3' noWrap>
          Ошибка создания
        </Typography>
      )}
    </Stack>
  );
};
