export interface IParsedSubject {
  /** Электронная почта (организации). */
  E?: string;

  /** Имя и отчество. */
  G?: string;

  /** Название отдела (подразделения). */
  OU?: string;

  /** Фамилия. */
  SN?: string;

  /** Должность. */
  T?: string;

  /** Название организации. */
  CN?: string;
}

/**
 * Разбирает строку субъекта и возвращает объект типа IParsedSubject.
 * @param {string} val - Строка субъекта для разбора.
 * @returns {IParsedSubject} - Информация об обработанном субъекте.
 */
export const parseSubject = (val: string): IParsedSubject => {
  // данные приходят строкой, делим поля по запятой
  const split = val.split(',').reduce((acc, el) => {
    // делим пару ключ-значение по "=" и записываем в объект
    const elSplit = el.split('=');
    return {
      ...acc,
      [elSplit[0]?.trim()]: elSplit[1]?.trim(),
    };
  }, {});

  return split as IParsedSubject;
};
