import { unreachable } from '@rsdk/common';

import { BanReason } from 'shared/api/__generated__';

export const getBanReason = (reason: BanReason) => {
  switch (reason) {
    case BanReason.INJURY:
      return 'Травма';
    case BanReason.COMPLAINTS:
      return 'Жалобы';
    case BanReason.SIGNS_OF_ILLNESS:
      return 'Признаки болезни';
    case BanReason.SIGNS_OF_INTOXICATION:
      return 'Признаки интоксикации';
    default:
      return unreachable(reason);
  }
};
