import { NotificationItem } from 'shared/api/__generated__';
import { createNewDate } from 'shared/lib/utils/dates';

import { INotificationItem } from '../types/INotificationItem.types';

export const parseNotificationItem = (
  item: NotificationItem,
): INotificationItem => ({
  ...item,
  timestamp: createNewDate(item.timestamp),
  header: item.header ? JSON.parse(item.header) : {},
  text: item.text ? JSON.parse(item.text) : {},
});
