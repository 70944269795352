import { IlCloseCircle } from '@mc/react-icons/il';
import { Chip, Drawer, Stack } from '@mui/material';
import { useState } from 'react';

import { IconButton } from 'shared/ui/buttons/IconButton';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import ImageThisIsFine from './assets/this-is-fine.png';
import cls from './FakerDrawer.module.scss';
import { FakerExam } from './ui/FakerExam/FakerExam';

interface FakerDrawerProps {
  isOpen: boolean;
  onClose?: () => void;
}

enum FakerDrawerNavigation {
  MEDCAB_EXAMS = 'medcabExams',
  TICKETS = 'tickets',
}

export const FakerDrawer = (props: FakerDrawerProps) => {
  const { isOpen, onClose } = props;

  const navigation: { key: FakerDrawerNavigation; label: string }[] = [
    { key: FakerDrawerNavigation.MEDCAB_EXAMS, label: 'Медкабинет' },
    { key: FakerDrawerNavigation.TICKETS, label: 'Талоны' },
  ];

  const [currentNavigation, setCurrentNavigation] =
    useState<FakerDrawerNavigation>(FakerDrawerNavigation.MEDCAB_EXAMS);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor='right'>
      {/* Контейнер */}
      <Stack
        flexDirection='column'
        gap='2rem'
        justifyContent='space-between'
        style={{ minHeight: '100vh' }}
      >
        <Stack className={cls.Container} gap='1rem'>
          {/* Заголовок */}
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='h1'>Faker</Typography>
            <IconButton onClick={onClose}>
              <IconWrapper Svg={IlCloseCircle} />
            </IconButton>
          </Stack>

          {/* Навигация */}
          <Stack flexDirection='row' alignItems='center' gap='0.5rem'>
            {navigation.map(({ key, label }) => (
              <Chip
                key={key}
                label={label}
                variant='filled'
                onClick={() => setCurrentNavigation(key)}
                color={currentNavigation === key ? 'lightBlue' : 'default'}
              />
            ))}
          </Stack>

          {/* Контейнер навигации */}
          <Stack className={cls.NavigationContainer}>
            {currentNavigation === 'medcabExams' && <FakerExam />}
            {currentNavigation === 'tickets' && (
              <Stack textAlign='center'>
                <Typography>
                  --- Тут будет интерфейс добавления талонов ---
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {/*  */}
        <Stack
          style={{
            height: 100,
            backgroundImage: `url(${ImageThisIsFine})`,
            backgroundPosition: 'center bottom',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            flexShrink: 0,
          }}
        />
      </Stack>
    </Drawer>
  );
};
