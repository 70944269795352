import { PhotoStatus } from 'shared/api/__generated__';

import { PartialPhotoProps } from '../types/Photo';

export const statusText = {
  VERIFIED: 'Обновлена',
  UNVERIFIED: 'Не верифицирована',
  ARCHIVED: 'Перенесена в архив',
};

export const getStatusText = (status: PhotoStatus) => statusText[status];

export const getStatusDate = (currentPhoto: PartialPhotoProps) => {
  if (currentPhoto.status === PhotoStatus.ARCHIVED)
    return currentPhoto.archivedAt;
  return currentPhoto.addedAt;
};
