import dayjs from 'dayjs';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

import { dateWithoutServerOffset } from '../../utils/dates/clientServerOffset/clientServerOffset';

/**
 * Интервал таймера в миллисекундах между кадрами анимации.
 *
 * NOTE: при установке значения 10-50мс, нагрузка на CPU оставляет около 30%,
 * при значении 200мс, нагрузка не более 4% (на m1).
 */
const TICK_INTERVAL = 200;

export type CountdownContextValue = {
  store: StoreApi<CountdownState & CountdownActions>;
  start: (deadline: string) => void;
  stop: () => void;
};

export const CountdownContext = createContext<
  CountdownContextValue | undefined
>(undefined);

export type CountdownContextProviderProps = {
  children: ReactNode;
};

interface CountdownState {
  /** Оставшееся время в секундах */
  countdownTime: number;

  /** Флаги */
  isActive: boolean;
  isStarted: boolean;
  isFinished: boolean;
}

interface CountdownActions {
  /** Установить текущее оставшееся время */
  setCountdown: (value: number) => void;
  /** Установить стейт */
  setState: (value: Partial<CountdownState>) => void;
}

/**
 * Таймер обратного отсчёта.
 *
 * Нужно обернуть место использования таймера в CountdownContextProvider.
 * Отрисовать в нужном месте таймер компонентом Countdown.
 * Вызвать start таймера через хук useCountdown.
 * Если нужно отрисовать время использовать хук useCountdownTime.
 */
export const CountdownContextProvider = (
  props: CountdownContextProviderProps,
) => {
  const { children } = props;

  /**
   * Ссылка на id кадра анимации.
   * Требуется для остановки анимации при уничтожении компонента.
   */
  const raf = useRef<number>();

  /** Ссылка на время истечения таймера */
  const deadlineTimeRef = useRef<number>(Date.now());

  /** Ссылка на setTimeout, прерывающий частые изменения компонента */
  const throttleRef = useRef<number>();

  /** Стор для хранения стейта */
  const [store] = useState(() =>
    createStore<CountdownState & CountdownActions>((set) => ({
      countdownTime: 0,
      isActive: false,
      isStarted: false,
      isFinished: false,
      setCountdown: (value) => set((state) => ({ ...state, countdown: value })),
      setState: (value) => set((state) => ({ ...state, ...value })),
    })),
  );
  const setState = useStore(store, (state) => state.setState);

  /**
   * Функция остановки таймера.
   * Останавливает анимацию и сбрасывает состояние таймера до начального.
   */
  const stop = useCallback(() => {
    window.cancelAnimationFrame(raf.current!);

    // Сбрасываем throttle-таймер
    if (throttleRef.current) {
      clearTimeout(throttleRef.current);
      throttleRef.current = undefined;
    }

    raf.current = undefined;
    deadlineTimeRef.current = Date.now();
    setState({
      countdownTime: 0,
      isActive: false,
      isFinished: true,
      isStarted: false,
    });
  }, [setState]);

  /**
   * Функция на расчёт значения таймера.
   * Вызывается на каждом кадре анимации (за счёт requestAnimationFrame).
   */
  const tick = useCallback(() => {
    /**  Вычисляем, сколько времени осталось до истечения таймера */
    const remainingTime = deadlineTimeRef.current - Date.now(); // getDiffToDeadline();

    if (remainingTime > 0) {
      // Если осталось время, то обновляем состояние таймера
      setState({
        countdownTime: remainingTime,
      });

      // Исключаем дрочку ре-рендера компонента
      throttleRef.current = window.setTimeout(() => {
        // ...записываем id на следующий кадр анимации
        raf.current = window.requestAnimationFrame(tick);
      }, TICK_INTERVAL);
    } else stop();
  }, [stop, setState]);

  /**
   * Функция запуска таймера.
   * Записывает время истечения таймера в deadlineTimeRef
   * и выставляет флаги активности.
   */
  const start = useCallback(
    (deadline: string) => {
      // Если таймер уже запущен, то останавливаем его
      if (raf.current) {
        stop();
      }
      // Проверяем валидность переданного дедлайна
      if (!dayjs(deadline).isValid()) {
        throw new Error('Передана не валидная дата окончания таймера');
      }

      // Сохраняем время истечения таймера с учетом разницы с серверным временем
      deadlineTimeRef.current = +dateWithoutServerOffset(
        dayjs(deadline).toDate(),
      );

      // Устанавливаем начальное значение стейта
      setState({
        countdownTime: deadlineTimeRef.current - Date.now(),
        isActive: true,
        isFinished: false,
        isStarted: true,
      });

      // Запускаем таймер
      raf.current = window.requestAnimationFrame(tick);
    },
    [stop, setState, tick],
  );

  useEffect(() => {
    // При удалении компонента останавливаем таймер
    // и останавливаем обновление состояния.
    return () => {
      if (raf.current) window.cancelAnimationFrame(raf.current);
    };
  }, []);

  const value = useMemo(
    () => ({
      start,
      stop,
      store,
    }),
    [start, stop, store],
  );

  return (
    <CountdownContext.Provider value={value}>
      {children}
    </CountdownContext.Provider>
  );
};

export type UseCountdownValue = {
  isActive: boolean;
  isStarted: boolean;
  isFinished: boolean;
  start: (deadline: string) => void;
  stop: () => void;
};

export const useCountdown = (): UseCountdownValue => {
  const countdownContextValue = useContext<CountdownContextValue | undefined>(
    CountdownContext,
  );
  if (!countdownContextValue) {
    throw new Error('Missing CountdownContextProvider');
  }

  const { start, stop, store } = countdownContextValue;

  const isFinished = useStore(store, (state) => state.isFinished);
  const isStarted = useStore(store, (state) => state.isStarted);
  const isActive = useStore(store, (state) => state.isActive);

  const value = useMemo(
    () => ({
      start,
      stop,
      isActive,
      isFinished,
      isStarted,
    }),
    [start, stop, isActive, isFinished, isStarted],
  );

  return value;
};

export type UseCountdownTimeValue = {
  secondsLeft: number;
  isFinished: boolean;
};

export const useCountdownTime = (): UseCountdownTimeValue => {
  const countdownContextValue = useContext<CountdownContextValue | undefined>(
    CountdownContext,
  );
  if (!countdownContextValue) {
    throw new Error('Missing CountdownContextProvider');
  }

  const { store } = countdownContextValue;

  const isFinished = useStore(store, (state) => state.isFinished);
  const countdownTime = useStore(store, (state) => state.countdownTime);

  return {
    secondsLeft: Math.round(countdownTime / 1000),
    isFinished,
  };
};
