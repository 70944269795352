import { Stack } from '@mui/system';

import EmptyListIcon from 'shared/assets/icons/thumbsUp.svg?react';
import { Typography } from 'shared/ui/contents/Typography';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import cls from './NotificationList.module.scss';

interface EmptyNotificationListProps {
  isUnreadOnly: boolean;
}

export const EmptyNotificationList = (props: EmptyNotificationListProps) => {
  const { isUnreadOnly } = props;

  const missingNotifications = isUnreadOnly
    ? 'Всё прочитано'
    : 'Нет уведомлений';

  return (
    <Stack className={cls.EmptyListContainer}>
      <IconWrapper Svg={EmptyListIcon} className={cls.EmptyListIcon} />
      <Typography variant='h2' className={cls.EmptyListText}>
        {missingNotifications}
      </Typography>
    </Stack>
  );
};
