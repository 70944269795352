import { ApolloClient, ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { apolloCache } from '../helpers/apolloCache';
import { authLink } from '../links/authLink';
import { cleanVariables } from '../links/cleanVariables';
import { errorLink } from '../links/errorLink';
import { removeSkip } from '../links/removeSkip';
import { removeUnusedFragments } from '../links/removeUnusedFragments';
import { removeUnusedVariables } from '../links/removeUnusedVariables';
import { requestIdLink } from '../links/requestIdLink';
import { restLink } from '../links/restLink';
import { scalarLink } from '../links/scalarLink';
import { subscriptionLink } from '../links/subscriptionLink';
import { defaultOptions } from './config';

/**
 * Apollo Client для авторизованных запросов
 */
export const apolloClientMain = new ApolloClient({
  connectToDevTools: import.meta.env.__DEV__,
  link: ApolloLink.from([
    errorLink,
    scalarLink,
    cleanVariables,
    removeSkip,
    removeUnusedFragments,
    removeUnusedVariables,
    authLink,
    requestIdLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      // Если функция возвращает true, то запрос будет отправлен по ws
      subscriptionLink,
      // ... иначе как rest
      restLink,
    ),
  ]),
  cache: apolloCache,
  defaultOptions,
});
