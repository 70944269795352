import { gql } from 'shared/api/__generated__';

export const ORG_UNIT_FAKER_SEARCH = gql(/* GraphQL */ `
  query ORG_UNIT_FAKER_SEARCH(
    $filters: OrgUnitsFilters!
    $pagination: Pagination!
  ) {
    orgUnits {
      getFilteredList(filters: $filters, pagination: $pagination) {
        items {
          id
          displayShortname
        }
        total
      }
    }
  }
`);
