import { Stack } from '@mui/system';

import { PhotoStatus } from 'shared/api/__generated__';
import { classNames } from 'shared/lib/utils/classNames';
import { formatDate } from 'shared/lib/utils/dates';

import { Button } from '../../../buttons/Button';
import { Tooltip } from '../../../helpers/Tooltip';
import { Typography } from '../../Typography';
import { tooltipPopperProps } from '../const/tooltipPopperProps';
import { PhotoProps, PhotoWithMaskProps } from '../types/Photo';
import { getStatusDate, getStatusText } from '../utils/displayStatus';
import { ImageContainer } from './ImageContainer';
import cls from './Photo.module.scss';

export const Photo = (props: PhotoProps) => {
  const { renderTooltip, ...args } = props;

  if (renderTooltip)
    return (
      <Tooltip title={renderTooltip} PopperProps={tooltipPopperProps}>
        <div style={{ width: 'fit-content' }}>
          <ImageContainer {...args} />
        </div>
      </Tooltip>
    );

  return <ImageContainer {...args} />;
};

export const PhotoWithMask = (props: PhotoWithMaskProps) => {
  const { photo, size, onShow, onArchive, ...args } = props;

  const status = photo?.status;
  const src = photo?.url;

  return (
    <ImageContainer
      {...args}
      grey={args.grey || (status && status === PhotoStatus.ARCHIVED)}
      size={size || 200}
      src={src}
      maskSlot={
        status &&
        src && (
          <Stack className={classNames(cls.MaskSlot)}>
            <Stack className={classNames(cls.Plate)}>
              <Stack className={classNames(cls.PlateText)}>
                {status === PhotoStatus.ARCHIVED && (
                  <Typography variant='t3'>{getStatusText(status)}</Typography>
                )}
                {getStatusDate(photo) && (
                  <Typography variant='t3'>
                    {formatDate(getStatusDate(photo))}
                  </Typography>
                )}

                {(onShow || onArchive) && (
                  <Stack className={classNames(cls.ActionButtons)} gap='16px'>
                    {onShow && (
                      <Button
                        className={classNames(cls.ActionButton)}
                        color='primary'
                        onClick={() => onShow(photo)}
                      >
                        <Typography variant='b1'>Посмотреть</Typography>
                      </Button>
                    )}
                    {onArchive && status !== PhotoStatus.ARCHIVED && (
                      <Button
                        className={classNames(cls.ActionButton)}
                        color='error'
                        onClick={() => onArchive(photo)}
                      >
                        <Typography variant='b1'>Архивировать фото</Typography>
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        )
      }
    />
  );
};
