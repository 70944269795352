import { AdminpanelRoutes } from './Adminpanel.routes';
import { EmployeeImportRoutes } from './EmployeeImport.routes';
import { ExamsRoutes } from './Exams.routes';
import { GlobalRouteConfig } from './Global.routes';
import { HostsRoutes } from './Hosts.routes';
import { LoginRoutes } from './Login.routes';
import { MedCabinetRoutes } from './MedCabinet.routes';
import { OrgUnitsRoutes } from './OrgUnits.routes';
import { ReportRoutes } from './Report.routes';
import { SubscriptionsRoutes } from './Subscriptions.routes';
import { UsersRoutes } from './Users.routes';

export const routeConfig = {
  ...OrgUnitsRoutes,
  ...HostsRoutes,
  ...UsersRoutes,
  ...LoginRoutes,
  ...ExamsRoutes,
  ...MedCabinetRoutes,
  ...ReportRoutes,
  ...EmployeeImportRoutes,
  ...AdminpanelRoutes,
  ...SubscriptionsRoutes,

  // NOTE: Global routes всегда последние, так как
  // в них есть роуты на ошибки по регулярным выражениям.
  ...GlobalRouteConfig,
};
