/**
 * Преобразует время в секундах в формат чч:мм:сс
 */
export const toUserTime = (valueInSec: number) => {
  if (valueInSec === 0) return '00:00';

  if (valueInSec < 0) {
    console.error('Число секунд не может быть отрицательным');
    return '00:00';
  }

  const addZero = (num: number) => String(num).padStart(2, '0');

  let hours = null;
  let mins = Math.floor(valueInSec / 60);

  if (mins >= 60) {
    hours = Math.floor(mins / 60);
    mins %= 60;
  }
  const seconds = valueInSec % 60;

  const time = `${addZero(mins)}:${addZero(seconds)}`;
  return hours ? `${addZero(hours)}:${time}` : time;
};
