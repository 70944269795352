import { PaletteMode } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { ReactNode, useMemo, useState } from 'react';

import { ColorModeContext } from 'shared/lib/context/ColorModeContext';

import { createComponents } from '../lib/createComponents';
import { createPalette } from '../lib/createPalette';
import { createTypography } from '../lib/createTypography';

interface ThemeProviderProps {
  children?: ReactNode;
}

const LOCAL_STORAGE_THEME_MODE_KEY = 'theme.mode';

export const getInitialThemeMode = (): PaletteMode => {
  const storedThemeMode = localStorage.getItem(LOCAL_STORAGE_THEME_MODE_KEY);
  return storedThemeMode === 'light' ? 'light' : 'dark';
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [mode, setMode] = useState<PaletteMode>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  /** Хак для получения переменных из CSS */
  const cssVar = (name: string) =>
    getComputedStyle(document.documentElement).getPropertyValue(name).trim();

  const theme = useMemo(
    () =>
      createTheme({
        palette: createPalette(cssVar, mode),
        typography: createTypography(),
        components: {
          ...createComponents(cssVar, mode),
        },
      }),
    [mode],
  );

  document.body.className = `app mode-${mode}`;

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
};
