import { InjuryLocation, InjuryType } from 'shared/api/__generated__';

export const injuryTypeTranslate: Record<InjuryType, string> = {
  [InjuryType.HAIRY_AREA]: 'Волосистой области',
  [InjuryType.FRONTAL]: 'Лобной области',
  [InjuryType.TEMPORAL_REGION]: 'Височной области',
  [InjuryType.BROW]: 'Надбровной области',
  [InjuryType.PARAORBITAL_HEMATOMA]: 'Параорбитальная гематома',
  [InjuryType.EYELID]: 'Века',
  [InjuryType.INFLAMMATION_EYELID]: 'Воспаление века неуточненное',
  [InjuryType.INFRAORBITAL_REGION]: 'Подглазничной области',
  [InjuryType.ZYGOMATIC_AREA]: 'Скуловой области',
  [InjuryType.NOSAL_DORSUM]: 'Спинки носа',
  [InjuryType.NOSE]: 'Носа',
  [InjuryType.PAROTID_MASTICATORY_REGION]: 'Околоушно-жевательной области',
  [InjuryType.EAR]: 'Уха',
  [InjuryType.SOFT_TISSUE]: 'Мягких тканей лица',
  [InjuryType.UPPER_LIP]: 'Верхней губы',
  [InjuryType.LOWER_LIP]: 'Нижней губы',
  [InjuryType.CHIN_AREA]: 'Подбородочной области',
  [InjuryType.MULTIPLE_HEAD_INJURIES]: 'Множественные травмы головы',
  [InjuryType.NECK]: 'Шеи неуточненная',
  [InjuryType.CHEST]: 'Грудной клетки неуточненная',
  [InjuryType.UPPER_LIMB]: 'Верхней конечности',
  [InjuryType.LOWER_LIMB]: 'Нижней конечности',
  [InjuryType.OTHER]: 'Другая локализация',
};

export const injuryLocationTranslate: Record<InjuryLocation, string> = {
  [InjuryLocation.LEFT]: 'Слева',
  [InjuryLocation.RIGHT]: 'Справа',
  [InjuryLocation.LEFT_AND_RIGHT]: 'Слева и справа',
};
