/*
 * Когда бэк не успевает за фронтом, то тут описываются типы,
 * которые не успели добавить в gql схему.
 * После того, как бэк выпустит релиз, эти типы нужно зачистить.
 */

export enum Complaints {
  ARRHYTHMIA = 'ARRHYTHMIA',
  CHEST_PAIN = 'CHEST_PAIN',
  COUGH_SHORT_BREATH = 'COUGH_SHORT_BREATH',
  DIZZINESS = 'DIZZINESS',
  GENERAL_SICKNESS = 'GENERAL_SICKNESS',
  GENERAL_WEAKNESS = 'GENERAL_WEAKNESS',
  HEADACHE = 'HEADACHE',
  HEART_PAIN = 'HEART_PAIN',
  INDIGESTION = 'INDIGESTION',
  JOINT_PAIN = 'JOINT_PAIN',
  NO_COMPLAINTS = 'NO_COMPLAINTS',
  RASH_ALLERGY = 'RASH_ALLERGY',
  STOMACH_ACHE = 'STOMACH_ACHE',
  TOOTHACHE = 'TOOTHACHE',
}
