import {
  browserTracingIntegration,
  init as SentryInit,
  replayIntegration,
} from '@sentry/react';

import { beforeBreadcrumb } from './beforeBreadcrumb';

export const initSentry = () => {
  if (!import.meta.env.__SENTRY_DSN__) return;

  try {
    SentryInit({
      dsn: import.meta.env.__SENTRY_DSN__,
      integrations: [browserTracingIntegration(), replayIntegration()],
      environment: import.meta.env.__STAGE_NAME__ || 'undefined',
      release: import.meta.env.__VERSION__ || undefined,

      // Performance Monitoring
      tracesSampleRate: 1.0,

      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      // Breadcrumbs - форматирование сообщений в логах
      beforeBreadcrumb,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Sentry initialization failed:', err);
  }
};
