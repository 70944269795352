import { PATH_TO_APP } from '../const';

const EXAMS_ROOT = `${PATH_TO_APP}/exams`;

export const getRouteExams = () => `${EXAMS_ROOT}`;
export const getRouteExamDetailsInfo = (id: string) => `${EXAMS_ROOT}/${id}`;
export const getRouteExamDetailsConditions = (id: string) =>
  `${EXAMS_ROOT}/${id}/conditions`;
export const getRouteExamDetailsHistory = (id: string) =>
  `${EXAMS_ROOT}/${id}/history`;
export const getRouteExamDetailsReceipt = (id: string) =>
  `${EXAMS_ROOT}/${id}/receipt`;
