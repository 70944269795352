import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
} from '@mui/material';

export function Autocomplete<
  T,
  Multiple extends boolean = false,
  FreeSolo extends boolean = false,
>(props: MUIAutocompleteProps<T, Multiple, boolean, FreeSolo>) {
  const { multiple, ...args } = props;

  const size = args?.size || 'small';

  return (
    <MUIAutocomplete
      {...args}
      size={size}
      multiple={multiple}
      noOptionsText={args?.noOptionsText || 'Нет совпадений'}
    />
  );
}
