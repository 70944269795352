import { IMapSize, TButtonSize } from '../types/Button';

export const sizeMap: Record<TButtonSize, IMapSize> = {
  small: {
    height: '32px',
    size: 'small',
    loaderSize: '18px',
  },
  medium: {
    height: '38px',
    size: 'medium',
    loaderSize: '20px',
  },
  large: {
    height: '42px',
    size: 'large',
    loaderSize: '22px',
  },
  extraLarge: {
    height: '54px',
    size: 'large',
    fontSize: '1rem',
    loaderSize: '24px',
  },
};
