import { gql } from '@apollo/client';

export const USER_DISABLE = gql`
  mutation USER_DISABLE($input: DisableAccountInput!) {
    accounts {
      disable(input: $input) {
        ... on Person {
          id
        }
      }
    }
  }
`;
