import { ReportPage } from 'pages/Report';

import { ReportRouteNames, ReportRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const ReportRoutes: Record<ReportRouteNames, TRouteProps> = {
  [ReportRouteNames.REPORT]: {
    path: ReportRoutePaths.getRouteReport(),
    element: <ReportPage />,
    isAuth: true,
    needRules: ['report.method.get_filtered_list'],
  },
};
