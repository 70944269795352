import { gql } from 'shared/api/__generated__';

export const NotificationSetIsRead = gql(/* GraphQL */ `
  mutation NotificationSetIsRead($input: SetReadOrUnreadInput!) {
    notifications {
      setReadOrUnread(input: $input) {
        nothing
      }
    }
  }
`);
