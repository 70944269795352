import { sleep } from './sleep';

/* eslint-disable no-await-in-loop */
type AsyncFunc<T> = () => Promise<T>;
type StopCallback = () => unknown;

type ExecuteWithStrategyOptions<T> = {
  base: {
    /** Функция, которую нужно выполнить */
    fn: AsyncFunc<T>;
    /** Раз во сколько миллисекунд выполнять функцию */
    interval: number;
    /** Условие, по которому нужно остановить выполнение цикла */
    checkStopCondition: () => boolean | Promise<() => boolean>;
  };

  /** Инструкции при ошибках */
  errors: {
    /** Максимальное количество ошибок подряд */
    maxErrorRetries: number;
    /** Интервал между повторами при ошибке */
    errorRetryInterval: number;
    /** Коллбэк при остановке */
    onStop?: StopCallback;
  };

  /** Инструкции доп. попыток при неудовлетворительных результатах */
  check?: {
    /** Условие, по которому будет выполняться череда доп. проверок */
    checkCondition: (value: T) => boolean;
    /** Количество попыток доп. проверок */
    retryCount: number;
    /** Интервал между доп. попытками */
    retryInterval: number;
  };
};

export async function executeWithStrategy<T>({
  base: { fn, interval, checkStopCondition },
  errors,
  check,
}: ExecuteWithStrategyOptions<T>) {
  /** Счётчик ошибок */
  let errorRetries = 0;

  /** Флаг остановки */
  let hardStop = false;

  const executeAndCheck = async () => {
    if (hardStop || (await checkStopCondition())) {
      console.log('Main. Условие остановки выполнено. Остановка цикла.');
      return;
    }

    try {
      const result = await fn();
      // console.log(`Main. Получен результат: ${result}`);

      // Сброс счётчика ошибок при успешном выполнении
      errorRetries = 0;

      // Если результат выполняет условия, выполняем доп. проверки
      if (check && check.checkCondition(result)) {
        console.log(
          `Получен неудовлетворительный результат: ${JSON.stringify(result)}. Начинаем retry.`,
        );
        for (let i = 0; i < check.retryCount; i += 1) {
          console.log(`Retry #${i + 1}`);

          if (hardStop || (await checkStopCondition())) {
            console.log(
              `Retry #${i + 1}. Условие остановки выполнено. Остановка main.`,
            );
            return;
          }

          const retryResult = await fn();
          console.log(
            `Retry #${i + 1}. Результат: ${JSON.stringify(retryResult)}`,
          );

          if (!check.checkCondition(retryResult)) {
            console.log(
              `Retry #${i + 1}. Получен удовлетворительный результат. Выходим из retry.`,
            );
            break;
          }

          // Ждем перед следующей дополнительной попыткой
          await sleep(check.retryInterval);
        }
      }

      // Ждем перед следующей основной попыткой
      await sleep(interval);
    } catch (error) {
      errorRetries += 1;
      console.error(`Error. Попытка #${errorRetries} завершилась с ошибкой`);

      // Проверяем, достигли ли мы максимального числа ошибок
      if (errorRetries >= errors.maxErrorRetries) {
        console.log(
          'Превышено максимальное количество повторений с ошибкой. Остановка main.',
        );

        // Если есть коллбэк остановки при ошибке, вызываем его
        if (errors.onStop) errors.onStop();

        // Прерываем цикл, тк достигли максимального числа ошибок
        return;
      }

      // Ждем перед повторной попыткой в случае ошибки
      await sleep(errors.errorRetryInterval);

      // Пропускаем ожидание интервала между основными повторами
      await executeAndCheck();
      return;
    }

    // После задержки запускаем цикл снова
    await executeAndCheck();
  };

  // Первый запуск
  await executeAndCheck();

  // Возвращаем функцию остановки
  return () => {
    hardStop = true;
  };
}
