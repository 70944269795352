import { gql } from '../__generated__';

export const AUTH_REFRESH = gql(/* GraphQL */ `
  mutation AUTH_REFRESH($input: RefreshInput!) {
    auth {
      refresh(input: $input) {
        access {
          value
          expiresAt
        }
        refresh {
          value
          expiresAt
        }
      }
    }
  }
`);
