import { dateNowWithServerOffset } from '../dates';
import { getExpiration } from './getExpiration';
import { TTokenIsExpiredParams } from './tokens.types';

/**
 * Проверка токена на истечение
 */
export const tokenIsExpired = (args: TTokenIsExpiredParams) => {
  return getExpiration(args) <= dateNowWithServerOffset().getTime();
};
