import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  authSliceActions,
  getAccountId,
  getNeedAuthorize,
} from 'processes/auth';

import { ReLogin } from 'features/Login';

import { LoginRoutePaths } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { getBrowserStorage } from 'shared/lib/utils/storage';
import { tokenIsExpired } from 'shared/lib/utils/tokens';

interface CheckTokenGuardProps {
  children: React.ReactNode;
}

export const CheckTokenGuard = (props: CheckTokenGuardProps) => {
  const { children } = props;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const activeAccountId = useSelector(getAccountId);

  const isNeedAuthorize = useSelector(getNeedAuthorize);

  const refreshToken = getBrowserStorage('REFRESH_TOKEN_KEY');
  const lastLogin = getBrowserStorage('LAST_LOGIN_VALUE');
  const accountType = getBrowserStorage('ACCOUNT_TYPE');
  const accountId = getBrowserStorage('ACCOUNT_ID');

  /** условие при котором необходимо перезагрузить страницу */
  const rebootCondition =
    refreshToken &&
    lastLogin &&
    activeAccountId &&
    activeAccountId !== accountId;

  const changeLocalStorage = useCallback((e: StorageEvent) => {
    const changedRefreshToken =
      e.key === 'REFRESH_TOKEN_KEY' && e.newValue === null;
    const clearedLocalStorage = !e.key;
    if (changedRefreshToken || clearedLocalStorage) {
      window.location.href = LoginRoutePaths.getRouteLogin();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('storage', changeLocalStorage);
    return () => {
      window.removeEventListener('storage', changeLocalStorage);
    };
  }, [changeLocalStorage]);

  useEffect(() => {
    if (
      (accountType === 'TokenPair' && rebootCondition) ||
      (accountType === 'NeedToChooseAccount' && rebootCondition)
    ) {
      // перезагрузка страницы в этом случа избавляет нас от сброса не активной страницы на страницу логина
      // - соответственно и от хранения токена
      window.location.reload();
    } else if (refreshToken && lastLogin) {
      const refreshTokenIsExpired = tokenIsExpired({ token: refreshToken });

      if (refreshTokenIsExpired) dispatch(authSliceActions.showLogin());
    } else {
      dispatch(authSliceActions.clearAccountData());
      dispatch(authSliceActions.hideLogin());
      navigate(LoginRoutePaths.getRouteLogin());
      // window.location.href = LoginRoutePaths.getRouteLogin();
    }
  }, [
    refreshToken,
    dispatch,
    isNeedAuthorize,
    navigate,
    lastLogin,
    accountType,
    rebootCondition,
  ]);

  if (!refreshToken) return null;

  return (
    <>
      {children}
      {isNeedAuthorize && <ReLogin />}
    </>
  );
};
