import {
  LoginAccountsPage,
  LoginActivateCodePage,
  LoginActivatePage,
  LoginPage,
  LoginSetPasswordPage,
} from 'pages/Login';

import { LoginRouteNames, LoginRoutePaths } from 'shared/const/router';

import { TRouteProps } from './types';

export const LoginRoutes: Record<LoginRouteNames, TRouteProps> = {
  [LoginRouteNames.LOGIN]: {
    path: LoginRoutePaths.getRouteLogin(),
    element: <LoginPage />,
  },
  [LoginRouteNames.LOGIN_ACTIVATE]: {
    path: LoginRoutePaths.getRouteLoginActivate(),
    element: <LoginActivatePage />,
  },
  [LoginRouteNames.LOGIN_ACTIVATE_CODE]: {
    path: LoginRoutePaths.getRouteLoginActivateCode(),
    element: <LoginActivateCodePage />,
  },
  [LoginRouteNames.LOGIN_ACCOUNTS]: {
    path: LoginRoutePaths.getRouteLoginAccounts(),
    element: <LoginAccountsPage />,
  },
  [LoginRouteNames.LOGIN_SET_PASSWORD]: {
    path: LoginRoutePaths.getRouteLoginSetPassword(),
    element: <LoginSetPasswordPage />,
  },
};
