import { gql } from '@apollo/client';

export const USER_SET_PERSONAL_DATA = gql`
  mutation USER_SET_PERSONAL_DATA($input: SetPersonalDataInput!) {
    accounts {
      people {
        setPersonalData(input: $input) {
          id
        }
      }
    }
  }
`;
