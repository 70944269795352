import { ExamTypeSelectItem } from 'entities/Exam';
import { HostSelectItem } from 'entities/Host';
import { OrgUnitSelectItem } from 'entities/OrgUnit';
import { UserSelectItem } from 'entities/User';

import {
  AccountsFilters,
  CumulativeResolution,
  DatesRange,
  HostFilters,
  HostReportsFilters,
  OrgUnitsFilters,
  RemarkKey,
  Scalars,
} from 'shared/api/__generated__';

export enum KindQuickFilter {
  ALL = 'ALL',
  POSTTEST = 'POSTTEST',
  PRETEST = 'PRETEST',
}

export enum ResolutionSuccessQuickFilter {
  ALL = 'ALL',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export interface ExamsListFilterState {
  // быстрые
  kinds: KindQuickFilter;
  resolutionSuccess: ResolutionSuccessQuickFilter;
  dates?: {
    from?: DatesRange['from'];
    to?: DatesRange['to'];
    isChanged: boolean;
  };

  // расширенные
  employees: Array<Scalars['UUID']['input']>;
  owners: Array<Scalars['UUID']['input']>;
  medics: Array<Scalars['UUID']['input']>;
  hosts: Array<Scalars['UUID']['input']>;
  examTypes: Array<Scalars['UUID']['input']>;
  isTest: boolean;
  isService: boolean | null;
  isEmulation: boolean | null;
  isHullIntegrityCompromised: boolean;
  isAssistantDisabled: boolean;
  isExternalConditionsDisturbed: boolean;
  isVideoTimeout: boolean;
  isProcessingFail: boolean;
  isPreprocessingFail: boolean;
  isMedicalDeviceError: boolean;
  cumulativeResolutions: CumulativeResolution[];
  remarks: RemarkKey[];
}

export interface ExamsListFilterSelectedItems {
  employees: UserSelectItem[];
  owners: OrgUnitSelectItem[];
  medics: UserSelectItem[];
  hosts: HostSelectItem[];
  examTypes: ExamTypeSelectItem[];
}

export interface AuditFilterListState {
  dates?: {
    from?: DatesRange['from'];
    to?: DatesRange['to'];
    isChanged: boolean;
  };
  employees: Array<Scalars['UUID']['input']>;
  owners: Array<Scalars['UUID']['input']>;
  actorEmployees: Array<Scalars['UUID']['input']>;
  action: Array<Scalars['String']['input']>;
}

export interface AuditFilterListSelectedItems {
  employees: UserSelectItem[];
  owners: OrgUnitSelectItem[];
  actorEmployees: UserSelectItem[];
  action: string[];
}

export interface FiltersSliceState {
  auditList: AuditFilterListState;
  auditListSelectedItems: AuditFilterListSelectedItems;
  examsList: ExamsListFilterState;
  examsListSelectedItems: ExamsListFilterSelectedItems;
  hostsList: Record<string, HostFilters>;
  hostReportList: Partial<HostReportsFilters>;
  orgUnitsList: Record<string, OrgUnitsFilters>;
  usersList: Record<string, AccountsFilters>;
}
