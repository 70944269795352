import { Box } from '@mui/material';

import cls from './requiredStar.module.scss';

interface RequiredStarProps {
  error?: boolean;
}

export const RequiredStar = (props: RequiredStarProps) => {
  const { error } = props;
  return (
    <Box
      className={cls.container}
      color={
        error ? 'var(--color-pink-100)' : 'var(--color-monochrome-grey-400)'
      }
    >
      *
    </Box>
  );
};
