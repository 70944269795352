import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const UserContext = createContext<{
  needRefetchUser: boolean;
  setNeedRefetchUser: (value: boolean) => void;
}>({
  needRefetchUser: false,
  setNeedRefetchUser: () => {},
});

export function useUserContext() {
  return useContext(UserContext);
}

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider: FC<UserContextProviderProps> = ({
  children,
}) => {
  const [needRefetch, setNeedRefetch] = useState(false);

  const context = useMemo(
    () => ({
      needRefetchUser: needRefetch,
      setNeedRefetchUser: setNeedRefetch,
    }),
    [needRefetch, setNeedRefetch],
  );

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
