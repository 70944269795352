import { setContext, setUser } from '@sentry/react';

import type { SetUserToSentry } from './userSentry.types';

export const setUserToSentry = (params: SetUserToSentry) => {
  const { user, role, owner } = params;

  if (user !== undefined) setUser(user);
  if (role !== undefined) setContext('Role', role);
  if (owner !== undefined) setContext('Owner', owner);
};

export const clearUserFromSentry = () => {
  setUserToSentry({});
};
