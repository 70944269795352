import { IlAlertCircle, IlCheck, IlClose } from '@mc/react-icons/il';

import { NotificationState } from 'processes/notifications/ui/Notifications.context';

import { AlertTypes } from '../types';
import cls from '../ui/Alert.module.scss';

export const getAlertClassName = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return cls.SuccessAlert;
    case AlertTypes.ERROR:
      return cls.ErrorAlert;
    default:
      return cls.InfoAlert;
  }
};

export const getAlertIcon = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.SUCCESS:
      return IlCheck;
    case AlertTypes.ERROR:
      return IlClose;
    default:
      return IlAlertCircle;
  }
};

/**  Генерация URL для Kibana */
const generateKibanaDiscoverUrl = (UUID: string): string => {
  const baseUrl = 'https://kibana.medcontrol.cloud';
  const query = `request_id: "${UUID}"`;
  const encodedQuery = encodeURIComponent(query);

  const domainDataViewId = import.meta.env.__KIBANA_DATA_VIEW_ID__;

  return `${baseUrl}/app/discover#/?_a=(index:'${domainDataViewId}',query:(language:kuery,query:'${encodedQuery}'))`;
};

/** Функция для преобразования */
export const getButtonData = (args: {
  /** Тип уведомления */
  type: AlertTypes;
  /** Уникальный идентификатор запроса */
  xRequestId?: NotificationState['xRequestId'];
  button?: {
    /** Текст на кнопке */
    text: string;
  } & (
    | {
        /** Путь, который используется в `useNavigate()` */
        navigatePath: string;
      }
    | {
        /** Функция перехода по кнопке  */
        onClick: () => void;
      }
  );
}) => {
  const { type, xRequestId, button } = args;

  if (xRequestId && type === AlertTypes.ERROR) {
    return {
      text: 'Ссылка на логи в kibana',
      onClick: () => {
        window.open(generateKibanaDiscoverUrl(xRequestId), '_blank');
      },
    };
  }

  return button;
};
