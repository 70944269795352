import { Box } from '@mui/system';

import LogoShortIcon from 'shared/assets/icons/mc-logo-short.svg?react';
import { classNames } from 'shared/lib/utils/classNames';
import { IconWrapper } from 'shared/ui/helpers/IconWrapper';

import cls from './NavbarLoginLogo.module.scss';

export const NavbarLoginLogo = () => {
  return (
    <Box className={classNames(cls.NavbarLogo)}>
      <IconWrapper Svg={LogoShortIcon} className={classNames(cls.Icon)} />
    </Box>
  );
};
