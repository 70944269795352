import { gql } from 'shared/api/__generated__';

export const notificationSetLastOpened = gql(/* GraphQL */ `
  mutation notificationSetLastOpened {
    notifications {
      setLastOpened {
        nothing
      }
    }
  }
`);
