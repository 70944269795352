import { gql } from '../__generated__';

export const LOGIN = gql(/* GraphQL */ `
  mutation LOGIN($input: LogInInput!) {
    auth {
      logIn(input: $input) {
        ... on NeedToConfirm {
          processToken {
            value
            expiresAt
          }
          type
          delivery
        }
        ... on NeedToChooseAccount {
          processToken {
            value
            expiresAt
          }
          options {
            accountId
            orgUnitId
            orgUnitName
            role {
              name
            }
            orgUnitLogo
          }
        }
        ... on TokenPair {
          access {
            value
            expiresAt
          }
          refresh {
            value
            expiresAt
          }
        }
      }
    }
  }
`);
