import { InboundDocumentStatus } from '../../__generated__';

export const DocumentMappedStatus = {
  [InboundDocumentStatus.PENDING]: 'На рассмотрении',
  [InboundDocumentStatus.ACTIVE]: 'Действует',
  [InboundDocumentStatus.USED]: 'Использован',
  [InboundDocumentStatus.DECLINED]: 'Отклонён',
  [InboundDocumentStatus.OUTDATED]: 'Истёк срок действия',
  [InboundDocumentStatus.ARCHIVED]: 'В архиве',
};
