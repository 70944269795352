import {
  Stack,
  SwitchProps as MUISwitchProps,
  TypographyOwnProps,
} from '@mui/material';
import { useCallback } from 'react';

import { classNames } from 'shared/lib/utils/classNames';

import { Typography } from '../../../contents/Typography';
import cls from './Switch.module.scss';
import { SwitchStyled } from './SwitchStyled';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  size?: MUISwitchProps['size'];
  labelProps?: TypographyOwnProps;
}

export const Switch = (props: SwitchProps) => {
  const { label, checked, disabled, onChange, size, labelProps } = props;

  const handlerChange = useCallback(() => {
    if (onChange && !disabled) onChange(!checked);
  }, [checked, disabled, onChange]);

  const _labelProps: TypographyOwnProps = {
    variant: 't3',
    color: 'var(--color-monochrome-black)',
    ...(labelProps || {}),

    // Добавляем серый стиль для текста, если Switch задизаблен
    ...(disabled && { color: 'var(--color-monochrome-grey-400)' }),
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap='4px'
      onClick={(e) => {
        e.preventDefault();
        handlerChange();
      }}
      className={classNames(cls.SwitchContainer, { [cls.Disabled]: disabled })}
    >
      <SwitchStyled {...props} onChange={handlerChange} size={size} />
      {label && <Typography {..._labelProps}>{label}</Typography>}
    </Stack>
  );
};
