import { withScalars } from 'apollo-link-scalars';
import {
  buildClientSchema,
  GraphQLScalarType,
  IntrospectionQuery,
} from 'graphql';

import introspectionResult from '../../__generated__/schema.min.json';
import { scalarConfig } from '../../../../../config/graphql/scalars';

export const scalarLink = withScalars({
  schema: buildClientSchema(
    // типизация взята из официальной документации
    introspectionResult as unknown as IntrospectionQuery,
  ),
  typesMap: {
    // проходимся по всем скалярам и добавляем их в typesMap
    ...Object.entries(scalarConfig.items).reduce(
      (acc, [key, value]) => {
        if (value.resolver) acc[key] = value.resolver;
        return acc;
      },
      {} as Record<string, GraphQLScalarType>,
    ),
  },
  // removeTypenameFromInputs: true,
});
