import { initI18Next } from '@mc/translation';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const i18n = initI18Next((i18next) =>
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'ru',
      fallbackLng: 'ru',
      debug: import.meta.env.__DEV__,
      ignoreJSONStructure: false,
      interpolation: {
        escapeValue: false,
      },
      overloadTranslationOptionHandler: () => ({
        defaultValue: '',
      }),
    }),
);

export default i18n;
