import DayJSPlugin from 'dayjs';
import ru from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

DayJSPlugin.locale(ru);

DayJSPlugin.extend(utc);
DayJSPlugin.extend(timezone);

export const dayjs = DayJSPlugin;
