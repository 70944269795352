import { Box } from '@mui/material';

import { Tooltip } from 'shared/ui/helpers/Tooltip';

import cls from './NotificationIndicator.module.scss';

interface NotificationIndicatorProps {
  isActive?: boolean;
  onClick?: () => void;
}

export const NotificationIndicator = (props: NotificationIndicatorProps) => {
  const { isActive, onClick } = props;
  return onClick ? (
    <Tooltip
      title={
        isActive ? 'Отметить как прочитанное' : 'Отметить как непрочитанное'
      }
    >
      <Box className={cls.ActiveIndicatorBox} onClick={onClick}>
        <Box
          className={isActive ? cls.IndicatorActive : cls.IndicatorInactive}
        />
      </Box>
    </Tooltip>
  ) : (
    <Box className={cls.IndicatorBox}>
      <Box className={isActive ? cls.IndicatorActive : cls.IndicatorInactive} />
    </Box>
  );
};
