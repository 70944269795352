import { Link } from 'react-router-dom';

import { Typography } from 'shared/ui/contents/Typography';

import { INotificationItemStringVariables } from '../../types/INotificationItem.types';
import cls from './NotificationCard.module.scss';

/**
 * Значения переменных типа {{ value }} и наименование свойств,
 * хранящих ссылки на из сущности
 */
const urlsForVariablesLinks: Partial<
  Record<
    keyof INotificationItemStringVariables,
    keyof INotificationItemStringVariables
  >
> = {
  value: 'url',
  employeeShortName: 'employeeUrl',
  examSerial: 'examUrl',
  host: 'hostUrl',
};

/**
 * Вернет блок заголовка с подстановкой переменных
 * (заменит {{ переменную }} на Link если есть url)
 * или просто вернет сплошной текст
 */
export const parserBlock = (
  type: 'header' | 'payload',
  text: string,
  data: INotificationItemStringVariables,
) => {
  if (!data) return null;

  return text.split(/({{[^{}]+}})/g).map((part, index) => {
    const match = part.match(/{{\s*(\w+)\s*}}/);
    if (match) {
      const variable = match[1] as keyof typeof urlsForVariablesLinks;
      const linkKey = urlsForVariablesLinks[variable];
      const url = linkKey ? data[linkKey] : undefined;

      if (url) {
        const key = part + index;
        // Если есть соответствующая ссылка для переменной
        return (
          <Link
            key={key}
            to={url}
            className={type === 'header' ? cls.HeaderUrl : ''}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Typography variant='h4' display='inline' className={cls.LinkText}>
              {data[variable] || variable}{' '}
            </Typography>
          </Link>
        );
      }
      // Если ссылки нет, просто возвращаем значение переменной
      return data[variable] || '';
    }
    // Возвращаем текст без изменений, если это не переменная (это было вне скобок {{ }})
    return part;
  });
};
