import { print } from 'graphql';
import { Client } from 'graphql-ws';

import { WS_UPDATE_SUBSCRIPTION_TOKEN } from './wsUpdateSubscriptionToken.gql';

/**
 * Отправка нового токена в websocket соединение
 */
export const sendNewTokenInSocket = ({
  client,
  token,
}: {
  client: Client;
  token: string;
}) => {
  return client.iterate({
    operationName: 'WS_UPDATE_SUBSCRIPTION_TOKEN',
    query: print(WS_UPDATE_SUBSCRIPTION_TOKEN),
    variables: { input: { token } },
  });
};
